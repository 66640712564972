import type { BottomLeftDimensions, ImperialBottomLeftDimensions } from '../../../droneZone.model';
import { imperialFormatter } from './imperialFormatter';

export const convertAllMetersToFeet = (
  sizeAndPosition: BottomLeftDimensions,
): ImperialBottomLeftDimensions =>
  Object.entries(sizeAndPosition).reduce((acc, [key, value]) => {
    acc[key as keyof ImperialBottomLeftDimensions] = imperialFormatter(value ?? 0);
    return acc;
  }, {} as ImperialBottomLeftDimensions);
