import { CameraControls } from '@react-three/drei';
import CameraControlsLib from 'camera-controls';
import { RefObject } from 'react';
import { useCameraFrustumSizing } from 'shared/map-3d/aisle-view/hooks/useCameraFrustumSizing';
import { useZoomClamp } from 'shared/map-3d/aisle-view/hooks/useZoomClamp';
import { useZoomFit } from 'shared/map-3d/aisle-view/hooks/useZoomFit';
import { Group, Vector3 } from 'three';

const LOCK_ANGLE = Math.PI / 2;

export const AisleViewScene = ({
  aisleRef,
  fit = { type: 'width' },
  normal,
  position,
}: {
  aisleRef: RefObject<Group>;
  fit?: { type: 'height' | 'width' };
  normal?: Vector3;
  position?: Vector3;
}) => {
  useCameraFrustumSizing();
  useZoomFit({
    aisleRef,
    normal,
    position,
    fit,
  });
  useZoomClamp(aisleRef);
  return (
    <CameraControls
      makeDefault
      verticalDragToForward
      boundaryEnclosesCamera
      smoothTime={0.75}
      mouseButtons={{
        left: CameraControlsLib.ACTION.TRUCK,
        right: CameraControlsLib.ACTION.NONE,
        middle: CameraControlsLib.ACTION.NONE,
        wheel: CameraControlsLib.ACTION.NONE,
      }}
      minPolarAngle={LOCK_ANGLE}
      maxPolarAngle={LOCK_ANGLE}
    />
  );
};
