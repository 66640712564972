import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useReportTableHeaderStyles = makeStyles()((theme: Theme) => ({
  cardHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  },
  cardHeaderSection: {
    alignSelf: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(2),
    flex: '0 0 auto',
    minWidth: 0,
  },
  cardHeaderActions: {
    marginInlineStart: 'auto',
  },
}));
