import { useEffect, useState } from 'react';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { formatNumberWithSeparators } from 'utils/numberFormatting';
import { WarehouseStatsBar } from './features/warehouse-stats-bar/WarehouseStatsBar';
import { WarehouseStatsPie } from './features/warehouse-stats-pie/WarehouseStatsPie';
import { WarehouseStatsWidgetToggle } from './WarehouseStatsWidgetToggle';
import { ChartType, WarehouseStatsDataSerie } from './WarehouseStats.model';
import { useStyles } from './warehouseStatsWidget.styles';

const DEFAULT_MODE: ChartType = 'progress';

const validateMode = (mode: ChartType, fallback: ChartType): ChartType => {
  const validModes: ChartType[] = ['pie', 'progress'];

  if (validModes.includes(mode)) {
    return mode;
  }

  return fallback;
};

export const WarehouseStatsWidget = ({
  id,
  title,
  subTitle,
  unit,
  unitPrefix,
  data,
  counter,
  colors = ['red', 'blue', 'green', 'yellow', 'orange', 'grey', 'pink'],
  onGridStateSelect,
  initialMode = DEFAULT_MODE,
}: {
  id: string;
  title: string;
  subTitle?: string;
  unit: string;
  unitPrefix?: string;
  data: WarehouseStatsDataSerie[];
  colors: string[];
  counter: number;
  onGridStateSelect?: (state: GridInitialStatePremium) => void;
  initialMode?: ChartType;
}) => {
  const { classes } = useStyles();

  const localStorageKey = `warehouse-stats-widget-${id}`;

  const [mode, setMode] = useState<ChartType>(() =>
    validateMode(localStorage.getItem(localStorageKey) as ChartType, initialMode),
  );

  const handleUpdateMode = (chartType: ChartType) => {
    setMode(chartType);
  };

  useEffect(() => {
    localStorage.setItem(localStorageKey, mode);
  }, [mode, localStorageKey]);

  return (
    <BaseCard
      cardFor="warehouse-stats"
      dataTestId="c-warehouse-stat-card"
      showHeader={true}
      showContent={true}
      showActionButtons={false}
      showHeaderDivider={true}
      title={
        <>
          {title}
          {'  '}
          <WarehouseStatsWidgetToggle mode={mode} onToggle={handleUpdateMode} />
        </>
      }
      subtitle={subTitle}
      counter={formatNumberWithSeparators(counter)}
      counterSubject={unitPrefix ? `${unitPrefix} ${unit}` : unit}
      hideCounterSubject={true}
    >
      <div className={classes.wrapper}>
        {mode === 'progress' && (
          <div className={classes.wrapperProgress}>
            {data
              .filter((serie) => !!serie.value)
              .map((serie, index) => (
                <WarehouseStatsBar
                  key={`${serie.label}_${serie.id}`}
                  label={serie.label}
                  description={serie.description}
                  total={counter}
                  unit={unit}
                  showProgressBar={true}
                  value={serie.value}
                  color={colors[index]}
                  onGridStateSelect={onGridStateSelect}
                  gridState={serie.gridState}
                />
              ))}
          </div>
        )}

        {mode === 'pie' && (
          <div className={classes.wrapperPie}>
            <div className={classes.wrapperPieGraphWrapper}>
              <div className={classes.wrapperPieGraphWrapperBody}>
                <WarehouseStatsPie colors={colors} data={data} />
              </div>
            </div>

            <div className={classes.wrapperPieLegendWrapper}>
              {data.map((serie, index) => {
                if (serie.value) {
                  return (
                    <WarehouseStatsBar
                      key={`${serie.label}_${serie.id}`}
                      label={serie.label}
                      description={serie.description}
                      total={counter}
                      value={serie.value}
                      unit={unit}
                      color={colors[index]}
                      showProgressBar={false}
                      gridState={serie.gridState}
                      onGridStateSelect={onGridStateSelect}
                    />
                  );
                }

                return null;
              })}
            </div>
          </div>
        )}
      </div>
    </BaseCard>
  );
};
