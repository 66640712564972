import { createSuperSetGuestToken } from '../../../services/Superset/CreateSuperSetGuestToken';

export const fetchGuestToken = async () => {
  try {
    const res = await createSuperSetGuestToken();

    return res.data.guest_token;
  } catch {
    return '';
  }
};
