import { imperialFormatter } from '../features/ImperialDroneZoneForm/utils/imperialFormatter';
import { metricFormatter } from '../features/MetricDroneZoneForm/utils/metricFormatter';

export const formatDisplayValue = (
  value = 0,
  {
    isMetricSystem,
    isUnitHidden,
    decimalPlaces,
  }: { isMetricSystem?: boolean; isUnitHidden?: boolean; decimalPlaces?: number },
): string => {
  if (isMetricSystem) {
    return metricFormatter(value, { decimalPlaces, isUnitHidden });
  }

  return imperialFormatter(value);
};
