import { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
// NOTE: This line is commented on because we use 'react-phone-input-2' only temporarily, until 'material-ui-phone-number' library fixes the empty value error.
// import MuiPhoneNumber from 'material-ui-phone-number';
import PhoneInput from 'react-phone-input-2';
import { IUserAttributesSTStatusEnum } from 'codegen/authentication';
import 'react-phone-input-2/lib/material.css';
import { TokenManager } from 'common/tokenManager';
import { CustomMultiSelect } from 'components/common/CustomFormComponents/CustomMultiSelect';
import { CustomSwitch } from 'components/common/CustomFormComponents/CustomSwitch';
import { Box } from 'components/common/Box';
import {
  IUserGetResponseST,
  IUserGetResponseSTGroupsEnum,
  IUserResponseSTStatusEnum,
} from 'codegen/user_admin';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { UserServices } from 'services/UserServices';
import { useStyles } from './EditUserModal.styles';
import { useRequestController } from '../../../../../hooks';
import { useUserLevelStore } from '../../../../../store/UserLevelStore/userLevelStore';
import { useClientLevelStore } from '../../../../../store/ClientLevelStore/clientLevelStore';
import { useFacilityLevelStore } from '../../../../../store/FacilityLevelStore/facilityLevelStore';
import { FacilityActionNames } from '../../../../../store/FacilityLevelStore/facilityLevelActions';
import { authServices } from '../../../../../services/AuthServices';
import { userStore } from '../../../../../store/UserStore';
import { getUser as getUserApi } from './api/getUser.api';
import { ModalBase } from '../../../../../components/ModalsAndPopups/ModalBase';
import { isInputEmpty } from '../AddUserModal/utils/isInputEmpty';
import { isEmailAvailable } from '../AddUserModal/utils/isEmailAvailable';
import { isPhoneValidFormat } from '../AddUserModal/utils/isPhoneValidFormat';
import { AddEditUserSkeleton } from '../../../../../components/Skeletons/AddEditUserSkeleton';
import { transformFacilitiesToSelectOptions } from '../AddUserModal/model/transformFacilitiesToSelectOptions';
import { Facility } from '../../model/facility.model';

const tokenManager = TokenManager.getInstance();

export const EditUserModal = ({
  userId,
  allUserEmails,
  facilities,
  onEdited,
  onClose,
}: {
  facilities: Facility[];
  allUserEmails: { email: string; username: string }[];
  userId: string | undefined;
  onClose: () => void;
  onEdited: () => void;
}) => {
  const { classes } = useStyles();
  const { requestController } = useRequestController('AddEditUserModal');
  const { stateUserLevel, getUserData } = useUserLevelStore();
  const { asyncPopulateFacilities } = useClientLevelStore();

  const { stateFacilityLevel, dispatchFacilityLevel } = useFacilityLevelStore();

  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState<{
    family_name: string;
    given_name: string;
    email: string;
    phone_number: string;
    user_groups: IUserGetResponseSTGroupsEnum[];
    access_to_all_facilities: boolean;
    status: IUserResponseSTStatusEnum;
  }>({
    family_name: '',
    given_name: '',
    email: '',
    phone_number: '',
    user_groups: [],
    access_to_all_facilities: false,
    status: 'UNKNOWN',
  });

  const [errors, setErrors] = useState({
    givenName: '',
    familyName: '',
    phoneNumber: '',
    email: '',
    userGroups: '',
  });

  const [userFacilities, setUserFacilities] = useState<string[]>([]);
  const [userFacilitiesOld, setUserFacilitiesOld] = useState<string[]>([]);
  const [userAccessToAllFacilitiesOld, setUserAccessToAllFacilitiesOld] = useState<boolean>();
  const [allUserGroups, setAllUserGroups] = useState([]);

  const { currentSystemId: systemId, facilityData } = stateFacilityLevel;
  const clientName = facilityData.client;
  const refreshToken = tokenManager.getRefreshToken();

  const isButtonDisabled = () => {
    const fieldsPopulated = Object.keys(values).some(
      (key) => key !== 'phone_number' && values[key as keyof typeof values] === '',
    );
    const errorsCleared = Object.values(errors).every((val) => val === '');

    return !errorsCleared || fieldsPopulated;
  };

  const setCurrentSystemId = (currentSystemId: string) => {
    dispatchFacilityLevel({
      type: FacilityActionNames.SET_CURRENT_SYSTEM_ID,
      payload: currentSystemId,
    });
  };

  const handleChange = (value: string, prop: string) => {
    setValues({ ...values, [prop]: value });
  };

  const handleAccessToAllFacilitiesSwitchChange = (value: boolean) => {
    setValues({ ...values, access_to_all_facilities: value });

    if (value) {
      setUserFacilities(facilities.map((facility) => (facility.id ? facility.id.toString() : '')));
    } else if (userAccessToAllFacilitiesOld) {
      setUserFacilities([]);
    } else {
      setUserFacilities(userFacilitiesOld);
    }
  };

  const awaitRefreshToken = async (otherFacilitiesUserHasAccessTo?: string[]) => {
    if (!refreshToken) {
      console.warn('awaitRefreshToken invoked when refreshToken is undefined/null');
      return;
    }

    await requestController.doRequest({
      request: authServices.refreshToken,
      requestParams: [stateUserLevel.usernameHashed, refreshToken],
      callbackSuccess: () => {
        if (!isEmpty(otherFacilitiesUserHasAccessTo) && otherFacilitiesUserHasAccessTo) {
          setCurrentSystemId(otherFacilitiesUserHasAccessTo[0]);
        }

        // Refresh list of facilities
        asyncPopulateFacilities(requestController, false);

        getUserData();
      },
      messageErrorFallback: 'Tokens could not be refreshed.',
    });
  };

  const handleEditUser = (data: {
    user_id: string | undefined;
    email: string;
    user_groups: IUserGetResponseSTGroupsEnum[];
    system_ids: string;
  }) => {
    const { signal } = requestController.reserveSlotForRequest();
    const { email, ...rest_data } = data;
    requestController.doRequest({
      request: userStore.updateUser,
      requestParams: [systemId, rest_data, signal],
      messageSuccess: 'User updated.',
      callbackBeforeSend: () => setIsLoading(true),
      callbackSuccess: () => {
        const { email, user_groups: userGroups, system_ids: systemIds } = data;

        const userSystemIds: string[] = systemIds?.split(',');
        const isEditingCurrentlyLoggedUser: boolean = email === stateUserLevel.username;
        const isVerityUser: boolean = userGroups.includes(IUserGetResponseSTGroupsEnum.VerityUser);
        const userHasAccessToCurrentFacility: boolean = userSystemIds?.includes(systemId as string);
        const otherFacilitiesUserHasAccessTo: string[] = userSystemIds?.filter(
          (userSystemId: string) => userSystemId !== systemId,
        );

        if (!isEditingCurrentlyLoggedUser) {
          onEdited();
        } else if (!isVerityUser || !userHasAccessToCurrentFacility) {
          awaitRefreshToken(otherFacilitiesUserHasAccessTo);
        } else {
          awaitRefreshToken();
          onEdited();
        }
      },
      messageErrorFallback: 'User could not be updated',
      callbackFinally: () => {
        setIsLoading(false);
        onClose();
      },
    });
  };

  const getUser = useCallback(
    (user_id: string) => {
      const { signal } = requestController.reserveSlotForRequest();
      requestController.doRequest({
        request: getUserApi,
        requestParams: [systemId, user_id, signal],
        callbackBeforeSend: () => setIsLoading(true),
        callbackSuccess: (r: { data: IUserGetResponseST }) => {
          setValues({
            family_name: r.data.user.family_name,
            given_name: r.data.user.given_name,
            email: r.data.user.email,
            phone_number: r.data.user.phone_number || '',
            user_groups: r.data.groups,
            access_to_all_facilities: r.data.user.access_to_all_facilities || false,
            status: r.data.user.status,
          });
          setUserFacilities(r.data.user.system_ids.map((num) => num.toString()));
          setUserFacilitiesOld(r.data.user.system_ids.map((num) => num.toString()));
          setUserAccessToAllFacilitiesOld(r.data.user.access_to_all_facilities);
        },
        messageErrorFallback: 'User could not be fetched.',
        callbackFinally: () => setIsLoading(false),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [systemId],
  );

  const getUserGroups = useCallback(() => {
    const { signal } = requestController.reserveSlotForRequest();

    requestController.doRequest({
      request: UserServices.getUserGroups,
      requestParams: [systemId, signal],
      callbackSuccess: (r) => {
        const groups = r.data.groups.map((group: string) => ({
          value: group,
          label: group.replace(/([A-Z])/g, ' $1').trim(),
        }));
        setAllUserGroups(groups);
      },
      messageErrorFallback: 'Facilities and Groups could not be fetched.',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemId]);

  const addUser = (user_id: string | undefined) => {
    const userData = {
      user_id,
      system_ids: userFacilities && userFacilities.join(','),
      email: values.email,
      family_name: values.family_name,
      given_name: values.given_name,
      phone_number: values.phone_number
        .replaceAll(' ', '')
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(')', ''),
      user_groups: values.user_groups,
      access_to_all_facilities: values.access_to_all_facilities,
    };
    handleEditUser(userData);
  };

  useEffect(() => {
    getUserGroups();
    if (userId) {
      getUser(userId);
    }
  }, [userId, getUserGroups, getUser]);

  const isEditingInfoDisabled = values.status === IUserAttributesSTStatusEnum.ExternalProvider;
  const canGrantAllFacilities = userHasPermission(PERMISSION.GRANT_ALL_FACILITIES);
  return (
    <ModalBase
      opened={true}
      maxWidth="md"
      testId="AddEditUserModal"
      handleClose={onClose}
      title={
        <Box textAlign="start" p={2}>
          <Typography style={{ fontWeight: 'bold' }} color="secondary" variant="h5">
            {`${userId ? 'Edit' : 'Add'} User`}
          </Typography>
          <Typography color="secondary" variant="subtitle1">
            {`${userId ? 'Editing user from' : 'Adding user to'} ${clientName}`}
          </Typography>
        </Box>
      }
      actionButtons={
        <>
          <Button onClick={onClose} variant="outlined" color="primary" fullWidth>
            Cancel
          </Button>
          <Button
            onClick={() => addUser(userId)}
            variant="contained"
            color="primary"
            fullWidth
            disabled={isButtonDisabled()}
          >
            {`${userId ? 'Save' : 'Add'} User`}
          </Button>
        </>
      }
    >
      {!isLoading ? (
        <>
          <Box
            className={classes.inputWrapper}
            mt={3}
            mb={4}
            display="flex"
            justifyContent="space-between"
          >
            <TextField
              className={classes.inputField}
              type="text"
              variant="outlined"
              label="First name"
              required
              error={Boolean(errors.givenName)}
              helperText={errors.givenName}
              value={values.given_name}
              disabled={isEditingInfoDisabled}
              onBlur={() =>
                setErrors((e) => ({ ...e, givenName: isInputEmpty(values.given_name) }))
              }
              onChange={(e) => handleChange(e.target.value, 'given_name')}
            />
            <TextField
              className={classes.inputField}
              type="text"
              variant="outlined"
              label="Last name"
              required
              error={Boolean(errors.familyName)}
              helperText={errors.familyName}
              value={values.family_name}
              disabled={isEditingInfoDisabled}
              onBlur={() =>
                setErrors((e) => ({ ...e, familyName: isInputEmpty(values.family_name) }))
              }
              onChange={(e) => handleChange(e.target.value, 'family_name')}
            />
          </Box>
          <Box
            className={classes.inputWrapper}
            mb={2}
            display="flex"
            justifyContent="space-between"
          >
            <TextField
              className={classes.inputField}
              type="email"
              variant="outlined"
              disabled={true}
              label="User email"
              required
              error={Boolean(errors.email)}
              helperText={errors.email}
              value={values.email}
              onChange={(event) => {
                setErrors((e) => ({
                  ...e,
                  email: isEmailAvailable(event.target.value, allUserEmails, true),
                }));
                handleChange(event.target.value, 'email');
              }}
              onBlur={(event) => {
                setErrors((e) => ({
                  ...e,
                  email: isEmailAvailable(event.target.value, allUserEmails, true),
                }));
              }}
            />
            <div style={{ width: '49%' }}>
              <PhoneInput
                autoFormat={true}
                disableDropdown={true}
                containerClass={classes.phoneInputHolder}
                inputClass={classes.phoneInput}
                value={values.phone_number}
                disabled={isEditingInfoDisabled}
                regions={['america', 'europe', 'asia', 'oceania', 'africa']}
                specialLabel={values.phone_number ? 'Phone number *' : ''}
                placeholder="Phone number *"
                onChange={(phone) => {
                  setErrors((e) => ({
                    ...e,
                    phoneNumber: isPhoneValidFormat(values.phone_number),
                  }));
                  handleChange(`+${phone}`, 'phone_number');
                }}
                onBlur={() => {
                  setErrors((e) => ({
                    ...e,
                    phoneNumber: isPhoneValidFormat(values.phone_number),
                  }));
                }}
              />
              {!!errors.phoneNumber && (
                <p className={classes.phoneInputError}>{errors.phoneNumber}</p>
              )}
            </div>
          </Box>
          <Box
            className={classes.inputWrapper}
            mb={2}
            display="flex"
            justifyContent="space-between"
          >
            <div style={{ width: canGrantAllFacilities ? '75%' : '100%' }}>
              <CustomMultiSelect
                id="system_ids"
                name="SystemIds"
                testId="c-multi-choice-dropdown-"
                variant="outlined"
                margin="normal"
                disabled={values.access_to_all_facilities}
                label="Grant access for"
                values={userFacilities || []}
                valueOptions={transformFacilitiesToSelectOptions(facilities) || []}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setUserFacilities(typeof value === 'string' ? value.split(',') : value);
                  setUserFacilitiesOld(typeof value === 'string' ? value.split(',') : value);
                }}
                onClear={() => setUserFacilities([])}
              />
            </div>
            {canGrantAllFacilities ? (
              <div
                style={{
                  width: '25%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CustomSwitch
                  id="access_to_all_facilities"
                  name="AccessToAllFacilities"
                  testId="c-switch-access-to-all-facilities"
                  label="Grant all facilities"
                  checked={values.access_to_all_facilities}
                  onChange={(event) => {
                    handleAccessToAllFacilitiesSwitchChange(event);
                  }}
                />
              </div>
            ) : null}
          </Box>
          <Box>
            <CustomMultiSelect
              id="user_groups"
              name="UserGroupsSelect"
              testId="UserGroupsSelect"
              variant="outlined"
              margin="normal"
              label="User group"
              values={values.user_groups || []}
              valueOptions={allUserGroups || []}
              error={Boolean(errors.userGroups)}
              errorMessage={errors.userGroups || ''}
              onChange={(event) => {
                handleChange(event.target.value, 'user_groups');
              }}
              onClear={() => setValues({ ...values, user_groups: [] })}
            />
          </Box>
        </>
      ) : (
        <AddEditUserSkeleton />
      )}
    </ModalBase>
  );
};
