import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { BarcodeMatchLogic } from 'common/functions/barcodes/getBarcodeMuiColor.util';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { Dispatch, SetStateAction, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { BarcodeVisualization } from './BarcodeVisualization';

export const ImageGridItem = ({
  index,
  imageURL,
  locationData,
  highlightedBarcode,
  barcodeMatchLogic,
  issueLogic,
  setHighlightedBarcode,
  showBarcodeHighlighting,
  systemId,
}: {
  index: number;
  imageURL: string;
  locationData: ILocationData;
  highlightedBarcode: string;
  barcodeMatchLogic: BarcodeMatchLogic | undefined;
  issueLogic: IRuleActionSTIssueLogicEnum | undefined;
  setHighlightedBarcode: Dispatch<SetStateAction<string>>;
  showBarcodeHighlighting: boolean;
  systemId: string;
}) => {
  const { stateFacilityLevel } = useFacilityLevelStore();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const showBarcodeVisualization =
    isImageLoaded &&
    stateFacilityLevel.facilitySettings?.enable_barcode_highlighting &&
    showBarcodeHighlighting;

  return (
    <>
      {!isImageLoaded && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="pulse"
          data-testid={`skeleton-${index}`}
        />
      )}
      <TransformWrapper>
        <TransformComponent>
          <img
            src={imageURL}
            onLoad={() => {
              setIsImageLoaded(true);
            }}
            alt={`DronePic - ${index}`}
            loading="lazy"
            style={{
              visibility: isImageLoaded ? 'visible' : 'hidden',
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            }}
          />
          {showBarcodeVisualization && (
            <BarcodeVisualization
              systemId={systemId}
              locationData={locationData}
              imageURL={imageURL}
              key={imageURL}
              highlightedBarcode={highlightedBarcode}
              barcodeMatchLogic={barcodeMatchLogic}
              issueLogic={issueLogic}
              setHighlightedBarcode={setHighlightedBarcode}
            />
          )}
        </TransformComponent>
      </TransformWrapper>
    </>
  );
};
