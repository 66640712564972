import { Theme } from '@mui/material/styles';
import { HEADER_HEIGHT } from 'components/Header/defaults/Header.defaults';
import { makeStyles } from 'tss-react/mui';

const TABLE_UI_OFFSET = '80px';

export const useStyles = makeStyles()((theme: Theme) => ({
  cardHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(1),
  },
  cardHeaderSection: {
    alignSelf: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(2),
    flex: '0 0 auto',
    minWidth: 0,
  },
  cardHeaderActions: {
    marginInlineStart: 'auto',
  },
  cardBody: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${TABLE_UI_OFFSET})`,
    minHeight: '500px',
  },
}));
