import { createContext, useCallback, useReducer, useContext } from 'react';
import isEmpty from 'lodash/isEmpty';
import { TokenManager } from 'common/tokenManager';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { isSSOUser } from 'features/permissions/isSSOUser';
import { setSentryUserTags } from 'common/sentry';
import { setPendoUser } from 'common/pendo';
import { authServices } from '../../services/AuthServices';
import { UserLevelReducer } from './userLevelStoreReducer';
import { UserLevelAction, UserLevelActionNames } from './userLevelActions';
import { initialUserLevelState } from './userLevelInitialState';
import { useRequestController } from '../../hooks';
import { IUserLevelData } from './IUserLevelStore';

export type UserLevelContextProps = {
  stateUserLevel: IUserLevelData;
  dispatchUserLevelStore: (action: UserLevelAction) => void;
  getUserData: () => void;
  isDataReady: () => boolean;
};

const tokenManager = TokenManager.getInstance();

export const UserLevelContext = createContext<UserLevelContextProps | undefined>(undefined);

const logPrefix = getLogPrefixForType('STORE', 'AuthStore');

export const UserLevelStoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [stateUserLevel, dispatchUserLevelStore] = useReducer(
    UserLevelReducer,
    initialUserLevelState,
  );

  const { requestController } = useRequestController(logPrefix);

  const accessToken = tokenManager.getAccessToken();

  /**
   * Request for getting user data and populating store
   */
  const getUserData = useCallback(
    () =>
      accessToken &&
      requestController.doRequest({
        request: authServices.getUser,
        requestParams: [accessToken],
        messageErrorFallback: 'User data can not be fetched.',
        callbackSuccess: async ({ data }) => {
          const usernameHashed = tokenManager.hashedUsernameFromAccessToken();

          // User has MFA active
          const isMfaAuth = !isEmpty(data.user_mfa_setting_list) || isSSOUser();
          const payload = {
            username: data.user_attributes.email,
            usernameHashed,
            isMfaAuth,
            isAuth: Boolean(accessToken),
          };

          dispatchUserLevelStore({
            type: UserLevelActionNames.SET_USER_DATA,
            payload,
          });

          console.debug(logPrefix, 'User data and profile successfully downloaded', payload);

          tokenManager.setUserAttributes(data.user_attributes);
          // userId = tokenManager.getUserAttributes()?.sub;
          setPendoUser();
          setSentryUserTags();
        },
        callbackError: () => {
          localStorage.clear();
        },
      }),
    [accessToken, requestController],
  );

  /**
   * Flag that indicates that userLevelStore is populated
   *
   * NOTE: in order to speed-up the login process, the verification on whether the web-socket
   * is authorized has been moved at ground control level store (see UD-3267).
   */
  const isDataReady = useCallback(() => stateUserLevel.isDataLoaded, [stateUserLevel.isDataLoaded]);

  return (
    <UserLevelContext.Provider
      value={{
        stateUserLevel,
        dispatchUserLevelStore,
        getUserData,
        isDataReady,
      }}
    >
      {children}
    </UserLevelContext.Provider>
  );
};

export const useUserLevelStore = () => {
  const userLevelContext = useContext(UserLevelContext);

  if (userLevelContext === undefined) {
    throw new Error('useUserLevelStore has to be used within <UserLevelContext.Provider>');
  }

  return userLevelContext;
};
