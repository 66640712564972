import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { ReportSummaryData } from '../../reducer/report-store/ReportStore.model';
import { useStyles } from './Report.styles';
import { ReportLocationsGrid } from './features/report-locations-grid/ReportLocationsGrid';
import { ReportOverview } from './features/report-overview/ReportOverview';
import { IssueSummary } from './features/report-overview/issue-summary-card/IssueSummaryCard';
import { CurrentTab } from './utils/GetTabData';
import { ReportTable } from './features/report-table/ReportTable';

export const Report = ({
  canReviewReport,
  reportNeedsReview,
  issuesSpinner,
  issuesSummary,
  summarySpinner,
  reportLocationData,
  reportExecutionData,
  currentTabData,
  reportState,
  tabs,
  changeTab,
  getReportData,
  activeTabRLG,
  reviewLocationsTab,
  fullReportSpinner,
  tableSpinner,
}: {
  canReviewReport: boolean;
  reportNeedsReview: boolean;
  issuesSpinner: number;
  issuesSummary: IssueSummary;
  summarySpinner: number;
  reportLocationData: ReportSummaryData;
  reportExecutionData: ReportSummaryData;
  currentTabData: CurrentTab;
  reportState: string;
  tabs: { icon: JSX.Element; label: string; show: boolean }[];
  getReportData: (reportId: string, nLocationsPerRequest: number) => void;
  activeTabRLG: number;
  reviewLocationsTab: number;
  fullReportSpinner: number;
  tableSpinner: boolean;
  changeTab: (tabValue: number) => void;
}) => {
  const { reportId = '' } = useParams();
  const { cx, classes } = useStyles();

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { facilitySettings } = stateFacilityLevel;

  const currentActiveSpinner: Record<string, boolean> = {
    issues: tableSpinner,
    'solved issues': tableSpinner,
    amended: tableSpinner,
    'snoozed issues': tableSpinner,
    'potential issues': tableSpinner,
    'inconclusive issues': tableSpinner,
    'invalid issues': tableSpinner,
    report: tableSpinner,
    'locations to review': tableSpinner,
  };

  return (
    <div className={classes.wrapper}>
      <div className={cx(classes.section, classes.sectionColumns)}>
        <ReportOverview
          summarySpinner={summarySpinner}
          issuesSpinner={issuesSpinner}
          issuesSummary={issuesSummary}
          canReviewReport={canReviewReport}
          reportNeedsReview={reportNeedsReview}
          reportLocationData={reportLocationData}
          reportExecutionData={reportExecutionData}
        />
      </div>

      {!isEmpty(tabs) && (
        <div className={cx(classes.section, classes.sectionFullWidth)}>
          {facilitySettings?.enable_experimental_report_grid ? (
            <ReportTable />
          ) : (
            <div className={classes.cardBody}>
              <ReportLocationsGrid
                tabs={tabs}
                activeTab={activeTabRLG}
                changeTab={changeTab}
                reviewLocationsTab={reviewLocationsTab}
                currentTabData={currentTabData}
                reportId={reportId}
                isLoading={fullReportSpinner}
                currentActiveSpinner={currentActiveSpinner}
                reportState={reportState}
                reportNeedsReview={reportNeedsReview}
                getReportData={getReportData}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
