import { INoFlyZoneUpdatePropertiesRequestST } from 'codegen/no_fly_zone';
import { IRequestController } from 'hooks';
import { groundControlServices } from 'services/GroundControlServices';
import { bottomLeftDimensionsToGeometry } from 'shared/map-container/utils/creation.util';
import { NEW_DRONE_ZONE_ID } from 'shared/map/defaults/new-drone-zone-id';
import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';

export const createOrUpdateNoFlyZone = ({
  requestController,
  noFlyZone,
  onSuccess,
  systemId,
  flightDomainId,
}: {
  requestController: IRequestController;
  noFlyZone: NoFlyZone;
  onSuccess: () => void;
  systemId: string;
  flightDomainId: string;
}) => {
  const isNew = noFlyZone.id === NEW_DRONE_ZONE_ID;
  const request = isNew
    ? groundControlServices.createNoFlyZone
    : groundControlServices.updateNoFlyZoneProperties;

  const geo = bottomLeftDimensionsToGeometry(noFlyZone.sizeAndPosition);

  const nfz: INoFlyZoneUpdatePropertiesRequestST = {
    name: noFlyZone.name,
    description: noFlyZone.description,
    geometry: geo,
  };

  const { signal } = requestController.reserveSlotForRequest();

  const payload = {
    systemId,
    flightDomainId,
    nfzId: isNew ? undefined : noFlyZone.id,
    nfz,
    signal,
  };

  return requestController.doRequest({
    request,
    requestParams: [payload],
    messageErrorFallback: 'Something went wrong while performing this action!',
    callbackSuccess: onSuccess,
  });
};
