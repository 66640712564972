import { DroneZone } from 'shared/map/model/drone-zone.model';
import { DroneZoneFormProps } from './droneZone.model';
import { ImperialDroneZoneForm } from './features/ImperialDroneZoneForm/ImperialDroneZoneForm';
import { MetricDroneZoneForm } from './features/MetricDroneZoneForm/MetricDroneZoneForm';
import { useMeasurementSystem } from './hooks/useMeasurementSystem';

export const DroneZoneForm = <T extends DroneZone>(props: DroneZoneFormProps<T>) => {
  const { isMetricSystem } = useMeasurementSystem();

  return isMetricSystem ? <MetricDroneZoneForm {...props} /> : <ImperialDroneZoneForm {...props} />;
};
