import globalAxios from 'axios';
import pako from 'pako';

const getTransformRequest = () => {
  if (Array.isArray(globalAxios.defaults.transformRequest)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return globalAxios.defaults.transformRequest.concat((data: any, headers: any) => {
      if (typeof data === 'string' && data.length > Infinity) {
        headers['Content-Encoding'] = 'gzip';
        return pako.gzip(data);
      }
      return data;
    });
  }
  if (globalAxios.defaults.transformRequest !== undefined) {
    return globalAxios.defaults.transformRequest;
  }
  return undefined;
};

export const config = {
  transformRequest: getTransformRequest(),
};
