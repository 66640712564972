import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import { StandardPlaceholder } from '../standard-placeholder/StandardPlaceholder';
import { useStyles } from './errorPlaceholder.styles';

export const ErrorPlaceholder = () => {
  const { classes } = useStyles();

  useEffect(() => {
    Sentry.captureMessage('Remote: Picking Locations - a bug crashed the application');
  }, []);

  return (
    <StandardPlaceholder
      icon={
        <div className={classes.iconWrapper}>
          <WarningIcon color="inherit" size="inherit" />
        </div>
      }
    >
      <Typography variant="body2">
        There was a problem displaying the Counting reports, it may be only an issue specific to one
        report. Our engineers have been made aware there was an issue, please try again later.
      </Typography>
    </StandardPlaceholder>
  );
};
