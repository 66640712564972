import { TextField } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { FormInputNumberProps } from './FormInputNumber.model';
import { isValidDecimalLength } from './utils/isValidDecimalLength';
import { DEFAULT_DECIMAL_PLACES } from '../defaults/FormInput.defaults';

export const FormInputNumber = ({
  name,
  placeholder = '',
  label = '',
  size = 'medium',
  decimalPlaces = DEFAULT_DECIMAL_PLACES,
  onChange,
  onBlur,
  ...rest
}: FormInputNumberProps) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: FieldProps['field'],
  ) => {
    field.onChange(event);

    if (onChange) {
      onChange(event);
    }
  };

  const handleBlur = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: FieldProps['field'],
  ) => {
    field.onBlur(event);

    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <Field name={name} validate={(value: number) => isValidDecimalLength(value, decimalPlaces)}>
      {({ field, meta }: FieldProps) => (
        <TextField
          name={name}
          value={field.value}
          placeholder={placeholder}
          label={label}
          onChange={(event) => handleChange(event, field)}
          onBlur={(event) => handleBlur(event, field)}
          type="number"
          size={size}
          error={!!(meta.touched && meta.error)}
          helperText={meta.error}
          {...rest}
        />
      )}
    </Field>
  );
};
