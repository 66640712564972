export const premiumGridStyles = {
  border: 0,
  borderRadius: 0,
  width: '100%',
  borderWidth: 0,
  display: 'grid',
  gridTemplateColumns: 'minmax(0, 1fr)',
  gridTemplateRows: 'min-content minmax(0, 1fr) min-content',

  '& .MuiDataGrid-container--top [role=row]': {
    backgroundColor: '#ffffff',
  },

  '& .MuiDataGrid-container--bottom [role=row]': {
    backgroundColor: '#ffffff',
  },

  '& .MuiDataGrid-overlayWrapper': {
    zIndex: '0 !important',
  },

  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#ffffff',
  },

  '& :not(.MuiDataGrid-headerFilterRow) .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
    border: '1px solid #D3D3D3 !important',
  },

  '& :not(.MuiDataGrid-headerFilterRow) .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor:nth-of-type(n+2)':
    {
      borderLeft: '0 !important',
    },

  '& .MuiDataGrid-headerFilterRow .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
    border: '0 !important',
  },

  '& .MuiDataGrid-headerFilterRow .MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--header':
    {
      border: '0 !important',
    },

  '& .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
    borderTopWidth: '0 !important',
  },

  '& .MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--header': {
    borderTopWidth: '0 !important',
  },

  '& .MuiDataGrid-columnHeader input': {
    fontSize: 'small',
  },

  '& .row-even': {
    backgroundColor: 'rgb(250, 250, 250)',
  },

  '.MuiDataGrid-cell:focus-within,': {
    outline: 'none !important',
  },

  '.MuiDataGrid-columnHeader:focus-within,': {
    outline: 'none !important',
  },

  '& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeader': {
    backgroundColor: 'rgba(0,0,0,0)',
  },

  '& .MuiDataGrid-cell': { borderTopStyle: 'none' },

  '& .MuiDataGrid-headerFilterRow .MuiDataGrid-columnHeader label': {
    fontSize: 'small',
  },

  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },

  '& .MuiDataGrid-filler': {
    marginTop: '0 !important',
  },
};
