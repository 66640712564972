import { Group } from 'three';
import { forwardRef, memo, Ref } from 'react';
import { Bin3DContainer } from '../bin3D/Bin3D';
import { AisleViewProps } from './model/aisleViewProps.model';

export const AisleView = memo(
  forwardRef(({ bins }: AisleViewProps, ref: Ref<Group>) => (
    <Bin3DContainer ref={ref} bins={bins} />
  )),
);

AisleView.displayName = 'AisleView';
