import { useMutation, useQuery } from '@tanstack/react-query';
import { ILabelTypeST } from 'codegen/location_action';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { locationActionApi } from '../api/locationLabel.api';

export const useLocationLabelApi = ({ locationId }: { locationId: string }) => {
  const { currentSystemId: systemId } = useFacilityLevelStore().stateFacilityLevel;

  const { isLoading, data, error, refetch } = useQuery({
    queryKey: ['LabelsCardGet', locationId],
    queryFn: () => locationActionApi().getLocationLabel(systemId as string, locationId),
    select: ({ data }) => ({
      ...data,
    }),
    retry: false,
  });

  const { mutate: mutateAddLabel } = useMutation({
    mutationKey: ['LabelsCardAdd', locationId],
    mutationFn: (labelType: ILabelTypeST) =>
      locationActionApi()
        .postLocationLabel(systemId as string, locationId, {
          label_type: labelType,
        })
        .then(() => refetch()),
  });

  const { mutate: mutateArchiveLabel } = useMutation({
    mutationKey: ['LabelsCardArchive', locationId],
    mutationFn: () =>
      locationActionApi()
        .putLocationLabel(systemId as string, locationId, {
          label_status: 'ARCHIVED',
        })
        .then(() => refetch()),
  });

  return { isLoading, data, error, mutateAddLabel, mutateArchiveLabel };
};
