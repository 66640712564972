import Container from '@mui/material/Container';

import './style.css';

import { Link, useParams, useSearchParams } from 'react-router-dom';
import { PageHeaderSection } from 'components/Page/PageHeaderSection';
import { CardGrid } from 'components/common/CardGrid/CardGrid';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { Button } from '@mui/material';
import { FEATURE_FLAG_MAP_3D } from 'shared/map-3d/map3DFeatureFlag.model';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { Box } from 'components/common/Box';
import { HomeReportsGrid } from './HomeReportsGrid';
import { HomeWarehouseStatGrid } from './HomeWarehouseStatGrid';
import { userHasSomePermissions } from '../../features/permissions/userHasPermission';
import { PERMISSION } from '../../features/permissions/permissions.model';
import warehouseStatusBG from './warehouse-status-bg.webp';

export const Home = () => {
  const facilityName = localStorage.getItem('facilityName');
  const { systemId } = useParams();
  const [searchParams] = useSearchParams();
  const {
    stateFacilityLevel: { facilitySettings },
  } = useFacilityLevelStore();

  const show3DMap =
    facilitySettings.enable_3d_warehouse_status || searchParams.has(FEATURE_FLAG_MAP_3D);

  const isHomeReportsGridVisible: boolean = userHasSomePermissions([
    PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT,
    PERMISSION.SCHEDULED_REPORTS_MANAGEMENT,
    PERMISSION.VIEW_ONGOING_REPORTS,
    PERMISSION.VIEW_FINISHED_REPORTS,
  ]);

  const isHomeWarehouseStatGridVisible: boolean = userHasSomePermissions([
    PERMISSION.WMS_MANAGEMENT,
    PERMISSION.VIEW_WAREHOUSE_STATUS,
  ]);

  return (
    <>
      <PageHeaderSection
        title="Inventory overview"
        subtitle={`Here is your inventory overview for the ${facilityName} facility`}
        customBtn={
          show3DMap ? (
            <Box
              sx={{
                paddingTop: 3.5,
                paddingBottom: 3.5,
                paddingLeft: 8,
                paddingRight: 8,
                backgroundImage: `url(${warehouseStatusBG})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <Button
                variant="contained"
                component={Link}
                to={`..${INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_MAP}`}
              >
                View map
              </Button>
            </Box>
          ) : null
        }
      />

      <Container maxWidth="xl" sx={{ paddingTop: '32px' }}>
        <CardGrid columnCount={2} className="c-page-content">
          {isHomeWarehouseStatGridVisible && (
            <HomeWarehouseStatGrid systemId={systemId as string} />
          )}

          {isHomeReportsGridVisible && <HomeReportsGrid />}
        </CardGrid>
      </Container>
    </>
  );
};
