export const getBatchStartSlotName = (
  slotName: string,
  locationNames: string[],
  requestPageSize: number,
) => {
  const slotIndex = locationNames.findIndex((s) => s === slotName);

  const batchIndex = Math.floor(slotIndex / requestPageSize) * requestPageSize;

  return locationNames[batchIndex];
};
