import { useParams } from 'react-router-dom';
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium';
import { premiumGridStyles } from 'udb/inventory/features/premium-grid/premiumGrid.styles';
import { useMemo, useState } from 'react';
import { useItemData } from '../../hooks/useItemData';
import { getItemViewColumns } from './ItemViewColumns.model';
import { BARCODE_VIEW_SEGMENT_SIZE } from './ItemView.model';
import { ItemViewCustomToolbar } from './ItemViewCustomToolbar';

export const ItemView = () => {
  const { systemId = '' } = useParams();
  const { itemData: barcodeData, isLoading } = useItemData(systemId);
  const gridApiRef = useGridApiRef();
  const [startSlot, setStartSlot] = useState(0);

  const barcodes = useMemo(() => Object.values(barcodeData), [barcodeData]);

  const rows = useMemo(
    () =>
      barcodes.slice(startSlot, Math.min(startSlot + BARCODE_VIEW_SEGMENT_SIZE, barcodes.length)),
    [barcodes, startSlot],
  );

  return (
    <DataGridPremium
      sx={{ ...premiumGridStyles, height: '89vh' }}
      columns={getItemViewColumns()}
      apiRef={gridApiRef}
      rows={rows}
      headerFilters
      headerFilterHeight={60}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd'
      }
      slots={{ toolbar: () => ItemViewCustomToolbar({ barcodes, setStartSlot }) }}
      loading={isLoading}
      pagination={true}
      paginationMode="client"
      pageSizeOptions={[
        { value: 100, label: '100' },
        { value: 1000, label: '1000' },
        { value: 10000, label: '10000' },
        { value: 100000, label: '100000' },
      ]}
    />
  );
};
