import { Grid } from '@mui/material';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsCard } from '../../SettingsCard';
import { BarcodeLengths } from '../../barcodes/BarcodeLengths';
import { getBarcodesOptions } from '../../options/getBarcodesOptions';
import { ErrorsList } from '../../models/ErrorsList.model';

export const BarcodeValidation = ({
  isLoading,
  facilitySettings,
  handleValuesChange,
  errors,
  setErrors,
}: {
  isLoading: boolean;
  facilitySettings: IFacilitySettingsST;
  handleValuesChange: (prop: string, value: string | number | number[] | boolean) => void;
  errors: ErrorsList;
  setErrors: React.Dispatch<React.SetStateAction<ErrorsList>>;
}) => (
  <Grid item>
    <SettingsCard
      title="Barcodes"
      isLoading={isLoading}
      items={getBarcodesOptions(facilitySettings, handleValuesChange, errors)}
      testId="c-barcodes-settings-card"
      content={
        <BarcodeLengths
          subtitle="Barcode invalid lengths"
          errors={errors.validBarcodeLength}
          setErrors={(error: string) => setErrors({ ...errors, validBarcodeLength: error })}
          min={facilitySettings.barcode_min_length ?? 0}
          max={facilitySettings.barcode_max_length ?? Infinity}
          barcodeLengths={
            facilitySettings.barcode_invalid_lengths ? facilitySettings.barcode_invalid_lengths : []
          }
          onBarcodeLengthChanges={(e: number[]) => {
            handleValuesChange('barcode_invalid_lengths', e);
          }}
        />
      }
    />
  </Grid>
);
