import { getFacilityApiService } from './services';

/**
 * Get the facility corresponding to the giving System ID
 * @param systemId System ID (of the facility)
 * @returns Facility data
 */
const getFacility = (systemId: string, signal: AbortSignal) =>
  getFacilityApiService().getFacility(systemId, { signal });

/**
 * Get the facility information corresponding to the given System ID
 * @param systemId System ID (of the facility)
 * @param signal Abort Controller
 * @returns Facility data
 */
const getFacilityInformation = (systemId: string, signal: AbortSignal) =>
  getFacilityApiService().getFacilityInformation(systemId, { signal });

/**
 * Get the facility slots information
 * @param systemId System ID (of the facility)
 * @param signal Abort Controller
 * @returns Facility data
 */
const getFacilitySlots = (systemId: string, signal?: AbortSignal) =>
  getFacilityApiService().getFacilitySlots(systemId, { signal });

/**
 * Get the facilities logos
 * @param systemId System ID (of the facility)
 * @returns list of logo images URLs
 */
const getFacilityLogos = (systemId: string, signal: AbortSignal) =>
  getFacilityApiService().getFacilityLogos(systemId, { signal });

const getFacilityMapFile = (systemId: string) =>
  getFacilityApiService().getFacilityMapPresignedUrl(systemId);

const getFacilityMapProcessingLog = (systemId: string) =>
  getFacilityApiService().getFacilityProcessingLog(systemId);

const getFacilityMapInfo = (systemId: string, signal: AbortSignal) =>
  getFacilityApiService().getFacilityMapInfo(systemId, { signal });

export const facilityServices = {
  getFacility,
  getFacilityInformation,
  getFacilitySlots,
  getFacilityLogos,
  getFacilityMapInfo,
  getFacilityMapFile,
  getFacilityMapProcessingLog,
};
