import { NETWORK_REQUEST_SETTINGS } from 'common/settings';
import { initWarehouseStatusService } from './warehouseStatusService';

/**
 * Get the Location Data
 * @param systemId System ID (of the facility)
 * @param fromSlot identifier of the first slot to be fetch
 * @param nLocations number of location to fetch
 * @param signal Axios Abort Signal
 * @returns Dictionary of Location Data
 */
export const getLocationsData = (
  systemId: string,
  fromSlot: string,
  nLocations: number,
  signal?: AbortSignal,
) => {
  const params = {
    from: fromSlot,
    limit: nLocations,
  };

  const raxConfig = {
    retry: NETWORK_REQUEST_SETTINGS.RETRIES,
    noResponseRetries: NETWORK_REQUEST_SETTINGS.RETRIES,
  };

  return initWarehouseStatusService().getLocationsData(systemId, { params, signal, raxConfig });
};

/**
 * Get the Location Metadata
 * @param systemId System ID (of the facility)
 * @param fromSlot identifier of the first slot to be fetch
 * @param nLocations number of location to fetch
 * @param signal Axios Abort Signal
 * @returns Dictionary of Location Data
 */
export const getLocationsMetadata = (
  systemId: string,
  fromSlot: string,
  nLocations: number,
  signal: AbortSignal,
) => {
  const params = {
    from: fromSlot,
    limit: nLocations,
  };

  const raxConfig = {
    retry: NETWORK_REQUEST_SETTINGS.RETRIES,
    noResponseRetries: NETWORK_REQUEST_SETTINGS.RETRIES,
  };

  return initWarehouseStatusService().getLocationsMetaData(systemId, { params, signal, raxConfig });
};

/**
 * Get all the Issues for the given facility
 * @param systemId ID of the facility
 * @returns Dictionary of all the issues
 */
export const getLocationsWithIssues = (
  systemId: string,
  fromSlot: string,
  nLocations: number,
  fetchIssuesOnly: boolean,
  signal: AbortSignal,
) => {
  const params = {
    from: fromSlot,
    limit: nLocations,
    fetch_issues_only: fetchIssuesOnly,
  };

  const raxConfig = {
    retry: NETWORK_REQUEST_SETTINGS.RETRIES,
    noResponseRetries: NETWORK_REQUEST_SETTINGS.RETRIES,
  };

  return initWarehouseStatusService().getLocationsData(systemId, { params, signal, raxConfig });
};
