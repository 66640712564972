import { AlertColor } from '@mui/material';
import { useReducer } from 'react';

export type UseAlertProps = {
  show: boolean;
  status: AlertColor;
  title?: string;
  message: string;
  preventClose: boolean;
};
interface UseAlertInitialState {
  WMSSnapshot: UseAlertProps | null;
  StockCheck: UseAlertProps | null;
  MovedLocations: UseAlertProps | null;
  FacilityMap: UseAlertProps | null;
  Report: UseAlertProps | null;
  Issue: UseAlertProps | null;
  NoFlyZones: UseAlertProps | null;
}

export type UseAlertKeys = keyof UseAlertInitialState;

export interface AlertProps {
  label: UseAlertKeys;
  title?: string;
  message?: string;
  preventClose?: boolean;
}

interface UseAlertReducerAction extends AlertProps {
  type: 'success' | 'warning' | 'info' | 'error' | 'close';
}

const initialState: UseAlertInitialState = {
  WMSSnapshot: null,
  StockCheck: null,
  MovedLocations: null,
  FacilityMap: null,
  Report: null,
  Issue: null,
  NoFlyZones: null,
};

const reducer = (state = initialState, action: UseAlertReducerAction) => {
  switch (action.type) {
    case 'success':
      return {
        ...state,
        [action.label]: {
          show: true,
          title: action.title,
          message: action.message,
          status: 'success',
          preventClose: action.preventClose,
        },
      };
    case 'warning':
      return {
        ...state,
        [action.label]: {
          show: true,
          title: action.title,
          message: action.message,
          status: 'warning',
          preventClose: action.preventClose,
        },
      };
    case 'info':
      return {
        ...state,
        [action.label]: {
          show: true,
          title: action.title,
          message: action.message,
          status: 'info',
          preventClose: action.preventClose,
        },
      };
    case 'error':
      return {
        ...state,
        [action.label]: {
          show: true,
          title: action.title,
          message: action.message,
          status: 'error',
          preventClose: action.preventClose,
        },
      };
    case 'close':
      return {
        ...state,
        [action.label]: {},
      };
    default:
      return initialState;
  }
};

export const useAlert = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const success = ({ title, message, preventClose, label }: AlertProps) => {
    dispatch({
      type: 'success',
      message,
      title,
      preventClose,
      label,
    });
  };

  const warning = ({ title, message, preventClose, label }: AlertProps) => {
    dispatch({
      type: 'warning',
      message,
      title,
      preventClose,
      label,
    });
  };

  const info = ({ title, message, preventClose, label }: AlertProps) => {
    dispatch({
      type: 'info',
      message,
      title,
      preventClose,
      label,
    });
  };

  const error = ({ title, message, preventClose, label }: AlertProps) => {
    dispatch({
      type: 'error',
      message,
      title,
      preventClose,
      label,
    });
  };

  const close = (label: UseAlertKeys) => {
    if (state?.[label] && Object.hasOwn(state[label], 'show')) {
      dispatch({ type: 'close', label });
    }
  };

  return {
    alertState: state,
    showAlert: {
      success,
      warning,
      info,
      error,
      close,
    },
  };
};
