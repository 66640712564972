import { matchBarcode } from 'common/functions/barcodes/matchBarcode.util';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { BarcodeRow, BarcodeStatusEnum } from '../models/BarcodeRow.model';

export const makeBarcodeArray = ({
  locationData,
  expectedBarcodes,
  foundBarcodes,
}: {
  locationData: ILocationData;
  expectedBarcodes?: string[];
  foundBarcodes?: string[];
}) => {
  const { barcodeExpectedFoundOn, barcodeFoundShouldBeOn, wmsQty, wmsArticleNo, wmsCustomer } =
    locationData.rowData;

  const result = [];
  const maxLength = Math.max(expectedBarcodes?.length || 0, foundBarcodes?.length || 0);
  for (let i = 0; i < maxLength; i += 1) {
    const expected = expectedBarcodes && expectedBarcodes.length > i ? expectedBarcodes[i] : '';
    const found = foundBarcodes && foundBarcodes.length > i ? foundBarcodes[i] : '';
    const barcodeStatus = matchBarcode(expected, found);

    const barcodeRow: BarcodeRow = {
      id: 2 + result.length,
      locationData,
      barcodeStatus,
      expectedContent: expected && expected !== 'No match' ? expected : '',
      contentFound: found && found !== 'No match' ? found : '',
      foundAt:
        barcodeStatus === BarcodeStatusEnum.Missing && barcodeExpectedFoundOn !== '-'
          ? barcodeExpectedFoundOn
          : '',
      shouldBeAt:
        barcodeStatus === BarcodeStatusEnum.Unexpected && barcodeFoundShouldBeOn !== '-'
          ? barcodeFoundShouldBeOn
          : '',
      wmsQuantity: wmsQty[i] && wmsQty[i] !== '-' ? wmsQty[i] : '',
      articleNumber: wmsArticleNo[i] && wmsArticleNo[i] !== '-' ? wmsArticleNo[i] : '',
      customer: wmsCustomer[i] && wmsCustomer[i] !== '-' ? wmsCustomer[i] : '',
    };

    result.push(barcodeRow);
  }
  return result;
};
