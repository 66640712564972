import { IReportST } from 'codegen/report';
import { LocalStore } from 'common/functions/storageFunctions';
import { REPORT_STATES } from 'common/reportStates';
import { sort } from 'common/functions/otherFunctions';
import moment from 'moment';
import { DATETIME_FORMAT_WITH_WEEKDAY, RELATIVE_DATETIME_FORMAT } from 'common/datetimeFormats';
import { isReportFinishedIncomplete } from 'common/functions/reportFunctions';
import { Report } from '../../../Reports.model';
import { IProcessReportSummariesData } from './processReportSummariesData.model';
import { generateRowData } from './generateRowData';
import { ReportsInfo } from '../reportsInfo.model';
import { ReportInCard } from '../reportInCard.model';

const getFinishedReports = (finishedReports: Report[], timezone: string): ReportInCard[] =>
  finishedReports.map((report: Report) => ({
    reportId: report.id,
    insightType: report.insightType,
    reportType: report.reportType,
    date: `finished on ${moment(report.finishedAt)
      .tz(timezone)
      .format(DATETIME_FORMAT_WITH_WEEKDAY)}`,
    recurrence: report.recurrence !== '-',
    recurrenceDescription: report.recurrence || '',
    reportStatus: report.status,
    reportName: report.reportName,
    totalLocations: isReportFinishedIncomplete(report.rawData)
      ? `${report.scannedLocations} of ${report.locationsToScan}`
      : report.allLocations.toString(),
    totalIssues: report.issues,
  }));

const getOngoingReports = (ongoingReports: Report[], timezone: string): ReportInCard[] =>
  ongoingReports.map((report: Report) => ({
    reportId: report.id,
    insightType: report.insightType,
    reportType: report.reportType,
    date: `${
      report.startedAt !== '-'
        ? `started on ${moment(report.startedAt).tz(timezone).format(DATETIME_FORMAT_WITH_WEEKDAY)}`
        : `dispatched on ${moment(report.scheduledFor)
            .tz(timezone)
            .format(DATETIME_FORMAT_WITH_WEEKDAY)}`
    }`,
    recurrence: report.recurrence !== '-',
    recurrenceDescription: report.recurrence || '',
    progress: report.progress,
    completion: report.completion,
    reportStatus: report.status,
    reportName: report.reportName,
    totalLocations: `${report.allLocations}`,
    locationsToScan: `${report.locationsToScan}`,
    actions: report.actions,
  }));

const getToReviewReports = (reportsToReview: Report[], timezone: string): ReportInCard[] =>
  reportsToReview.map((report: Report) => ({
    reportId: report.id,
    insightType: report.insightType,
    reportType: report.reportType,
    date: `started on ${moment(report.startedAt)
      .tz(timezone)
      .format(DATETIME_FORMAT_WITH_WEEKDAY)}`,
    recurrence: report.recurrence !== '-',
    recurrenceDescription: report.recurrence || '',
    progress: report.progress,
    completion: report.completion,
    reportStatus: report.status,
    reportName: report.reportName,
    totalLocations: report.allLocations,
    locationsToScan: report.locationsToScan,
    actions: report.actions,
  }));

export const processReportSummariesData = (
  reports: IReportST[],
  data?: IProcessReportSummariesData | undefined,
) => {
  const timezone = LocalStore.getTimezone();

  let reportsFinishedAll = data?.reportsFinishedAll || [];
  let reportsOngoingAll = data?.reportsOngoingAll || [];
  let reportsToReviewAll = data?.reportsToReviewAll || [];
  let reportsArchivedAll = data?.reportsArchivedAll || [];

  reports.forEach((row) => {
    const rowData = generateRowData(row);

    if (row.state === REPORT_STATES.FINISHED.toString()) {
      reportsFinishedAll.push(rowData);
    } else if (
      [REPORT_STATES.ONGOING.toString(), REPORT_STATES.SCHEDULED.toString()].includes(row.state)
    ) {
      row.num_locations_to_review && reportsToReviewAll.push(rowData);
      reportsOngoingAll.push(rowData);
    } else if (row.state === REPORT_STATES.DELETED.toString()) {
      reportsArchivedAll.push(rowData);
    }
  });

  reportsFinishedAll = sort({
    array: reportsFinishedAll,
    sortingOrder: 'desc',
    sortBy: 'finishedAt',
  });

  reportsToReviewAll = sort({
    array: reportsToReviewAll,
    sortingOrder: 'desc',
    sortBy: 'startedAt',
  });

  reportsOngoingAll = sort({
    array: reportsOngoingAll,
    sortingOrder: 'desc',
    sortBy: 'startedAt',
  });

  reportsArchivedAll = sort({
    array: reportsArchivedAll,
    sortingOrder: 'desc',
    sortBy: 'startedAt',
  });

  const reportsInfo: ReportsInfo = {
    finishedReports: {
      numberOfReports: reportsFinishedAll.length,
      lastUpdatedAt: reportsFinishedAll[0]
        ? moment(reportsFinishedAll[0].finishedAt)
            .tz(timezone)
            .calendar(null, RELATIVE_DATETIME_FORMAT)
        : '',
    },
    ongoingReports: {
      numberOfReports: reportsOngoingAll.length,
      mostRecentStart: reportsOngoingAll[0]
        ? moment(
            reportsOngoingAll[0].startedAt !== '-'
              ? reportsOngoingAll[0].startedAt
              : reportsOngoingAll[0].scheduledFor,
          )
            .tz(timezone)
            .calendar(null, RELATIVE_DATETIME_FORMAT)
        : '',
    },
    toReviewReports: {
      numberOfReports: reportsToReviewAll.length,
      lastUpdatedAt: reportsToReviewAll[0]
        ? moment(reportsToReviewAll[0].startedAt)
            .tz(timezone)
            .calendar(null, RELATIVE_DATETIME_FORMAT)
        : '',
    },
  };

  // ######################################################
  // Data for Latest reports card on Homepage
  // ######################################################
  const slicedFinishedReports = reportsFinishedAll.slice(0, 4);
  const finishedReports = getFinishedReports(slicedFinishedReports, timezone);

  // ######################################################
  // Data for Ongoing reports card on Homepage
  // ######################################################
  const ongoingReports = getOngoingReports(reportsOngoingAll, timezone);

  // ######################################################
  // Data for To review card on Homepage
  // ######################################################
  const toReviewReports = getToReviewReports(reportsToReviewAll, timezone);

  return {
    reportsFinishedAll,
    reportsOngoingAll,
    reportsToReviewAll,
    reportsArchivedAll,
    reportsInfo,
    finishedReports,
    ongoingReports,
    toReviewReports,
  };
};
