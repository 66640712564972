import { Theme, Typography } from '@mui/material';
import {
  AllIssueTypes,
  issueTypeEnumToColor,
  muiColorToTheme,
} from 'common/functions/issues/issueColorFunctions';
import { ILocationDataST, IWMSSlotStatusST } from 'codegen/warehouse_status';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';
import { getContentCellColor, isDataStale } from './ContentCellFunctions';

const getIssueColor = (
  locationData: ILocationDataST,
  theme: Theme,
  issueLogic: IRuleActionSTIssueLogicEnum | undefined,
) => {
  const hasIssues = !!locationData.issues?.length;
  if (hasIssues) {
    const issue = locationData.issues[0];
    const issueType = issueTypeEnumToColor[issue.type as AllIssueTypes];
    return muiColorToTheme(issueType, theme).main;
  }
  const isStale = isDataStale(locationData);
  const wmsStatus = locationData.wms_status as IWMSSlotStatusST;
  return getContentCellColor(!!wmsStatus, theme, undefined, isStale, issueLogic);
};

const useNoBarcodeLineStyles = (
  locationData: ILocationDataST,
  issueLogic: IRuleActionSTIssueLogicEnum | undefined,
) =>
  makeStyles()((theme: Theme) => ({
    body: {
      color: getIssueColor(locationData, theme, issueLogic),
      cursor: 'pointer',
    },
  }));

export const NonBarcodeLine = ({
  children,
  locationData,
  issueLogic,
}: PropsWithChildren<{
  locationData: ILocationDataST;
  issueLogic: IRuleActionSTIssueLogicEnum | undefined;
}>) => {
  const { classes } = useNoBarcodeLineStyles(locationData, issueLogic)();

  return (
    <Typography className={classes.body} variant="body2" component="span">
      {children}
    </Typography>
  );
};
