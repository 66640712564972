import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useMap3DCard = makeStyles()((theme: Theme) => ({
  cardContent: {
    paddingTop: 0,
  },
  canvasWrapper: {
    display: 'flex',
    minHeight: '30vh',
    height: '400px',
    position: 'relative',
    transform: 'translateZ(0)', // bump to new stacking context for fixed position elements
  },
  canvas: {
    minHeight: '30vh',
    borderRadius: 2 * theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
  aisleSummary: {
    position: 'absolute',
    left: theme.spacing(2),
    bottom: theme.spacing(2),
    maxWidth: '80%',
    flexWrap: 'wrap',
  },
  zoomControls: {
    position: 'absolute',
    right: theme.spacing(3),
    bottom: theme.spacing(2),
  },
  drawerPaper: {
    height: '100%',
  },
}));
