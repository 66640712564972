import { getTimeIntervalDates, loadSinceInterval } from 'common/functions/dateTimeFunctions';
import { TIMEFRAME_TO_FETCH_UPLOADED_FILES_FROM } from 'common/settings';

export const calculateDates = (
  loadSince: string | null,
  urlParamFromDate: string | null,
  urlParamUntilDate: string | null,
) => {
  if (urlParamFromDate && urlParamUntilDate) {
    return { from: urlParamFromDate, until: urlParamUntilDate };
  }
  return loadSince
    ? getTimeIntervalDates(loadSince as loadSinceInterval)
    : getTimeIntervalDates(TIMEFRAME_TO_FETCH_UPLOADED_FILES_FROM);
};
