import {
  gridFilteredSortedRowIdsSelector,
  gridFilterModelSelector,
  gridRowTreeSelector,
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { ILocationDataST } from 'codegen/report';
import { MutableRefObject } from 'react';
import isEmpty from 'lodash/isEmpty';

const getFilteredLocationsFromGrid = (gridApiRef: React.MutableRefObject<GridApiPremium>) => {
  const tree = gridRowTreeSelector(gridApiRef);

  return gridFilteredSortedRowIdsSelector(gridApiRef)
    .filter((rowId) => tree[rowId].type !== 'group') // removes the grouping rows
    .map((rowId) => gridApiRef.current.getRow(rowId) as ILocationDataST); // maps row ids to rows
};

const hasGridFiltersApplied = (gridApiRef: MutableRefObject<GridApiPremium>) =>
  !isEmpty(gridApiRef.current) && gridFilterModelSelector(gridApiRef).items.length;

export const getFilteredLocationDataListFromGrid = (
  gridApiRef: MutableRefObject<GridApiPremium>,
): undefined | ILocationDataST[] => {
  if (!hasGridFiltersApplied(gridApiRef)) {
    return undefined;
  }

  return getFilteredLocationsFromGrid(gridApiRef);
};
