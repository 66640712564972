import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    paddingBlock: theme.spacing(1),
  },
  wrapperProgress: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0px, 1fr))',
    gridAutoRows: 'min-content',
    gap: '24px',
  },
  wrapperPie: {
    alignItems: 'center',
    containerName: 'chartDoughnutContainer',
    containerType: 'inline-size',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '32px',
    position: 'relative',
  },
  wrapperPieGraphdWrapper: {
    flex: '1 1 100%',
    minWidth: '0',
    position: 'relative',

    '@container chartDoughnutContainer (min-inline-size: 350px)': {
      flex: '1 1 clamp(100px, calc(50% - 32px), 100%)',
    },
  },
  wrapperPieGraphWrapper: {
    display: 'flex',
    justifyContent: 'center',
    minwidth: 0,
    flex: '1 1 100%',

    '@container chartDoughnutContainer (min-inline-size: 350px)': {
      flex: '1 1 clamp(100px, calc(50% - 32px), 100%)',
    },
  },
  wrapperPieGraphWrapperBody: {
    flex: '0 0 auto',
  },
  wrapperPieLegendWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(140px, 1fr))',
    gridAutoRows: 'min-content',
    gap: '16px',
    flex: '1 1 100%',
    width: 'clamp(125px, 30%, 100%)',
    maxWidth: '100%',

    '@container chartDoughnutContainer (min-inline-size: 350px)': {
      flex: '1 1 clamp(100px, calc(50% - 32px), 100%)',
    },
  },
}));
