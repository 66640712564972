import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Box } from 'components/common/Box';
import { Footer } from 'components/Footer/Footer';
import { FacilityMenu } from 'components/common/FacilityMenu/FacilityMenu';
import { Spinner } from 'components/common/Spinner';
import { useSlots, useLoadFacilityLevelData } from 'hooks';
import { useFacilityModalsStore } from '../../store/Modals/facilityModals/facilityModalsStore';
import { FacilityModalsActionTypes } from '../../store/Modals/types';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';

export const FacilityPage = (props: { children: React.ReactNode }) => {
  const { dispatchFacilityModals } = useFacilityModalsStore();

  const { systemId } = useParams();

  const logPrefix = getLogPrefixForType('ROUTE', `Facility Page: ${window.location.pathname}`);
  const { stateFacilityLevel } = useFacilityLevelStore();
  useLoadFacilityLevelData(logPrefix);

  useEffect(() => {
    dispatchFacilityModals({ type: FacilityModalsActionTypes.RESET });
  }, [dispatchFacilityModals]);

  const { isLoading: isLoadingSlots } = useSlots(systemId || '');

  /**
   * Only render the page when we have data about the facility available.
   */
  const shouldRenderPage =
    stateFacilityLevel.isFacilityDataInitiated && systemId && !isLoadingSlots;

  if (shouldRenderPage) {
    return (
      <>
        <Box sx={{ display: 'flex' }}>
          <FacilityMenu systemId={systemId} />

          <Box component="main" sx={{ width: '100%', flex: '1 1 auto', minWidth: 0 }}>
            {props.children}
          </Box>
        </Box>
        <Footer />
      </>
    );
  }
  return <Spinner label="loading facility data ..." />;
};
