// NOTE:
// Prop 'disableBackdropClick' in MUI 5 is removed from 'Divider' and added in 'onClose' function.
// For more info look at "https://mui.com/guides/migration-v4/#dialog"
// We kept the 'disableBackdropClick' prop from the parent component. If this prop has value 'true', the modal can not be closed by clicking outside the main modal card.

import React from 'react';

import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

import { makeStyles } from 'tss-react/mui';

import ClearIcon from '@mui/icons-material/Clear';
import { Breakpoint, Theme, Tooltip } from '@mui/material';

import { Box } from 'components/common/Box';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    minWidth: '96%',
    margin: theme.spacing(3),
    backgroundColor: 'transparent',
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.offWhite.main,
      borderRadius: theme.spacing(1),
    },
  },
  title: {
    textAlign: 'left',
    padding: theme.spacing(0, 0, 0, 0),
  },
  buttons: {
    margin: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface IModalBaseProps {
  testId: string;
  /**
   * Flag indicating whether the dialog is open
   */
  opened: boolean;
  /**
   * Call-back invoked when the dialog is closed
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClose?: (param?: any) => void;
  /**
   * Title of the dialog
   */
  title: React.ReactNode;
  /**
   * Content of the dialog
   */
  children: React.ReactNode;
  actionButtons?: React.ReactNode;
  /**
   * Determine the max-width of the dialog. The dialog width grows with the size of the screen.
   * Set to false to disable maxWidth.
   */
  maxWidth?: false | Breakpoint;
  contentClass?: string;
  /**
   * Disable closing the modal by clicking outside the Modal
   */
  disableBackdropClick?: boolean;
  /**
   * Flag indicating whether the Modal is shown in full screen
   */
  fullScreen?: boolean;
  /**
   * Flag indicating whether the close button is shown
   */
  closeButton?: boolean;
  /**
   * if true the closeButton is shown in contrast mode
   */
  closeButtonContrast?: boolean;
  /**
   * Disable padding
   */
  disableGutters?: boolean;
  disableEscapeKeyDown?: boolean;
}

export const ModalBase = (props: IModalBaseProps) => {
  const {
    testId,
    opened,
    handleClose,
    title,
    children,
    actionButtons,
    maxWidth,
    contentClass,
    disableBackdropClick = false,
    disableGutters,
    fullScreen,
    closeButtonContrast,
    closeButton = true,
    disableEscapeKeyDown,
  } = props;

  const { classes } = useStyles();

  const backdropClick = disableBackdropClick ? 'backdropClick' : '';

  return (
    <Dialog
      data-testid={testId}
      className={classes.root}
      open={opened}
      onClose={(event, reason) => {
        if (reason !== backdropClick && !!handleClose) {
          handleClose(event);
        }
      }}
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullScreen={fullScreen}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        {title}

        {closeButton && handleClose && (
          <Box bgcolor="white">
            <Tooltip title="Click to close the dialog">
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                sx={
                  closeButtonContrast
                    ? {
                        color: '#fff !important',
                        backgroundColor: 'offWhite.main',
                      }
                    : {
                        backgroundColor: 'offWhite.main',
                      }
                }
                onClick={handleClose}
                size="medium"
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{ padding: disableGutters ? '20px 0px' : '20px 40px' }}
        className={contentClass}
      >
        {children}
      </DialogContent>
      {actionButtons ? (
        <DialogActions className={classes.buttons}>{actionButtons}</DialogActions>
      ) : null}
    </Dialog>
  );
};
