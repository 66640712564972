import createTheme from '@mui/material/styles/createTheme';

const colorTokens = {
  branding: {
    primary: '#645AD2',
    primaryAccent: '#383A69',
  },
};

export const customTheme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiPaper-elevation2': {
            borderRadius: '16px',
            boxShadow: '0px 0px 4px -2px rgba(0,0,0,0.05), 0px 2px 9px -2px rgba(0,0,0,0.2)',
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',

          '&.MuiButtonBase-root.MuiButton-sizeSmall': {
            paddingBlock: '4px',
          },

          '&.MuiButtonBase-root.MuiButton-sizeMedium': {
            paddingBlock: '10px',
          },

          '&.MuiButtonBase-root.MuiButton-sizeLarge': {
            paddingBlock: '16px',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          color: 'inherit',
          border: '1px solid transparent',

          '&.Mui-selected': {
            backgroundColor: `${colorTokens.branding.primary}33 !important`, // TEMPORARILY manually adjusting the transparency by extending the HEX value. Please do not mimic this.
            transition: 'background-color 220ms, border-color 220ms',
          },
          '&.Mui-selected .MuiTableCell-root': {
            transition: 'background-color 220ms, border-color 220ms',
            borderBottomColor: `${colorTokens.branding.primary}77 !important`, // TEMPORARILY manually adjusting the transparency by extending the HEX value. Please do not mimic this.
          },
          '&.Mui-selected:hover': {
            backgroundColor: `${colorTokens.branding.primary}66 !important`, // TEMPORARILY manually adjusting the transparency by extending the HEX value. Please do not mimic this.
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
  },
  palette: {
    background: {
      default: '#eeeeee',
      paper: '#ffffff',
    },
    content: {
      primary: '#ffffff',
      secondary: '#dfdfdf',
    },
    primary: {
      light: `${colorTokens.branding.primary}33`, // TEMPORARILY manually adjusting the transparency by extending the HEX value. Please do not mimic this.
      main: colorTokens.branding.primary,
      dark: colorTokens.branding.primaryAccent,
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#FBFBFB',
      main: '#333333',
      dark: '#222222',
      contrastText: '#FBFBFB',
    },
    accent: {
      light: '#FBFBFB',
      main: '#3C3C3C',
      dark: '#3C3C3C',
      hover: '#0075fe2e',
      selected: '#C3DBFF',
      contrastText: '#FBFBFB',
    },
    textPrimary: {
      light: '#FBFBFB',
      main: '#333333',
      dark: '#333333',
      contrastText: '#FBFBFB',
    },
    textSecondary: {
      light: '#FBFBFB',
      main: '#3C3C3C',
      dark: '#3C3C3C',
      contrastText: '#FBFBFB',
    },
    success: {
      main: '#0F9D58',
      contrastText: '#FBFBFB',
    },
    error: {
      main: '#D73F45',
      contrastText: '#FBFBFB',
    },
    warning: {
      main: '#E3984C',
      contrastText: '#FBFBFB',
    },
    disabled: '#a4a4a4',
    iconDisabled: '#0000008a',
    shades: {
      white: '#FFFFFF',
      lightest: '#EBEBEB',
      lighter: '#D7D7D7',
      light: '#C1C1C1',
      dark: '#a4a4a4',
      darker: '#565656',
      darkest: '#3C3C3C',
      black: '#000000',
    },
    offWhite: {
      main: '#F8F6F6',
    },
  },
});
declare module '@mui/material/styles' {
  interface Palette {
    content: {
      primary: string;
      secondary: string;
    };
    accent: {
      main: string;
      light: string;
      dark: string;
      hover: string;
      selected: string;
      contrastText: string;
    };
    textPrimary: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
    textSecondary: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
    disabled: string;
    iconDisabled: string;
    shades: {
      white: string;
      lightest: string;
      lighter: string;
      light: string;
      dark: string;
      darker: string;
      darkest: string;
      black: string;
    };
    offWhite: {
      main: string;
    };
  }
  interface PaletteOptions {
    content: {
      primary: string;
      secondary: string;
    };
    accent: {
      main: string;
      light: string;
      dark: string;
      hover: string;
      selected: string;
      contrastText: string;
    };
    textPrimary: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
    textSecondary: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
    disabled: string;
    iconDisabled: string;
    offWhite: {
      main: string;
    };
    shades: {
      white: string;
      lightest: string;
      lighter: string;
      light: string;
      dark: string;
      darker: string;
      darkest: string;
      black: string;
    };
  }
}
