import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';
import { ILocationDataST } from 'codegen/report';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

export const getSelectedLocationRows = (
  selectedLocationName: string,
  filteredLocationDataList: ILocationDataST[],
  locationDataList?: ILocationDataST[],
): ILocationData[] => {
  const selectedLocation = locationDataList?.find(
    (location) => location.slot_label === selectedLocationName,
  );

  return selectedLocation
    ? filteredLocationDataList.map((location) => ({
        ...getRowForFullReportTable(location.slot_label, location, location?.issues).actions.data,
        ...(location.slot_label === selectedLocation.slot_label && { selected: true }),
      }))
    : [];
};
