import { Modal } from '@mui/material';
import { Box } from 'components/common/Box';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { fullSizeImageModalStyles } from './fullsizeImageModal.styles';

export const FullSizeImageModal = ({
  imageUrls,
  isModalOpen,
  selectedImageIndex,
  handleCloseModal,
}: {
  imageUrls: string[];
  isModalOpen: boolean;
  selectedImageIndex: number;
  handleCloseModal: () => void;
}) => {
  const { classes } = fullSizeImageModalStyles();

  return (
    <Modal open={isModalOpen} onClose={handleCloseModal} className={classes.modal}>
      <Box className={classes.modalContainer} data-testid="modal-container">
        <TransformWrapper>
          <TransformComponent>
            <img
              src={imageUrls[selectedImageIndex]}
              alt={`fullsize - ${selectedImageIndex}`}
              style={{
                width: '100%',
                maxHeight: '90vh',
                objectFit: 'contain',
              }}
            />
          </TransformComponent>
        </TransformWrapper>
        <div className={classes.dotsContainer}>
          {imageUrls.map((imageUrl, index) => (
            <div
              key={imageUrl}
              className={`${classes.dot} ${selectedImageIndex === index ? classes.activeDot : ''}`}
              data-testid="dot"
            />
          ))}
        </div>
      </Box>
    </Modal>
  );
};
