import { IReportST } from 'codegen/report';
import { LocationReportData } from 'udb/inventory/models/LocationReportData';
import { ReportSummaryData } from 'udb/inventory/features/reports/reducer/report-store/ReportStore.model';
import { IssueSummary } from '../features/report-overview/issue-summary-card/IssueSummaryCard';

export type ReportContainerState = {
  fullReportSpinner: number;
  tableSpinner: boolean;
  issuesSpinner: number;
  summarySpinner: number;
  spinner: number;
  issues: LocationReportData[];
  amended: LocationReportData[];
  snoozed: LocationReportData[];
  potential: LocationReportData[];
  inconclusive: LocationReportData[];
  invalid: LocationReportData[];
  fullReport: LocationReportData[];
  pageSubtitle: string;

  /**
   * The state variable "displayRefreshOption" is used to mitigate a backend limitation
   * in some cases reports that no longer need review will still have a num_of_locations_to_review
   * in their summary. This happens because the num_locations_to review is not updated
   * immediately. In those cases, we display a refresh button instead of the submit review button.
   */
  displayRefreshOption: boolean;
  summaryLoaded: boolean;
  reportLoaded: boolean;
  issuesSummary: IssueSummary;
  reportLocationData: ReportSummaryData;
  reportExecutionData: ReportSummaryData;
  reportState: string;
  reportName: string;
  reportSpecName: string;
  locationsToReview: LocationReportData[];
  reportNeedsReview: boolean;
  reportNumLocations?: number;
  rawReportData: IReportST;
  isDownloading: boolean;
  requestsToSend: number;
  repliesReceived: number;
  allRequestsFinished: boolean;
};

export const reportContainerInitialState: ReportContainerState = {
  fullReportSpinner: 0,
  tableSpinner: false,
  issuesSpinner: 0,
  summarySpinner: 0,
  spinner: 0,
  issues: [],
  amended: [],
  snoozed: [],
  potential: [],
  inconclusive: [],
  invalid: [],
  fullReport: [],
  pageSubtitle: '',
  displayRefreshOption: false,
  summaryLoaded: false,
  reportLoaded: false,
  issuesSummary: {} as IssueSummary,
  reportLocationData: {} as ReportSummaryData,
  reportExecutionData: {} as ReportSummaryData,
  reportState: '',
  reportName: '',
  reportSpecName: '',
  locationsToReview: [],
  reportNeedsReview: false,
  rawReportData: {} as IReportST,
  isDownloading: false,
  requestsToSend: 0,
  repliesReceived: 0,
  allRequestsFinished: false,
};
