import globalAxios from 'axios';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { LocalStore } from 'common/functions/storageFunctions';
import { AUTH_PAGES_URLS } from 'common/pages';
import { TokenManager } from 'common/tokenManager';

export const tokenManager = TokenManager.getInstance();

/**
 * Refresh token function invoked with default globalAxios to prevent
 * infinite loop using
 * @returns promise
 */
export const refreshToken = (interceptedRequestUrl?: string) => {
  const lp = getLogPrefixForType('FUNCTION', 'refreshToken');
  const idToken = tokenManager.getIdToken();
  const refresh_token = tokenManager.getRefreshToken();
  const username = tokenManager.hashedUsernameFromAccessToken();

  console.debug(lp, 'refresh token request invoked on request:', {
    url: interceptedRequestUrl,
    route: window.location.pathname,
  });

  if (!username || !refresh_token) {
    console.trace(lp, 'username or refresh_token are undefined', {
      username,
      refresh_token,
      route: window.location.pathname,
    });
    window.location.pathname = AUTH_PAGES_URLS.SIGNOUT;
    return null;
  }

  return globalAxios
    .post(
      'refresh-token',
      { username, refresh_token },
      {
        headers: { Authorization: idToken },
        baseURL: `${import.meta.env.VITE_APP_API_ENDPOINT}/api/1.0/`,
      },
    )
    .then((r) => {
      const refresh_token = tokenManager.getRefreshToken();
      if (r.data.access_token && refresh_token) {
        tokenManager.setNewAccessAndIdToken(r.data);
      }
      console.debug(lp, 'refresh token request finished');
      return r;
    })
    .catch(() => {
      LocalStore.removeAuthData();
    });
};
