import LinearProgress from '@mui/material/LinearProgress';
import { UseAlertProps } from 'hooks/useAlert';
import { AlertComponent } from './Alert';

export type IUploadInfoStateParam = UseAlertProps;

export const UploadInfo = ({
  state,
  progress,
  closeAlert,
  testId,
}: {
  state: IUploadInfoStateParam | null;
  progress: number;
  closeAlert: () => void;
  testId: string;
}) => (
  <>
    {progress > 0 && progress < 100 && <LinearProgress variant="determinate" value={progress} />}

    {state && (
      <AlertComponent
        testId={testId}
        handleClose={closeAlert}
        title={state.title}
        message={state.message}
        status={state.status}
        isActive={state.show}
        percentage={progress}
        preventClose={state.preventClose}
      />
    )}
  </>
);
