import { formatDisplayValue } from 'components/DroneZoneForm/utils/formatDisplayValue';
import { useMeasurementSystem } from 'udb/ground-control/hooks/useMeasurementSystem';
import { useStyles } from './ZoneSideViewBaseZone.styles';
import { DroneZones } from '../../../../../../model/drone-zones.model';

export const ZoneSideViewBaseZone = ({
  classNames,
  zone,
  areaHeight,
  facilityHeight,
  isDisabled,
  onClick,
}: {
  classNames: string;
  zone: DroneZones;
  areaHeight: number;
  facilityHeight: number;
  isDisabled: boolean;
  onClick: (zone: DroneZones) => void;
}) => {
  const { classes, cx } = useStyles();
  const { isMetricSystem } = useMeasurementSystem();

  const bottomPosition = `${(zone.sizeAndPosition.minZ / facilityHeight) * 100}%`;
  const topPosition = `${100 - (zone.sizeAndPosition.maxZ / facilityHeight) * 100}%`;

  const areaHeightLabel = formatDisplayValue(areaHeight, { isMetricSystem });

  const handleOnClick = () => {
    onClick(zone);
  };

  return (
    <button
      type="button"
      disabled={isDisabled}
      className={cx(classNames, classes.zone)}
      style={{
        insetBlockStart: topPosition,
        insetBlockEnd: bottomPosition,
      }}
      onClick={handleOnClick}
    >
      <span className={classes.zoneDetailsName}>
        {`${zone.name} (${formatDisplayValue(zone.sizeAndPosition.minZ, {
          isMetricSystem,
          isUnitHidden: true,
        })} to ${formatDisplayValue(zone.sizeAndPosition.maxZ, {
          isMetricSystem,
        })})`}
      </span>

      <span className={classes.zoneDetailsHeight}>{`H: ${areaHeightLabel}`}</span>
    </button>
  );
};
