import { GridColDef } from '@mui/x-data-grid-premium';
import {
  ISSUE_STATE,
  IssueCell,
  IssueStateCell,
} from 'udb/inventory/features/premium-grid/features/cell';
import { AllIssueTypes, getIssueType } from 'common/functions/issues/issueColorFunctions';
import { ItemData } from './ItemView.model';

export const getItemViewColumns = (): GridColDef<ItemData>[] => [
  {
    headerName: 'Barcode',
    field: 'barcode',
  },
  {
    headerName: 'Status',
    field: 'barcodeStatus',
  },
  {
    headerName: 'WMS Location',
    field: 'expected_at',
  },
  {
    headerName: 'Verity Location',
    field: 'found_at',
  },
  {
    headerName: 'Issue',
    field: 'issue_type',
    minWidth: 180,
    align: 'left',
    valueGetter: (value) => (value ? getIssueType(value).label : null),
    renderCell: (params) => <IssueCell issueType={(params.value as AllIssueTypes) || '-'} />,
  },
  {
    headerName: 'Issue status',
    field: 'issues.0.state',
    minWidth: 90,
    type: 'singleSelect',
    align: 'left',
    valueGetter: (value) => value || 'NONE',
    renderCell: (params) =>
      params.value ? <IssueStateCell state={params.value as ISSUE_STATE} /> : '',
  },
  {
    headerName: 'WMS Date',
    field: 'wms_date',
    type: 'date',
  },
  {
    headerName: 'Query Date',
    field: 'query_date',
    type: 'date',
  },
  {
    headerName: 'Found Date',
    field: 'collected_on',
    type: 'date',
  },
  {
    headerName: 'customer',
    field: 'customer',
  },
  {
    headerName: 'WMS Article Nr.',
    field: 'article_nos',
  },
  {
    headerName: 'WMS Qty',
    field: 'article_qty',
    type: 'number',
  },
];
