import { IIssueSTStateEnum, IIssueSTTypeEnum } from 'codegen/report';
import { BarcodeStatusEnum } from 'components/ModalsAndPopups/LocationModal/features/barcodes/models/BarcodeRow.model';

export type ItemData = {
  id: string;
  barcodeStatus: Exclude<BarcodeStatusEnum, BarcodeStatusEnum.None>;
  barcode: string;
  expected_at: string;
  found_at: string;
  wms_date: Date;
  query_date: Date;
  collected_on: Date;
  customer: string;
  article_nos: string;
  article_qty: number;
  issue_type: IIssueSTTypeEnum | null;
  issue_state: IIssueSTStateEnum | null;
};

/**
 * Size of the segment of barcodes which are been shown at once
 * the value is out of a trial and error: it seems that somewhere
 * over 400k DataGrid Premium crashes => 300k should then give some
 * safety cushion,
 * eno 2024-11-16
 */
export const BARCODE_VIEW_SEGMENT_SIZE = 300 * 1000;
