import { pieArcLabelClasses, PieChart } from '@mui/x-charts-pro';
import { WarehouseStatsDataSerie } from '../../WarehouseStats.model';

export const WarehouseStatsPie = ({
  data,
  colors,
}: {
  data: WarehouseStatsDataSerie[];
  colors: string[];
}) => {
  const valueFormatter = (value: { id?: string | number }) => {
    const { id } = value;
    const d = data.filter((d) => d.id === id)[0];
    return d ? `${d.description} (${d.value})` : '';
  };

  return (
    <PieChart
      skipAnimation={true}
      series={[
        {
          data,
          paddingAngle: 0,
          outerRadius: 75,
          innerRadius: 35,
          arcLabel: 'value',
          cx: 70,
          arcLabelMinAngle: 20,
          valueFormatter,
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'white',
          fontSize: 11,
          fontWeight: 700,
          textShadow: '0px 0px 2px rgba(0,0,0,0.5)',
        },
      }}
      colors={colors}
      width={150}
      height={150}
      slotProps={{
        legend: { hidden: true },
      }}
    />
  );
};
