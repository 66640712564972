import { useMeasurementSystem } from 'udb/ground-control/hooks/useMeasurementSystem';
import { DroneZones } from '../../../../model/drone-zones.model';
import { useStyles } from './zone-side-view.styles';
import { getHeightRangeValues } from './utils/getHeightRangeValues';
import { DroneZone } from '../../../../model/drone-zone.model';
import { ZoneSideViewType } from './features/zone-side-view-type/ZoneSideViewType';

export const ZoneSideView = ({
  zones,
  selectedZone,
  facilityHeight,
  onSelect,
}: {
  zones: DroneZones[];
  selectedZone: DroneZone | null;
  facilityHeight: number;
  onSelect: (zone: DroneZones) => void;
}) => {
  const { classes } = useStyles();
  const { isMetricSystem } = useMeasurementSystem();

  const areaHeight =
    (selectedZone?.sizeAndPosition.maxZ ?? 0) - (selectedZone?.sizeAndPosition.minZ ?? 0);

  const sortedLegendRange = getHeightRangeValues({
    min: 0,
    zones,
    max: facilityHeight,
    isMetricSystem,
  });
  const sortedZones = zones.sort(
    (zoneA, zoneB) => zoneB.sizeAndPosition.maxZ - zoneA.sizeAndPosition.maxZ,
  );

  const isSelectionDisabled = sortedZones.length === 1;

  return (
    <div className={classes.areaGraph}>
      <div className={classes.areaGraphLegend}>
        {sortedLegendRange.map((legend) => (
          <span
            key={legend.id}
            className={classes.areaGraphLegendItem}
            style={{ insetBlockEnd: legend.position }}
          >
            {legend.value}
          </span>
        ))}
      </div>

      <div className={classes.areaGraphData}>
        {sortedZones.map((zone) => (
          <ZoneSideViewType
            key={zone.id}
            zone={zone}
            facilityHeight={facilityHeight}
            areaHeight={areaHeight}
            isSelected={selectedZone?.id === zone.id}
            isDisabled={isSelectionDisabled}
            onClick={onSelect}
          />
        ))}
      </div>
    </div>
  );
};
