import { DEFAULT_DECIMAL_PLACES } from 'components/FormFields/defaults/FormInput.defaults';

export const metricFormatter = (
  value: number,
  options?: { isUnitHidden?: boolean; decimalPlaces?: number },
) =>
  new Intl.NumberFormat('en-US', {
    style: options?.isUnitHidden ? 'decimal' : 'unit',
    unit: options?.isUnitHidden ? undefined : 'meter',
    minimumFractionDigits: options?.decimalPlaces ?? DEFAULT_DECIMAL_PLACES,
    maximumFractionDigits: options?.decimalPlaces ?? DEFAULT_DECIMAL_PLACES,
    useGrouping: false,
  }).format(value);
