export const getLabel = ({
  baseLabel,
  formatter,
  max,
  min,
}: {
  baseLabel: string;
  formatter: (value: number) => string;
  max?: number;
  min?: number;
}) => {
  let fieldLabel = baseLabel;

  if (max) {
    fieldLabel += ` - max: ${formatter(max)}`;
  }

  if (min) {
    fieldLabel += ` - min: ${formatter(min)}`;
  }

  return fieldLabel;
};
