import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useSearchParams, useParams } from 'react-router-dom';

import Container from '@mui/material/Container';

import { getLogPrefixForType } from 'common/functions/logFunctions';
import { getTimeIntervalDates } from 'common/functions/dateTimeFunctions';
import { TIMEFRAME_TO_FETCH_REPORTS_FROM } from 'common/settings';

import { Box } from 'components/common/Box';
import { PageHeaderSection } from 'components/Page/PageHeaderSection';
import { useRequestController } from 'hooks';
import { InventoryActionNames } from 'store/FacilityLevelStore/facilityLevelActions';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { useFacilityModalsStore } from 'store/Modals';
import { FacilityModalsActionTypes } from 'store/Modals/types';
import { IInventoryRequestInternal } from 'interfaces';
import { useGetTabData } from './utils/get-tab-data/getTabData';
import { REPORTS, currentTabDefault, pageHeaderSubtitle } from './utils/get-tab-data/helpers';
import { generateRowData } from './reducer/report-store/utils/generateRowData';
import {
  getReportsAndInventory,
  isLastRecurrenceOfReport,
  isReportAutoExpired,
  isReportNonRecurring,
  isScheduledReportStarted,
} from './reportsFunctions';
import { LocationProps, Dates, Report, LoadSince } from './Reports.model';
import { ReportsGrid } from './features/reports-grid/ReportsGrid';
import { ReportScheduleButton } from './features/schedule-button/ReportScheduleButton';

const logPrefix = getLogPrefixForType('PAGE', 'Reports');
export const Reports = () => {
  const { stateFacilityLevel, dispatchFacilityLevel } = useFacilityLevelStore();
  const { dispatchFacilityModals } = useFacilityModalsStore();
  const [searchParams] = useSearchParams();

  const location = useLocation() as LocationProps;
  const [activeTab, setActiveTab] = useState<number>(Number(searchParams.get('activeTab')) || 0);
  const { systemId = '' } = useParams();

  const [spinnerFinishedReports, setSpinnerFinishedReports] = useState(0);
  const [spinnerOngoingAndToReviewReports, setSpinnerOngoingAndToReviewReports] = useState(0);
  const [spinnerScheduledReports, setSpinnerScheduledReports] = useState(0);
  const [spinnerArchivedReports, setSpinnerArchivedReports] = useState(0);

  const [reportsFinished, setReportsFinished] = useState<Report[]>([]);
  const [reportsOngoing, setReportsOngoing] = useState<Report[]>([]);
  const [reportsScheduled, setReportsScheduled] = useState<Report[]>([]);
  const [reportsToReview, setReportsToReview] = useState<Report[]>([]);
  const [reportsArchived, setReportsArchived] = useState<Report[]>([]);

  const loadSince = searchParams.get('loadSince') as LoadSince;

  const refDates = useRef(
    location.state?.loadSince || getTimeIntervalDates(loadSince || TIMEFRAME_TO_FETCH_REPORTS_FROM),
  );

  const currentActiveSpinner = {
    [REPORTS.FINISHED.TABLE_FOR]: !!spinnerFinishedReports,
    [REPORTS.ONGOING.TABLE_FOR]: !!spinnerOngoingAndToReviewReports,
    [REPORTS.SCHEDULED.TABLE_FOR]: !!spinnerScheduledReports,
    [REPORTS.REVIEW.TABLE_FOR]: !!spinnerOngoingAndToReviewReports,
    [REPORTS.ARCHIVED.TABLE_FOR]: !!spinnerArchivedReports,
  };

  const { requestController } = useRequestController(logPrefix);

  /**
   * Get reports and inventory requests
   * @param dates Dates interface
   * @param shouldLoad if this param is true we will load reports for all tabs otherwise just for tabs with range filter
   */
  const getReportsAndInventoryRequests = useCallback(
    ({ newDates, shouldLoad }: { newDates?: Dates; shouldLoad: boolean }) =>
      getReportsAndInventory({
        dates: newDates || refDates.current,
        shouldLoad,
        setReportsFinished,
        setReportsOngoing,
        setReportsToReview,
        setReportsScheduled,
        setReportsArchived,
        setSpinnerFinishedReports,
        requestController,
        systemId,
        setSpinnerOngoingAndToReviewReports,
        setSpinnerScheduledReports,
        setSpinnerArchivedReports,
      }),
    [requestController, systemId],
  );

  /**
   * Get data from dates
   * @param dates Dates interface
   */
  const getDataFrom = (dates: Dates) => {
    requestController.doCancelRequest(
      `${logPrefix}interval changed dates to from: ${dates.from} until: ${dates.until}`,
    );
    console.debug(logPrefix, 'Reports selection dates changed: ', dates);
    refDates.current = dates;
    getReportsAndInventoryRequests({ newDates: dates, shouldLoad: false });
  };

  /**
   * Update the report progress with progress that is received from the WebSocket
   */
  const updateReportProgress = useCallback(() => {
    const reportId = stateFacilityLevel.inventory.reportUpdate?.data?.report_id;
    const status = stateFacilityLevel.inventory.reportUpdate?.data?.report_state;
    const reportProgress = stateFacilityLevel.inventory.reportUpdate?.data?.progress;

    setReportsOngoing((ongoingReports) => {
      const copyReports = [...ongoingReports];
      const report: Report = copyReports[copyReports.findIndex((r: Report) => r.id === reportId)];

      if (report) {
        report.progress = reportProgress;
        report.status = status;

        return [...copyReports];
      }
      return ongoingReports;
    });
  }, [stateFacilityLevel.inventory.reportUpdate]);

  /**
   * Handle updating reports list on receiving WS message
   * TODO: Update function to include
   *
   *  - Moving report for review, from ONGOING to REVIEW tab
   *  - Moving finished report to finished tab
   */
  const handleReportsTableUpdate = useCallback(() => {
    const receivedReport = stateFacilityLevel.inventory?.reportUpdate?.data?.fullResponse;

    if (!receivedReport) return;
    const requestId = receivedReport.request_id;

    // We want to filter out the scheduled report from the list if:
    // - It is an expired Automatic report
    // - It is recurring report with last recurrence
    // - It is non-recurring report

    // FIXME: as reportsScheduled is Report[] and the Report type does not contain
    // the property requestExpiresAt it might well be that isExpiredAutoReport and
    // isLastRecurringReport are always false (for them be true is predicated on
    // isLastRecurringReport being in the past)
    // BUT: more probably the type is not Report to begin with
    // eno: 2024-04-28
    const scheduledReport = [...reportsScheduled].find(
      (report) => (report as unknown as { requestId: string }).requestId === requestId,
    ) as unknown as IInventoryRequestInternal;

    const isExpiredAutoReport = isReportAutoExpired(scheduledReport);
    const isLastRecurringReport = isLastRecurrenceOfReport(scheduledReport);
    const isNonRecurringReport = isReportNonRecurring(scheduledReport);

    if (isExpiredAutoReport || isLastRecurringReport || isNonRecurringReport) {
      // filter out scheduled report
      const scheduledReports = [...reportsScheduled].filter(
        (report) => (report as unknown as { requestId: string }).requestId !== requestId,
      );
      setReportsScheduled(scheduledReports);
    }

    const reportToUpdate = generateRowData(receivedReport);

    if (isScheduledReportStarted(reportToUpdate)) {
      // If incoming report has SCHEDULED status, and progress of 0, it should be moved into
      // ONGOING tab
      setReportsOngoing((reports: Report[]) => [reportToUpdate, ...reports]);
    }

    dispatchFacilityLevel({
      type: InventoryActionNames.WS_REPORT_STATUS_UPDATE,
      payload: {},
    });
  }, [
    dispatchFacilityLevel,
    reportsScheduled,
    stateFacilityLevel.inventory?.reportUpdate?.data?.fullResponse,
  ]);

  /**
   * This function is used for reports bulk operations (ARCHIVE/RESTORE)
   */
  const bulkHandleReports = useCallback(
    (reportIds: string[], actionType: FacilityModalsActionTypes) =>
      dispatchFacilityModals({
        type: actionType,
        payload: { reportIds, reportName: null },
        refreshData: {
          refreshData: () => getReportsAndInventoryRequests({ shouldLoad: true }),
        },
      }),
    [getReportsAndInventoryRequests, dispatchFacilityModals],
  );

  /**
   * NOTE: this takes care of the initial loading of the reports list.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
    getReportsAndInventoryRequests({ shouldLoad: true });
  }, [getReportsAndInventoryRequests]);

  useEffect(() => {
    updateReportProgress();
  }, [updateReportProgress]);

  useEffect(() => {
    handleReportsTableUpdate();
  }, [handleReportsTableUpdate]);

  const allTabData = useGetTabData({
    bulkHandleReports,
    reportsToReview,
    reportsFinished,
    reportsOngoing,
    reportsScheduled,
    reportsArchived,
  });

  const currentTabData = allTabData[activeTab] ? allTabData[activeTab] : currentTabDefault;

  const showLoadedSince = [REPORTS.FINISHED.TABLE_FOR, REPORTS.ARCHIVED.TABLE_FOR].includes(
    currentTabData.tableFor,
  );

  return (
    <>
      <PageHeaderSection
        title="Reports"
        subtitle={pageHeaderSubtitle[currentTabData.tableFor]}
        showLoadedSince={showLoadedSince}
        defaultTimeInterval={TIMEFRAME_TO_FETCH_REPORTS_FROM}
        getDataFrom={getDataFrom}
        customBtn={<ReportScheduleButton systemId={systemId} />}
      />

      <Container maxWidth="xl" sx={{ paddingTop: '32px' }}>
        <Box className="c-page-content">
          <ReportsGrid
            systemId={systemId}
            allTabData={allTabData}
            isLoading={currentActiveSpinner[currentTabData.tableFor]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            currentActiveSpinner={currentActiveSpinner}
            refreshData={{
              refreshData: () => getReportsAndInventoryRequests({ shouldLoad: true }),
            }}
          />
        </Box>
      </Container>
    </>
  );
};
