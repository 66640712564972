import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';

import { getLogPrefixForType, removeAnsiEscapeSequences } from 'common/functions/logFunctions';
import { singleRequestHandler } from 'common/requestHelpers';
import { IRequestController } from './IRequestController';
import { IDoRequestHandlerData } from './IDoRequestHandlerData';
import { IRequest } from './IRequest';

/**
 * Hook that creates a Request controller. The cancellation can be triggered manually and,
 * in any case, is automatically triggered on unmount. The controller contains the name of the
 * caller component for logging purposes.
 * @deprecated please leverage useQuery instead
 * @param componentName name of the component making use of the hook.
 * @returns controller, and functions to check whether is cancelled and cancel it.
 */
export const useRequestController = (
  componentName: string,
): { requestController: IRequestController } => {
  const logPrefix = getLogPrefixForType('HOOK', 'useRequestController', componentName);

  const [isExecuting, setIsExecuting] = useState({});

  /**
   * component name stripped of all encoding, used for non-logging messaging
   */
  const pureComponentName = useMemo(
    () => removeAnsiEscapeSequences(componentName),
    [componentName],
  );

  const onExecutingChanged = useCallback(
    (payload: { loader: string; value: boolean }) =>
      setIsExecuting((prevState) => ({ ...prevState, [payload.loader]: payload.value })),
    [],
  );

  /**
   * Axios Abort Controller to be used for canceling pending network requests.
   */
  const controller = useRef(new AbortController());

  const requestsQueue: Array<IRequest> = useMemo(() => [], []);

  /**
   * Instance of Snackbar, used for showing notifications to the end user.
   */
  const { enqueueSnackbar } = useSnackbar();

  const isRequestCancelled = useCallback(
    /**
     * function that tells whether the cancellation for the given request has been invoked.
     * @param requestId ID of the request.
     * @returns true if the request has been cancelled.
     */
    (requestId: string) =>
      requestsQueue.find((r) => r.requestId === requestId)?.controller.signal.aborted || false,
    [requestsQueue],
  );

  const doTriggerCancellation = useCallback(
    /**
     * Function which triggers the cancellation.
     * @param requestId ID of the request to be cancelled (if none is passed,
     * then all requests will be cancelled).
     */
    (reason: string, requestId?: number) => {
      reason = removeAnsiEscapeSequences(reason);
      const topic = getLogPrefixForType('TOPIC', 'CANCEL', logPrefix);
      if (requestId) {
        const reqDesc = `request: ${requestId} => ${requestsQueue[requestId].requestName}`;
        if (!requestsQueue[requestId]) {
          console.warn(topic, `CANCEL invoked for the non-existing ${reqDesc}`);
          return;
        }
        if (requestsQueue[requestId].controller.signal.aborted) {
          console.warn(logPrefix, `CANCEL invoked for the already cancelled ${reqDesc}`);
          return;
        }
        const cancellationMessage = `CANCEL invoked for ${reqDesc}, message: ${reason}`;
        requestsQueue[requestId].controller.abort();
        console.debug(logPrefix, cancellationMessage);
      } else {
        console.debug(
          topic,
          `CANCEL invoked for all pending requests (up to id: ${
            requestsQueue.length - 1
          }), reason: ${reason}`,
        );
        // Cancel all requests:
        // Issue a cancellation for all requests which have not been cancelled yet
        // NOTE: multiple requests could share an Abort Controller (namely all requests using the common
        // Abort Controller associated with the request controller), in some cases a cancellation could
        // be issued more than once, this is however not an issue, all cancellation after the first
        // one will be ignored by axios.
        let cancelledRequests = 0;
        requestsQueue
          .filter((r) => !r.controller.signal.aborted)
          .forEach((r) => {
            const cancellationMessage = `CANCEL invoked for ${r.requestName}, reason: ${reason}`;
            r.controller.abort();
            console.debug(topic, cancellationMessage);
            cancelledRequests += 1;
          });
        console.debug(topic, `CANCELLED ${cancelledRequests} requests`);
        // Reset the main controller
        controller.current = new AbortController();
      }
    },
    [logPrefix, requestsQueue],
  );

  const reserveSlotForRequestInternal = useCallback(
    /**
     * Reserve a given slot for a request and return that request ID.
     * The internal version of the function let specify the Abort Controller.
     * @param abortController controller to be used for this request, if none
     * is passed then the generic Abort Controller is used.
     * @returns the reserve request ID and the Abort Controller associated with
     * the request.
     */
    (abortController?: AbortController, requestName?: string) => {
      // Get the index where the request will be set at in the request array
      const requestId: string = uuidv4(); // requestArray.length;
      const controller = abortController || new AbortController();
      // Set the slot for the next request with the corresponding Abort Controller
      requestsQueue.push({ controller, requestName: requestName || 'RESERVED', requestId });
      console.debug(
        logPrefix,
        `slot ${requestsQueue.length - 1} RESERVED`,
        requestsQueue[requestsQueue.length - 1],
      );
      return { requestId, signal: controller.signal };
    },
    [logPrefix, requestsQueue],
  );

  const reserveSlotForRequest = useCallback(
    /**
     * Reserves a slot for an upcoming request and returns ID and Abort Controller for the request.
     * @returns Request ID and Abort Signal for the request. As the Request ID will then be
     * usable to issue a cancellation for this request, and this one only, the Abort Signal will
     * also be specific for this request (that is, it is different than the one returned by
     * requestController.signal).
     */
    () => reserveSlotForRequestInternal(undefined, `RESERVED by ${pureComponentName}`),
    [pureComponentName, reserveSlotForRequestInternal],
  );

  const doRequest = useCallback(
    /**
     * Do a Request
     * @param handlerData Handler Data.
     * @param reservedRequestId ID reserved for the request (if any). If no parameter is passed
     * a Request ID will be generated automatically (but the caller will not have access to it).
     * @returns Promise which will handle the request.
     */
    (handlerData: IDoRequestHandlerData, reservedRequestId?: string) => {
      // Get the index where the current request will be set
      const requestId: string =
        reservedRequestId !== undefined
          ? reservedRequestId
          : reserveSlotForRequestInternal(controller.current, 'RESERVED by requestController')
              .requestId;

      const request = requestsQueue.find((r) => r.requestId === requestId) as IRequest;
      request.requestName = `${handlerData.request.name} invoked by ${pureComponentName}`;

      const requestControllerState = {
        isRequestCancelled,
        componentName: logPrefix,
      };

      Sentry.withScope((scope) => {
        scope.setExtra('request id', requestId);
      });
      return singleRequestHandler({
        ...handlerData,
        onExecutingStateChanged: onExecutingChanged,
        requestControllerState,
        dispatcher: enqueueSnackbar,
        requestId,
      });
    },
    [
      enqueueSnackbar,
      isRequestCancelled,
      onExecutingChanged,
      logPrefix,
      pureComponentName,
      requestsQueue,
      reserveSlotForRequestInternal,
    ],
  );

  useEffect(() => {
    console.debug(logPrefix, 'initialized');
    // NOTE: the cancellation is automatically triggered when the component is unmounted.
    return () => {
      doTriggerCancellation('CANCEL of all requests invoked by component unmount');
    };
  }, [componentName, doTriggerCancellation, logPrefix]);

  // Note: the return object needs to be memoized otherwise at every render the reference to "requestController"
  // gets updated and all hooks having it as a dependency (e.g. useEffect) get triggered at each render
  const { requestController } = useMemo(
    () => ({
      requestController: {
        isRequestCancelled,
        doCancelRequest: doTriggerCancellation,
        componentName,
        signal: controller.current.signal,
        doRequest,
        reserveSlotForRequest,
        isExecuting: {},
      },
    }),
    [isRequestCancelled, doTriggerCancellation, componentName, doRequest, reserveSlotForRequest],
  );
  requestController.isExecuting = isExecuting;
  return { requestController };
};
