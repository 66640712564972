import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    containerType: 'inline-size',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    rowGap: theme.spacing(2),
    paddingBlockStart: theme.spacing(4),
    paddingInlineStart: theme.spacing(3),
    paddingInlineEnd: theme.spacing(8),

    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: `0 minmax(100px, ${theme.breakpoints.values.xl}px) minmax(0, 1fr)`,
    },
  },
  section: {
    [theme.breakpoints.up('xl')]: {
      gridColumn: '2 / -2',
    },
  },
  sectionColumns: {
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoRows: 'min-content',
    gridTemplateColumns: 'minmax(0px, 1fr)',
    justifyContent: 'start',

    '@container (min-inline-size: 700px)': {
      gridTemplateColumns: 'repeat(auto-fit,  minmax(300px, 1fr))',
    },

    '@container (min-inline-size: 1300px)': {
      gridTemplateColumns: 'repeat(auto-fill, clamp(350px, calc(33.33% - 12px), 550px))',
    },
  },
  sectionFullWidth: {
    gridColumn: '1 / -1',
  },
}));
