import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { createDroneZoneObject } from 'delivery/features/drone-zones/features/drone-zone-controls/features/utils/createDroneZoneObject.util';
import { useGroundControlStore } from 'store/GroundControl/groundControlLevelStore';
import { DroneZoneForm } from 'components/DroneZoneForm/DroneZoneForm';
import { NoFlyZoneCreator } from '../NoFlyZoneCreator/NoFlyZoneCreator';
import { useRequestController } from '../../../../../hooks';
import { FlowState } from '../../reducer/zoneStatusInitialState';
import { DroneZonesList } from '../DroneZonesList/DroneZonesList';
import { DroneZoneTypes } from '../../model/droneZones.model';
import { DroneZoneControlsProps } from './DroneZoneControlsProps.model';
import { useDroneZoneContext } from '../../reducer/DroneZonePageContext';
import { createOrUpdateNoFlyZone } from './api/createOrUpdateNoFlyZone.api';

export const DroneZoneControls = ({
  systemId,
  flightDomainId,
  flowState,
  noFlyZones,
  controlledZones,
  worldBox,
  areActionsEnabled,
  currentlySelectedZone,
  currentlyEditingZone,
  onSelectDroneZone,
}: DroneZoneControlsProps) => {
  const { requestController } = useRequestController('NoFlyZones');
  const { dispatchDroneZone } = useDroneZoneContext();
  const { stateGroundControl } = useGroundControlStore();
  const { flightDomain } = stateGroundControl;

  const isLoading = flowState === FlowState.LOADING;
  const editedNewFlyZone =
    currentlyEditingZone ?? createDroneZoneObject({ type: DroneZoneTypes.noFlyZone });

  const handleUpdateNoFlyZone = (noFlyZone: NoFlyZone) =>
    dispatchDroneZone({
      type: 'UPDATE_NO_FLY_ZONE',
      payload: noFlyZone,
    });

  const handleCancelNoFlyZone = (noFlyZone: NoFlyZone) => {
    dispatchDroneZone({ type: 'CANCEL_EDITING_NO_FLY_ZONE', payload: noFlyZone });
  };

  const handleSubmitNoFlyZone = (noFlyZone: NoFlyZone) =>
    createOrUpdateNoFlyZone({
      flightDomainId,
      noFlyZone,
      onSuccess: () => dispatchDroneZone({ type: 'SAVE_NO_FLY_ZONE_CHANGES', payload: null }),
      requestController,
      systemId,
    });

  const handleNoFlyZoneCreationCancel = () =>
    dispatchDroneZone({ type: 'SET_FLOW_STATE', payload: FlowState.LIST });

  switch (flowState) {
    case FlowState.LOADING:
    case FlowState.LIST:
    case FlowState.DELETE:
      return (
        <DroneZonesList
          flightDomain={flightDomain}
          systemId={systemId}
          noFlyZones={noFlyZones}
          controlledZones={controlledZones}
          isLoading={isLoading}
          areActionsEnabled={areActionsEnabled}
          currentlySelectedZone={currentlySelectedZone}
          onSelect={onSelectDroneZone}
        />
      );

    case FlowState.DRAW:
      return <NoFlyZoneCreator onCancelCreateNFZ={handleNoFlyZoneCreationCancel} />;
    case FlowState.CREATE:
      return (
        <DroneZoneForm<NoFlyZone>
          droneZone={editedNewFlyZone}
          worldBox={worldBox}
          onUpdate={handleUpdateNoFlyZone}
          onCancel={handleCancelNoFlyZone}
          onSubmit={handleSubmitNoFlyZone}
        />
      );
    default:
      return null;
  }
};
