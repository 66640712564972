import { NodeST } from 'shared/map-container/MapContainer.model';

export const flattenNodes = (node: NodeST): NodeST[] => {
  let result: NodeST[] = [node];

  if (node.nodes) {
    node.nodes.forEach((childNode) => {
      result = result.concat(flattenNodes(childNode));
    });
  }

  return result;
};

function hasDescendantWithName(node: NodeST, name: string): boolean {
  if (node.name === name) {
    return true;
  }
  if (node.nodes) {
    return node.nodes.some((child) => hasDescendantWithName(child, name));
  }
  return false;
}

export function findNodesByChildName(
  root: NodeST,
  targetType: NodeST['type'],
  childName: string,
): NodeST | null {
  let result = null;

  function dfs(node: NodeST): void {
    if (node.type === targetType && hasDescendantWithName(node, childName)) {
      result = node;
    }
    if (node.nodes) {
      node.nodes.forEach(dfs);
    }
  }

  dfs(root);
  return result;
}

export function findNodesByType(root: NodeST, targetType: NodeST['type']): NodeST[] {
  const result: NodeST[] = [];

  function depthFirstSearch(node: NodeST): void {
    if (node.type === targetType) {
      result.push(node);
    }
    if (node.nodes) {
      node.nodes.forEach(depthFirstSearch);
    }
  }

  depthFirstSearch(root);
  return result;
}
