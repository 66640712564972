import { MeshProps, ThreeEvent } from '@react-three/fiber';
import { memo, useCallback, useMemo } from 'react';
import { Vector3 } from 'three';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import { MapState } from '../../reducer/3DmapState';
import { Zoomer } from '../../utils/Zoomer';
import { MutableState } from '../../reducer/MutableState';
import { mapStyle } from '../map-canvas/MapCanvas.style';

const FLOOR_PADDING = 300;

const logPrefix = getLogPrefixForType('COMPONENT', '3D Floor');

export const Floor = memo(
  ({
    mapState,
    zoomer,
    onClick,
    onFloorDown,
    onFloorUp,
    variant = 'primary',
    ...props
  }: MeshProps & {
    mapState: MapState;
    zoomer?: Zoomer;
    variant?: 'primary' | 'secondary';
    onClick?: () => void;
    onFloorDown?: () => void;
    onFloorUp?: () => void;
  }) => {
    const mutableState = MutableState.getState();
    const { map } = mapState;
    const { minX, minY, minZ, maxX, maxY } = mapFacilityVectors(map?.box);

    const floorWidth = maxX - minX;
    const floorLength = maxY - minY;
    const floorX = minX + floorWidth / 2;
    const floorY = minY + floorLength / 2;

    const [widthPadded, lengthPadded] = [floorWidth + FLOOR_PADDING, floorLength + FLOOR_PADDING];

    const floorPosition = new Vector3(floorX, floorY, minZ);
    const floorGeometry = useMemo(() => new Vector3(0, 0, 0.0001), []);

    const onHover = (e: ThreeEvent<PointerEvent>) => {
      mutableState.interaction.guidePosition.copy(e.point);
    };

    const onWheel = useCallback(
      (e: ThreeEvent<WheelEvent>) => {
        const delta = 'wheelDeltaY' in e ? (e.wheelDeltaY as number) : 0;
        console.debug(logPrefix, 'onWheel invoked with delta => ', delta);
        zoomer && zoomer.zoom(delta);
        return false;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [zoomer],
    );

    return (
      <mesh
        position={floorPosition}
        onPointerMove={onHover}
        onPointerDown={onFloorDown}
        onPointerUp={onFloorUp}
        onClick={onClick}
        onWheel={onWheel}
        {...props}
      >
        <planeGeometry args={[widthPadded, lengthPadded]} />

        <meshBasicMaterial color={mapStyle.floor.void.backgroundColor[variant]} />

        <mesh position={floorGeometry}>
          <planeGeometry args={[floorWidth, floorLength]} />

          <meshBasicMaterial color={mapStyle.floor.facility.backgroundColor} />
        </mesh>
      </mesh>
    );
  },
);
