import { TextField } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { isImperialValid } from './utils/isImperialValid';
import { FormInputImperialProps } from './formInputImperial.model';

export const FormInputImperial = ({
  name,
  placeholder = '',
  label = '',
  size = 'medium',
  onChange,
  ...rest
}: FormInputImperialProps) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: FieldProps['field'],
  ) => {
    const { value } = event.target;

    const isValidImperialInput = (value: string) => /^[0-9'´"¨-]*$/.test(value);
    if (!isValidImperialInput(value)) {
      return;
    }

    field.onChange(event);
    onChange?.(event);
  };

  return (
    <Field name={name} validate={isImperialValid}>
      {({ field, meta }: FieldProps) => (
        <TextField
          name={name}
          value={field.value}
          placeholder={placeholder}
          label={label}
          onChange={(event) => handleChange(event, field)}
          type="text"
          size={size}
          error={!!meta.touched && !!meta.error}
          helperText={meta.error}
          {...rest}
        />
      )}
    </Field>
  );
};
