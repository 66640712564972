import { DroneZoneTypes } from 'shared/map/model/drone-zones.model';

export const validateName = (
  value: string,
  zoneType: keyof typeof DroneZoneTypes,
): string | undefined => {
  if (value.trim().length) {
    return undefined;
  }

  return `A name is required to create a ${zoneType}.`;
};
