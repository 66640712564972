import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';
import { useMemo } from 'react';
import { ILocationDataST } from 'codegen/report';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { BarcodeStatusEnum } from '../../../components/ModalsAndPopups/LocationModal/features/barcodes/models/BarcodeRow.model';
import { transformBarcodeRows } from '../../../components/ModalsAndPopups/LocationModal/features/barcodes/utils/transformBarcodeRows.util';
import { LocationSummaryPopupDetail } from '../../../components/ModalsAndPopups/LocationModal/features/location-summary-popup/LocationSummaryPopup.model';

interface LocationDetailsArgs {
  issueLogic?: IRuleActionSTIssueLogicEnum;
  activeBinName?: string;
  locationDataMap: Map<string, ILocationDataST>;
}

export function useLocationDetails({
  issueLogic,
  activeBinName,
  locationDataMap,
}: LocationDetailsArgs) {
  return useMemo(() => {
    if (!activeBinName) {
      return [];
    }
    const location = locationDataMap.get(activeBinName);

    if (!location) {
      return [];
    }

    const selectedLocation = getRowForFullReportTable(
      location?.slot_label ?? '',
      location,
      location?.issues ?? [],
    );
    const barcodeRows = selectedLocation
      ? transformBarcodeRows(selectedLocation.actions.data, issueLogic)
      : null;
    const missing: LocationSummaryPopupDetail = {
      title: 'Missing',
      color: 'error.main',
      items:
        barcodeRows
          ?.filter((row) => row.barcodeStatus === BarcodeStatusEnum.Missing)
          .map((row) => row.expectedContent) ?? [],
    };
    const unexpected: LocationSummaryPopupDetail = {
      title: 'Unexpected',
      color: 'warning.main',
      items:
        barcodeRows
          ?.filter((row) => row.barcodeStatus === BarcodeStatusEnum.Unexpected)
          .map((row) => row.contentFound) ?? [],
    };

    return [missing, unexpected].filter((detail) => detail.items.length);
  }, [activeBinName, locationDataMap, issueLogic]);
}
