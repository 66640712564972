import { Chip, Typography } from '@mui/material';
import { Box } from 'components/common/Box';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';

export const LocationModalHelp = () => {
  const { facilitySettings } = useFacilityLevelStore().stateFacilityLevel;
  return (
    <Box
      textAlign="left"
      paddingTop="1em"
      paddingRight="2em"
      paddingLeft="2em"
      paddingBottom="2em"
      width="28em"
    >
      <Typography variant="h5">Keyboard shortcuts</Typography>
      <Typography variant="h6" mt="1em">
        Navigation
      </Typography>
      <Typography mt="1em">
        <Chip label="&larr; (left arrow)" variant="outlined" style={{ marginRight: '1em' }} />
        Go to the previous location
      </Typography>
      <Typography mt="1em">
        <Chip label="&rarr; (right arrow)" variant="outlined" style={{ marginRight: '1em' }} />
        Go to the next location
      </Typography>
      <Typography mt="1em">
        <Chip label="ESC" variant="outlined" style={{ marginRight: '1em' }} />
        Close the dialog
      </Typography>
      <Typography variant="h6" mt="1.5em">
        Images
      </Typography>
      <Typography mt="1em">
        <Chip label=". (period)" variant="outlined" style={{ marginRight: '1em' }} />
        Toggle full image
      </Typography>
      <Typography mt="1em">
        <Chip label="Alt + click" variant="outlined" style={{ marginRight: '1em' }} />
        Open full image
      </Typography>
      {facilitySettings.enable_barcode_highlighting && (
        <Typography mt="1em">
          <Chip label="B" variant="outlined" style={{ marginRight: '1em' }} />
          Highlight barcodes on images: on and off
        </Typography>
      )}
      <Typography mt="1em">
        <Chip label="&darr; (down arrow)" variant="outlined" style={{ marginRight: '1em' }} />
        Next image
      </Typography>
      <Typography mt="1em">
        <Chip label="&uarr; (up arrow)" variant="outlined" style={{ marginRight: '1em' }} />
        Previous image
      </Typography>
      <Typography variant="h6" mt="1.5em">
        Feedback
      </Typography>
      <Typography mt="1em">
        <Chip label="1-9 keys" variant="outlined" style={{ marginRight: '1em' }} />
        Toggle feedback categories
      </Typography>
      <Typography mt="1em">
        <Chip label="&#8626; (Enter)" variant="outlined" style={{ marginRight: '1em' }} />
        Submit feedback
      </Typography>
    </Box>
  );
};
