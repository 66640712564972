import { useMemo } from 'react';
import { IVeritySlotStatusST, IWMSSlotStatusST } from 'codegen/location_information';
import { matchBarcode } from 'common/functions/barcodes/matchBarcode.util';
import { useLocationData } from './useLocationData';
import { ItemData } from '../features/itemView/ItemView.model';

export const useItemData = (systemId: string) => {
  const { locationData, isLoadingLocations } = useLocationData(systemId);

  const itemData = useMemo(() => {
    const barcodes: {
      [barcode: string]: ItemData;
    } = {};

    locationData.forEach((l) => {
      const issue = l.issues && l.issues.length ? l.issues[0] : null;

      if (l.wms_status && typeof l.wms_status !== 'string') {
        const wmsStatus = l.wms_status as IWMSSlotStatusST;
        if (wmsStatus.barcodes && wmsStatus.barcodes.length) {
          wmsStatus.barcodes.forEach((b, index) => {
            const id = `${l.slot_label}-${b}`;
            if (b !== null) {
              barcodes[id] = {
                ...barcodes[id],
                id,
                barcode: b,
                expected_at: l.slot_label,
                wms_date: new Date(wmsStatus.changed_at),
                query_date: new Date(wmsStatus.query_time),
                customer: wmsStatus.customers[index],
                article_nos: wmsStatus.article_nos[index],
                article_qty: wmsStatus.qtys[index],
                issue_type: issue?.type || null,
                issue_state: issue?.state || null,
              };
            }
          });
        }
      }

      if (l.verity_status && typeof l.verity_status !== 'string') {
        const verityStatus = l.verity_status as IVeritySlotStatusST;
        if (verityStatus.barcodes && verityStatus.barcodes.length) {
          verityStatus.barcodes.forEach((b) => {
            const id = `${l.slot_label}-${b}`;
            if (b !== null) {
              barcodes[id] = {
                ...barcodes[id],
                id,
                barcode: b,
                found_at: l.slot_label,
                collected_on: new Date(verityStatus.collected_at),
              };
            }
          });
        }
      }
    });

    Object.keys(barcodes).forEach((key) => {
      const barcode = barcodes[key];

      barcode.barcodeStatus = matchBarcode(
        barcode.expected_at ? barcode.barcode : '',
        barcode.found_at ? barcode.barcode : '',
      );
    });

    return barcodes;
  }, [locationData]);

  return useMemo(
    () => ({ itemData, isLoading: isLoadingLocations }),
    [itemData, isLoadingLocations],
  );
};
