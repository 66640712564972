import { Ref, forwardRef } from 'react';
import { Box as MuiBox, BoxProps } from '@mui/material';

/**
 * Applying component="div" attribute to all MUI Boxes due to type confusion with Drei
 * @param props mui BoxProps
 * @returns Mui/material/Box div component
 */
export const Box = forwardRef(({ children, ...rest }: BoxProps, ref: Ref<unknown>) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore This is needed or otherwise build will fail
  <MuiBox component="div" {...rest} ref={ref}>
    {children}
  </MuiBox>
));
