import BarChartIcon from '@mui/icons-material/BarChart';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import { IconButton, Tooltip } from '@mui/material';
import { ChartType } from './WarehouseStats.model';

const cycleChartType = (currentMode: ChartType): { type: ChartType; label: string } => {
  switch (currentMode) {
    case 'pie':
      return {
        type: 'progress',
        label: 'Progress Bar',
      };
    default:
      return {
        type: 'pie',
        label: 'Pie Chart',
      };
  }
};

export const WarehouseStatsWidgetToggle = ({
  mode,
  onToggle,
}: {
  mode: ChartType;
  onToggle: (chartType: ChartType) => void;
}) => {
  const { type: nextType, label: nextLabel } = cycleChartType(mode);

  const updateChartMode = () => {
    onToggle(nextType);
  };

  return (
    <Tooltip title={`Change displayed chart type to "${nextLabel}"`}>
      <IconButton sx={{ marginTop: '-3px' }} onClick={updateChartMode}>
        {mode === 'pie' && <BarChartIcon fontSize="medium" />}

        {mode === 'progress' && <DonutSmallIcon fontSize="medium" />}
      </IconButton>
    </Tooltip>
  );
};
