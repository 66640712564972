import { Paper, Typography } from '@mui/material';
import { IVeritySlotStatusStateST } from 'codegen/report';
import { getImageText } from './utils/getImageText';
import { useImageGridMessageContainerStyles } from './ImageGridMessageContainer.styles';

export const ImageGridMessageContainer = ({
  verityState,
  isLoadingImageUrls,
  imageUrlsLength,
  numberOfImageUrlsRequested,
}: {
  verityState: IVeritySlotStatusStateST | undefined;
  isLoadingImageUrls: boolean;
  imageUrlsLength: number;
  numberOfImageUrlsRequested: number;
}) => {
  const { classes } = useImageGridMessageContainerStyles();
  return (
    <Paper className={classes.paper} elevation={0}>
      <div className={classes.messageContainer}>
        <Typography
          className={classes.messageContainerText}
          color="textSecondary"
          align="center"
          variant="h6"
        >
          {getImageText({
            verityState,
            isLoadingImageUrls,
            imageUrlsLength,
            numberOfImageUrlsRequested,
          })}
        </Typography>
      </div>
    </Paper>
  );
};
