import { Typography } from '@mui/material';
import { useMeasurementSystem } from 'udb/ground-control/hooks/useMeasurementSystem';
import { formatDisplayValue } from 'components/DroneZoneForm/utils/formatDisplayValue';
import { DroneZones } from '../../../../model/drone-zones.model';
import { useStyles } from './DroneZoneDetailsPanelZoneProperties.styles';

export const DroneZoneDetailsPanelZoneProperties = ({ zone }: { zone: DroneZones }) => {
  const { classes } = useStyles();

  const { isMetricSystem } = useMeasurementSystem();

  return (
    <div className={classes.detailsProperties}>
      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>Name:</span>
        <span>{zone.name}</span>
      </Typography>

      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>Description:</span>
        <span>{zone.description}</span>
      </Typography>

      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>Bottom:</span>
        <span>{formatDisplayValue(zone.sizeAndPosition.minZ, { isMetricSystem })}</span>
      </Typography>

      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>Top:</span>
        <span>{formatDisplayValue(zone.sizeAndPosition.maxZ, { isMetricSystem })}</span>
      </Typography>

      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>Width:</span>
        <span>{formatDisplayValue(zone.sizeAndPosition.w, { isMetricSystem })}</span>
      </Typography>

      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>Length:</span>
        <span>{formatDisplayValue(zone.sizeAndPosition.l, { isMetricSystem })}</span>
      </Typography>

      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>X-Position:</span>
        <span>{formatDisplayValue(zone.sizeAndPosition.minX, { isMetricSystem })}</span>
      </Typography>

      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>Y-Position:</span>
        <span>{formatDisplayValue(zone.sizeAndPosition.minY, { isMetricSystem })}</span>
      </Typography>
    </div>
  );
};
