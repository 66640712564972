import { Vec6 } from 'shared/map-container/MapContainer.model';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import { toFixed2 } from 'utils/numberFormatting';
import { BottomLeftDimensions } from '../../../droneZone.model';
import { metricFormatter } from './metricFormatter';

export const validateSizeAndPositionFitsWithinWorldBox = ({
  zoneVectors,
  facilityVectors,
  fieldKey,
}: {
  zoneVectors: BottomLeftDimensions;
  facilityVectors: Vec6;
  fieldKey: keyof BottomLeftDimensions;
}): string | null => {
  const {
    w: zoneWidth,
    l: zoneLength,
    h: zoneHeight,
    minX: zoneMinX,
    minY: zoneMinY,
    minZ: zoneMinZ,
    maxZ: zoneMaxZ,
  } = Object.keys(zoneVectors).reduce(
    (acc, key) => ({ ...acc, [key]: Number(zoneVectors[key as keyof BottomLeftDimensions]) }),
    {} as BottomLeftDimensions,
  );

  const {
    minX: facilityMinX,
    minY: facilityMinY,
    maxX: facilityMaxX,
    maxY: facilityMaxY,
    minZ: facilityMinZ,
    maxZ: facilityMaxZ,
  } = mapFacilityVectors(facilityVectors);

  const MIN_DIFF_DELTA = 0.01;

  switch (fieldKey) {
    case 'w': {
      const validationMaxX = zoneMinX + zoneWidth;
      const deltaX = validationMaxX - facilityMaxX;

      if (zoneWidth < 0) {
        return '"Width" must be a positive number';
      }

      if (toFixed2(deltaX) >= MIN_DIFF_DELTA) {
        return `"Width" of ${metricFormatter(
          zoneWidth,
        )} places this zone outside of the facility by ${metricFormatter(deltaX)}`;
      }

      return null;
    }
    case 'l': {
      const validationMaxY = zoneMinY + zoneLength;
      const deltaY = validationMaxY - facilityMaxY;

      if (zoneLength < 0) {
        return '"Length" must be a positive number';
      }

      if (toFixed2(deltaY) >= MIN_DIFF_DELTA) {
        return `"Length" of ${metricFormatter(
          zoneLength,
        )} places this zone outside of the facility by ${metricFormatter(deltaY)}`;
      }

      return null;
    }
    case 'h': {
      const validationMaxZ = zoneMinZ + zoneHeight;
      const deltaZ = validationMaxZ - facilityMaxZ;

      if (zoneHeight < 0) {
        return '"Height" must be a positive number';
      }

      if (toFixed2(deltaZ) >= MIN_DIFF_DELTA) {
        return `"Height" of ${metricFormatter(
          zoneHeight,
        )} places this zone outside of the facility by ${metricFormatter(deltaZ)}`;
      }

      return null;
    }
    case 'minX': {
      const validationMaxX = zoneMinX + zoneWidth;
      const isBelowMin = toFixed2(zoneMinX - facilityMinX) <= -MIN_DIFF_DELTA;
      const isAboveMax = toFixed2(validationMaxX - facilityMaxX) >= MIN_DIFF_DELTA;

      if (!isBelowMin && !isAboveMax) {
        return null;
      }

      const deltaMinX = facilityMinX - zoneMinX;
      const deltaMaxX = validationMaxX - facilityMaxX;
      const validationValue = isBelowMin ? deltaMinX : deltaMaxX;

      return `"X-Position" of ${metricFormatter(
        zoneMinX,
      )} places this zone outside of the facility by ${metricFormatter(validationValue)}`;
    }
    case 'minY': {
      const validationMaxY = zoneMinY + zoneLength;
      const isBelowMin = toFixed2(zoneMinY - facilityMinY) <= -MIN_DIFF_DELTA;
      const isAboveMax = toFixed2(validationMaxY - facilityMaxY) >= MIN_DIFF_DELTA;

      if (!isBelowMin && !isAboveMax) {
        return null;
      }

      const deltaMinY = facilityMinY - zoneMinY;
      const deltaMaxY = validationMaxY - facilityMaxY;
      const validationValue = isBelowMin ? deltaMinY : deltaMaxY;

      return `"Y-Position" of ${metricFormatter(
        zoneMinY,
      )} places this zone outside of the facility by ${metricFormatter(validationValue)}`;
    }

    case 'minZ': {
      const isBelowMin = toFixed2(zoneMinZ - facilityMinZ) <= -MIN_DIFF_DELTA;
      const isAboveMax = toFixed2(zoneMinZ - facilityMaxZ) >= MIN_DIFF_DELTA;
      const isAboveZoneMax = zoneMinZ > zoneMaxZ;

      if (!isAboveZoneMax && !isBelowMin && !isAboveMax) {
        return null;
      }

      if (isAboveZoneMax) {
        return '"Bottom" value cannot be higher than the "top" value';
      }

      const deltaMinZ = facilityMinZ - zoneMinZ;
      const deltaMaxZ = zoneMinZ - facilityMaxZ;

      if (isBelowMin) {
        return `"Bottom" of ${metricFormatter(
          zoneMinZ,
        )} places this zone below the facility by ${metricFormatter(deltaMinZ)}`;
      }

      return `"Bottom" of ${metricFormatter(
        zoneMinZ,
      )} places this zone above the facility by ${metricFormatter(deltaMaxZ)}`;
    }
    case 'maxZ': {
      const isBelowMin = toFixed2(zoneMaxZ - facilityMinZ) <= -MIN_DIFF_DELTA;
      const isAboveMax = toFixed2(zoneMaxZ - facilityMaxZ) >= MIN_DIFF_DELTA;
      const isBelowZoneMin = zoneMaxZ < zoneMinZ;

      if (isBelowZoneMin) {
        return '"Top" value cannot be lower than the "bottom" value';
      }

      if (isAboveMax) {
        const deltaMaxZAbove = zoneMaxZ - facilityMaxZ;

        return `"Top" of ${metricFormatter(
          zoneMaxZ,
        )} places this zone above the facility by ${metricFormatter(deltaMaxZAbove)}`;
      }

      if (isBelowMin) {
        const deltaMaxZBelow = facilityMinZ - zoneMaxZ;

        return `"Top" of ${metricFormatter(
          zoneMaxZ,
        )} places this zone below the facility by ${metricFormatter(deltaMaxZBelow)}`;
      }

      return null;
    }

    default:
      return null;
  }
};
