import Typography from '@mui/material/Typography';
import { NoConnection } from 'components/common/NoConnection';
import { tableStyles } from '../styles';

type EnhancedTableHeaderProps = {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  noData?: boolean;
};

/**
 * Old Table implementation, please use DataGrid instead
 * @deprecated
 */
export const EnhancedTableHeader = ({
  title,
  subtitle,
  children,
  noData,
}: EnhancedTableHeaderProps) => {
  const { cx, classes } = tableStyles();

  return (
    <div className={cx(classes.wrapper, classes.tableElement)}>
      <div className={classes.details}>
        <Typography className={classes.title} color="secondary" variant="h4">
          {title}
        </Typography>

        {noData ? (
          <NoConnection text="No data available." />
        ) : (
          <Typography className={classes.subheader} color="textSecondary">
            {subtitle}
          </Typography>
        )}
      </div>

      <div className={classes.actions}>{children}</div>
    </div>
  );
};
