import { DroneZoneGeometry, DroneZones, DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { NEW_DRONE_ZONE_ID } from 'shared/map/defaults/new-drone-zone-id';
import { createDroneZoneObject } from '../drone-zone-controls/features/utils/createDroneZoneObject.util';

export const buildDroneZone = (
  name: string,
  geometry: DroneZoneGeometry,
  type: DroneZoneTypes,
): DroneZones => {
  const { start, end, height } = geometry;

  if (!start || !end) {
    return createDroneZoneObject({
      id: NEW_DRONE_ZONE_ID,
      type: DroneZoneTypes.controlledZone,
      name: 'failed creation',
    });
  }

  const [dx, dy] = [end.x - start.x, end.y - start.y];
  const [x, y] = [start.x + dx / 2, start.y + dy / 2];
  const [width, length] = [Math.abs(dx), Math.abs(dy)];
  const [minX, minY] = [x - width / 2, y - length / 2];

  return createDroneZoneObject({
    name,
    id: NEW_DRONE_ZONE_ID,
    type,
    width,
    length,
    height,
    minX,
    minY,
    minZ: start.z,
    maxZ: end.z,
  });
};
