import isEmpty from 'lodash/isEmpty';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';

import '../facilityCardStyle.css';

import { CustomSelect } from 'components/common/CustomFormComponents/CustomSelect';
import { CustomMultiSelect } from 'components/common/CustomFormComponents/CustomMultiSelect';
import { SettingsItem, SettingsItemSize } from './models/settingsCard.model';

type WidthClass = {
  widthLarger?: string;
  widthLarge?: string;
  widthMedium?: string;
  widthSmall?: string;
};

export const setInputWidth = (classes: WidthClass, size?: SettingsItemSize) => {
  switch (size) {
    case 'larger':
      return classes.widthLarger;
    case 'large':
      return classes.widthLarge;
    case 'medium':
      return classes.widthMedium;
    case 'small':
      return classes.widthSmall;
    default:
      return undefined;
  }
};

export const renderTextInput = (item: SettingsItem, classes: WidthClass) => (
  <TextField
    data-testid={item.testId}
    className={setInputWidth(classes, item.width)}
    size="small"
    multiline={item.multiline}
    rows={item.multiline ? item.rows || 1 : undefined}
    fullWidth={true}
    variant="outlined"
    disabled={item.disableItemControls}
    value={item.value || undefined}
    onChange={item.action}
  />
);

export const renderJsonTextArea = (item: SettingsItem, classes: WidthClass) => (
  <TextareaAutosize
    data-testid={item.testId}
    className={setInputWidth(classes, item.width)}
    disabled={item.disableItemControls}
    value={JSON.stringify(item.values, null, 2) || undefined}
    onChange={(event) => item.action(JSON.parse(event.target.value))}
    style={{ width: '42em', maxWidth: '100%' }}
  />
);

export const renderSelectInput = (item: SettingsItem) => (
  <CustomSelect
    id={item.label}
    name={item.label}
    error={false}
    errorMessage=""
    testId={item.testId as string}
    style={{ minWidth: '140px' }}
    variant="outlined"
    disabled={!!item.disableItemControls}
    value={item.value || undefined}
    valueOptions={item.selectOptions || []}
    defaultValue=""
    onChange={item.action}
  />
);

export const renderMultiSelectInputOptionsDescriptions = (item: SettingsItem) => {
  if (!isEmpty(item.selectOptions)) {
    return item.selectOptions?.map((e) => (
      <p className="infoText" key={e.value}>
        <b>{e.label}</b> {e.description}
      </p>
    ));
  }
  return '';
};

export const renderMultiSelectInput = (item: SettingsItem) => (
  <CustomMultiSelect
    style={{ minWidth: '240px' }}
    name=""
    variant="outlined"
    disabled={item.disableItemControls}
    values={item.values || []}
    valueOptions={item.selectOptions || []}
    onChange={item.action}
    onClear={item.clearAction}
    testId={item.testId as string}
  />
);

export const renderNumberInput = (item: SettingsItem, classes: WidthClass) => (
  <TextField
    data-testid={item.testId}
    inputRef={item.ref}
    className={setInputWidth(classes, item.width)}
    size="small"
    variant="outlined"
    type="number"
    disabled={item.disableItemControls}
    InputProps={{ inputProps: item.inputProps }}
    value={item.value || undefined}
    error={Boolean(item.errors)}
    helperText={item.errors}
    onChange={item.action}
    onKeyUp={item.action}
  />
);

export const renderInputWithActivation = (item: SettingsItem, classes: WidthClass) => (
  <>
    {renderNumberInput(item, classes)}
    <Checkbox
      checked={!item.disableItemControls}
      onChange={(e) => {
        if (item.onChange) {
          item.onChange(e);
        }
      }}
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  </>
);

export const renderSwitchInput = (item: SettingsItem) => (
  <Switch
    data-testid={item.testId}
    color="primary"
    checked={item.value}
    disabled={item.disableItemControls}
    onChange={item.action}
  />
);

export const renderButton = (item: SettingsItem) => (
  <Button
    data-testid={item.testId}
    disabled={item.disableItemControls}
    color="primary"
    variant="outlined"
    size="small"
    onClick={item.action}
  >
    {item.text}
  </Button>
);
