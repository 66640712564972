import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import upperFirst from 'lodash/upperFirst';
import { RRule, rrulestr, Weekday } from 'rrule';

import {
  IReportSpecificationST,
  IReportParameterST,
  IReportParameterUniqueNameST,
} from 'codegen/report_specification';
import { IntervalLabel } from 'udb/inventory/features/reports/features/report-scheduler/features/Recurrence/recurrence.model';
import { INPUT_ERROR } from './errorMessages';
import {
  INPUT_MODES,
  WIDGET_TYPES,
  VALUE_TYPES,
  PARAM_TYPES,
  TRIGGERING_SOURCE,
} from './reportSpecifications';
import { DATETIME_FORMAT, WeekDayShort } from './datetimeFormats';
import { RRULE_WEEKDAYS, RRULE_INTERVALS } from './weekDays';

import { getSanitizedInputValues } from './functions/otherFunctions';

/**
 * Verify it is AWS S3 safe characters: alphanumeric, forward slash, period, hyphen, underscore
 * see https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html
 * Fixes https://verity-ag.atlassian.net/browse/IIDSUP-6166
 * @param key string to be verified
 * @returns boolean
 */
function isSafeAwsKey(key: string): boolean {
  // eslint-disable-next-line no-useless-escape
  const pattern: RegExp = /^[a-zA-Z0-9\._ ()-]*$/;
  return pattern.test(key);
}

export const validateReportName = (reportName: string) => {
  if (!reportName.length) {
    return {
      valid: false,
      errorMsg: INPUT_ERROR.REPORT_NAME,
    };
  }
  if (!isSafeAwsKey(reportName)) {
    return {
      valid: false,
      errorMsg: INPUT_ERROR.REPORT_NAME_SPECIAL_CHAR,
    };
  }
  return {
    valid: true,
    errorMsg: '',
  };
};

/**
 * Validate dynamic params for a given report pec
 * These exclude reportName, dates, and recurrence (which are common to all reports)
 * @param reportSpec Definition of the reports
 * @param reportParamValues Values to be verified
 * @returns a list of error messages
 */
export const validateAllParamValues = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reportSpec: { params: any[] },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reportParamValues: { unique_name: string; values: any[] }[],
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorMessages: any = [];

  // Get params that need validation from the report spec
  const paramsThatNeedValidation = reportSpec.params
    .filter((param: { enabled: boolean }) => param.enabled)
    .filter(
      (param: { input_mode: string }) =>
        param.input_mode !== INPUT_MODES.AUTOMATIC && param.input_mode !== INPUT_MODES.FIXED_VALUE,
    );

  // Ensure that all params have values set
  // if that is not the case, add an error message for invalid param values
  paramsThatNeedValidation.forEach(
    (param: {
      unique_name: string;
      value_type: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      input_settings: { lower_bound: any; upper_bound: any };
    }) => {
      const paramValue = reportParamValues?.find(
        (el: { unique_name: string }) => el.unique_name === param.unique_name,
      );
      if (isEmpty(paramValue)) {
        errorMessages.push({
          unique_name: param.unique_name,
          error: INPUT_ERROR.DEFAULT,
        });
      } else if (
        param.value_type === VALUE_TYPES.INTEGER ||
        param.value_type === VALUE_TYPES.DECIMAL
      ) {
        const { lower_bound: lowerBound, upper_bound: upperBound } = param.input_settings;
        if (
          (isNumber(lowerBound) && lowerBound > paramValue.values[0]) ||
          (isNumber(upperBound) && upperBound < paramValue.values[0])
        ) {
          if (param.unique_name === PARAM_TYPES.LOCATIONS_PERCENTAGE) {
            errorMessages.push({
              unique_name: param.unique_name,
              error: INPUT_ERROR.OUT_OF_RANGE(
                (lowerBound * 100).toString(),
                (upperBound * 100).toString(),
              ),
            });
          } else {
            errorMessages.push({
              unique_name: param.unique_name,
              error: INPUT_ERROR.OUT_OF_RANGE(lowerBound, upperBound),
            });
          }
        }
      }
    },
  );

  return { valid: isEmpty(errorMessages), errorMessages };
};

export const validateStartDate = (
  reportSpec: IReportSpecificationST,
  isRecurring: boolean,
  dateFrom: moment.MomentInput,
  dateUntil: moment.MomentInput,
  allowPastDates = false,
) => {
  const validate = (errorMsg: string) => ({
    valid: errorMsg === '',
    errorMsg,
  });

  if (!moment(dateFrom).isValid()) {
    return validate(INPUT_ERROR.INVALID_DATE);
  }

  if (
    moment(dateFrom).isBefore(moment().subtract(5, 'minutes').format(DATETIME_FORMAT)) &&
    !allowPastDates
  ) {
    return validate(INPUT_ERROR.PAST_DATE);
  }

  if (
    moment(dateFrom).isSame(moment(dateUntil)) &&
    reportSpec?.scheduling?.allows_recurrence &&
    isRecurring
  ) {
    return validate(INPUT_ERROR.SAME_DATE);
  }

  if (
    moment(dateFrom).isAfter(moment(dateUntil)) &&
    ((reportSpec?.scheduling?.allows_recurrence && isRecurring) ||
      !reportSpec?.scheduling?.allows_recurrence)
  ) {
    return validate(INPUT_ERROR.AFTER_UNTIL);
  }

  // validation passed
  return validate('');
};

export const validateEndDate = (
  reportSpec: IReportSpecificationST,
  isRecurring: boolean,
  dateFrom: moment.MomentInput,
  dateUntil: moment.MomentInput,
) => {
  const validate = (errorMsg: string) => ({
    valid: errorMsg === '',
    errorMsg,
  });

  if (
    !moment(dateUntil).isValid() &&
    ((reportSpec?.scheduling?.allows_recurrence && isRecurring) || dateUntil)
  ) {
    return validate(INPUT_ERROR.INVALID_DATE);
  }

  if (
    moment(dateUntil).isBefore(moment().format(DATETIME_FORMAT)) &&
    reportSpec?.scheduling?.allows_recurrence &&
    isRecurring
  ) {
    return validate(INPUT_ERROR.PAST_DATE);
  }

  if (
    moment(dateFrom).isSame(moment(dateUntil)) &&
    reportSpec?.scheduling?.allows_recurrence &&
    isRecurring
  ) {
    return validate(INPUT_ERROR.SAME_DATE);
  }

  if (
    moment(dateUntil).isBefore(dateFrom) &&
    ((reportSpec?.scheduling?.allows_recurrence && isRecurring) || dateUntil)
  ) {
    return validate(INPUT_ERROR.BEFORE_FROM);
  }

  // validation passed
  return validate('');
};

export const validateOccurrenceInterval = (
  isRecurring: boolean,
  value: number,
  maximumAllowed: number,
) => {
  let errorMsg = '';
  if (isRecurring) {
    if (value > maximumAllowed) {
      errorMsg = `Maximum allowed ${maximumAllowed > 1 ? 'occurrences' : 'occurrence'} ${
        maximumAllowed > 1 ? `are ${maximumAllowed}` : `is ${maximumAllowed}`
      }`;
    }
    if (value < 1) {
      errorMsg = "Occurrence can't be lower than 1";
    }
  }
  return {
    valid: errorMsg === '',
    errorMsg,
  };
};

export const validateRecurrenceInterval = (interval: IntervalLabel, isRecurring: boolean) => {
  let errorMsg = '';
  if (isRecurring) {
    if (!interval) {
      errorMsg = 'Please select recurring interval';
    }
  }
  return {
    valid: errorMsg === '',
    errorMsg,
  };
};

// Check if a given reportSpec param should render a single choice select UI component
// TODO::TR::2021-06-09:: create enumerator for all UI widgets, and functions to identify them
export const isParamSingleChoiceSelect = (param: IReportParameterST) =>
  param?.input_mode === INPUT_MODES.PREDEFINED_VALUES &&
  param?.enabled &&
  param?.input_settings?.ui_widget_type === WIDGET_TYPES.DROP_DOWN &&
  param?.cardinality_max === 1;

// Check if a given reportSpec param should render a multi choice select UI component
export const isParamMultiChoiceSelect = (param: IReportParameterST) =>
  param?.input_mode === INPUT_MODES.PREDEFINED_VALUES &&
  param?.enabled &&
  param?.input_settings?.ui_widget_type === WIDGET_TYPES.DROP_DOWN &&
  (param?.cardinality_max === -1 || param?.cardinality_max > 1);

// Check if a given reportSpec param should render a text input UI component
export const isParamTextInput = (param: IReportParameterST) =>
  param?.input_mode === INPUT_MODES.CUSTOM_VALUES &&
  param?.enabled &&
  param?.input_settings?.ui_widget_type === WIDGET_TYPES.TEXT_FIELD;

export const getRecurrenceText = (
  reportType: string | string[],
  isRecurring: boolean,
  text: string | undefined,
) => {
  if (reportType.includes('Auto')) {
    return 'Auto';
  }
  if (!isRecurring) {
    return '-';
  }
  return upperFirst(text);
};

export interface IGenerateRruleFromUIComponentsParam {
  days: WeekDayShort[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDays: any;
  isRecurring: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateFrom: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateUntil: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  occurrenceInterval: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timezone: any;
  interval: 'yearly' | 'monthly' | 'weekly' | 'daily' | 'hourly';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reportSpecTriggering: any;
}
// Convert defined dates, intervals using rrule.js library
// module for parsing and serialization of recurrence rules
export const generateRruleFromUIComponents = ({
  days,
  setDays,
  isRecurring,
  dateFrom,
  dateUntil,
  occurrenceInterval,
  timezone,
  interval,
  reportSpecTriggering,
}: IGenerateRruleFromUIComponentsParam) => {
  let rule;
  let rRuleDays: WeekDayShort[] | Weekday[] = [];
  const weekDaysList = Object.keys(RRULE_WEEKDAYS) as WeekDayShort[];

  // Set current day as active weekDay in case there is no selected day
  if (isEmpty(days) && interval === 'weekly') {
    rRuleDays = weekDaysList.filter((day, index) => index === moment(dateFrom).weekday());
    setDays(rRuleDays);
  }

  rRuleDays = days.map((day) => RRULE_WEEKDAYS[day]);

  // We extract this constants because current version
  // of rrrule.js only work with 'Date'
  const yearFrom = moment(dateFrom).year();
  const monthFrom = moment(dateFrom).month();
  const dayFrom = Number(moment(dateFrom).format('DD'));
  const hoursFrom = moment(dateFrom).hours();
  const minutesFrom = moment(dateFrom).minutes();

  const yearUntilUTC = moment.utc(dateUntil).year();
  const monthUntilUTC = moment.utc(dateUntil).month();
  const dayUntilUTC = Number(moment.utc(dateUntil).format('DD'));
  const hoursUntilUTC = moment.utc(dateUntil).hours();
  const minutesUntilUTC = moment.utc(dateUntil).minutes();

  const dtstart = new Date(Date.UTC(yearFrom, monthFrom, dayFrom, hoursFrom, minutesFrom));
  const until = new Date(
    Date.UTC(yearUntilUTC, monthUntilUTC, dayUntilUTC, hoursUntilUTC, minutesUntilUTC),
  );

  // Create a rrule with defined dates and intervals
  if (isRecurring && interval) {
    // recurring request
    rule = new RRule({
      dtstart,
      until,
      freq: RRULE_INTERVALS[interval],
      interval: occurrenceInterval,
      byweekday: isEmpty(rRuleDays) || interval !== 'weekly' ? null : rRuleDays,
      tzid: timezone,
    });
  } else {
    // non-recurring and event based requests
    rule = new RRule({
      dtstart,
      until: reportSpecTriggering === TRIGGERING_SOURCE.EXTERNAL_EVENT ? until : null,
      count: reportSpecTriggering === TRIGGERING_SOURCE.EXTERNAL_EVENT ? 0 : 1,
      freq: RRule.SECONDLY,
      tzid: timezone,
    });
  }

  return {
    rString: rule.toString(),
  };
};

// Parse rRule string to object and return extracted values
export const convertRruleToUIComponents = (rrule: string) => {
  const days: WeekDayShort[] = [];
  const ruleDays = Object.keys(RRULE_WEEKDAYS) as WeekDayShort[];
  const ruleIntervals = Object.keys(RRULE_INTERVALS);
  const ruleObj = rrulestr(rrule); // convert rrule string to object

  // handle weekDays
  ruleObj.options.byweekday?.forEach((day) => {
    days.push(ruleDays[day]);
  });

  return {
    isRecurring: ruleObj.options.count === null,
    dtstart: ruleObj.options.dtstart,
    until: ruleObj.options.until,
    interval: ruleObj.options.interval,
    freq: ruleIntervals[ruleObj.options.freq],
    text: ruleObj.toText(),
    tzid: ruleObj.options.tzid,
    days,
  };
};

/**
 * Changes user given value on its way to the state
 * @param paramUniqueName name of the parameter
 * @param value value of the parameter
 * @returns prepared value for the state
 */
export const prepareParamValue = (
  paramUniqueName: IReportParameterUniqueNameST,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
): string[] => {
  switch (paramUniqueName) {
    case PARAM_TYPES.LOCATIONS_PERCENTAGE:
      return isEmpty(values) ? [values] : [(parseFloat(values) / 100).toString()];
    default:
      return getSanitizedInputValues(values);
  }
};

/**
 * Get param spec
 * @param reportSpec report specification
 * @param paramUniqueName param unique name
 * @returns report spec params
 */
export const getParamSpec = (
  reportSpec: IReportSpecificationST,
  paramUniqueName: IReportParameterUniqueNameST,
) => reportSpec.params.find((param: IReportParameterST) => param.unique_name === paramUniqueName);
