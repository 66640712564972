export const imperialFormatter = (value: number): string => {
  const signFactor = value < 0 ? -1 : 1;
  const totalInches = (value * signFactor) / 0.0254;

  let feet = Math.floor(totalInches / 12);
  let inches = Math.abs(Math.round(totalInches % 12));

  if (inches === 12) {
    feet += 1;
    inches = 0;
  }

  const feetFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  });

  const inchesFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  });

  return `${feetFormatter.format(feet * signFactor)}'${inchesFormatter.format(inches)}"`;
};
