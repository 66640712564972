import {
  DataGridPremium,
  GridInitialState,
  GridRowParams,
  GridSlots,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { premiumGridStyles } from 'udb/inventory/features/premium-grid/premiumGrid.styles';
import { getGridRowHeight } from 'udb/inventory/features/premium-grid/utils/getGridRowHeight.util';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { useParams, useSearchParams } from 'react-router-dom';
import { getFilteredLocationData } from 'udb/inventory/features/premium-grid/utils/getFilteredLocationData.util';
import { ILocationDataST } from 'codegen/report';
import { cleanGridState } from 'udb/inventory/features/premium-grid/utils/cleanGridState.util';
import { LocationModal } from 'components/ModalsAndPopups/LocationModal/LocationModal';
import { getSelectedLocationRows } from 'udb/inventory/features/premium-grid/utils/getSelectedLocationRows';
import { getFilteredLocationDataListFromGrid } from 'udb/inventory/features/premium-grid/utils/getFilteredLocationsFromGridApi.util';
import { serializedInitialGridState } from '../../defaults/serializedInitialGridState.default';
import { stateToMessageMapper } from '../../utils/stateToMessageMapper';
import { getColumns } from '../../models/ReportColumns.model';
import { getReportTabs } from '../../models/ReportTabs.model';
import { useReportTableStyles } from './ReportTable.styles';
import { ReportTableToolbar } from './features/report-table-toolbar/ReportTableToolbar';
import { ReportTableHeader } from './features/report-table-header/ReportTableHeader';
import { useReportData } from './hooks/useReportData';
import { toILocationDataSTMapper } from './utils/toILocationDataSTMapper';

const STARTING_TAB = 0;
const ACTIVE_TAB_KEY = 'activeTab';
const LOCATION_KEY = 'location';
const DATA_GRID_STATE_KEY = 'dataGridState';

export const ReportTable = () => {
  const { classes } = useReportTableStyles();

  const gridApiRef = useGridApiRef();
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { facilitySettings } = stateFacilityLevel;
  const { reportId = '', systemId = '' } = useParams();

  const { locationData, isLoadingReportLocations, invalidateReportLocationData } = useReportData({
    reportId,
    systemId,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const currentLocationName = searchParams.get(LOCATION_KEY);
  const [serializedGridState, setSerializedGridState] = useState('');
  const [activeTab, setActiveTab] = useState<number>(
    Number(searchParams.get('activeTab') ?? STARTING_TAB),
  );
  const dataGridStateFromURL = searchParams.get(DATA_GRID_STATE_KEY);
  const [gridViewFromURL] = useState<GridInitialState>(
    dataGridStateFromURL ? JSON.parse(dataGridStateFromURL) : undefined,
  );

  const reportTabs = useMemo(
    () =>
      getReportTabs({
        isUserAmendAllowed: !!facilitySettings?.allow_user_amend,
        areLabelsShown: !!facilitySettings.show_location_labels,
      }),
    [facilitySettings?.allow_user_amend, facilitySettings.show_location_labels],
  );

  const handleGridStateChange = () => {
    const currentGridState = cleanGridState(gridApiRef.current.exportState());
    const currentSerializedGridState = JSON.stringify(currentGridState);

    if (serializedGridState !== currentSerializedGridState) {
      setSerializedGridState(currentSerializedGridState);
    }
  };

  useEffect(() => {
    if (serializedGridState === serializedInitialGridState || serializedGridState === '') {
      return;
    }
    const urlState = searchParams.get(DATA_GRID_STATE_KEY);

    if (serializedGridState !== urlState) {
      searchParams.set(DATA_GRID_STATE_KEY, serializedGridState);
      setSearchParams(searchParams);
    }
  }, [serializedGridState, searchParams, setSearchParams]);

  const fullReportMappedToLocationDataWithUpdatedStatus = useMemo(
    () => locationData.map(toILocationDataSTMapper).map(stateToMessageMapper),
    [locationData],
  ) as ILocationDataST[];

  const preFilteredLocationDataList = useMemo(
    () =>
      getFilteredLocationData(
        fullReportMappedToLocationDataWithUpdatedStatus,
        reportTabs[activeTab].issueTypeFilters,
        reportTabs[activeTab].issueStateFilters,
        reportTabs[activeTab].slotSettingFilter,
        reportTabs[activeTab]?.filter,
      ),
    [fullReportMappedToLocationDataWithUpdatedStatus, activeTab, reportTabs],
  );

  const columns = useMemo(
    () => getColumns(facilitySettings, preFilteredLocationDataList, activeTab),
    [activeTab, facilitySettings, preFilteredLocationDataList],
  );

  useEffect(() => {
    const searchActiveTab = Number(searchParams.get(ACTIVE_TAB_KEY));
    searchActiveTab && setActiveTab(searchActiveTab);
  }, [isLoadingReportLocations, searchParams]);

  const closeLocationModal = useCallback(
    () =>
      setSearchParams((previousSearchParams) => {
        previousSearchParams.delete(LOCATION_KEY);
        return previousSearchParams;
      }),
    [setSearchParams],
  );

  const handleRowClick = useCallback(
    (params: GridRowParams) =>
      setSearchParams((previousSearchParams) => {
        previousSearchParams.set(LOCATION_KEY, params.row.slot_label);
        return previousSearchParams;
      }),
    [setSearchParams],
  );

  const locationModalLocations = useMemo(() => {
    if (!currentLocationName) {
      return [];
    }
    const gridFilteredLocationDataList = getFilteredLocationDataListFromGrid(gridApiRef);

    const hasCurrentLocationInFiltered = gridFilteredLocationDataList?.some(
      (location) => location.slot_label === currentLocationName,
    );

    return getSelectedLocationRows(
      currentLocationName,
      gridFilteredLocationDataList && hasCurrentLocationInFiltered
        ? gridFilteredLocationDataList
        : preFilteredLocationDataList,
      fullReportMappedToLocationDataWithUpdatedStatus,
    );
  }, [
    currentLocationName,
    preFilteredLocationDataList,
    gridApiRef,
    fullReportMappedToLocationDataWithUpdatedStatus,
  ]);

  return (
    <Card>
      <ReportTableHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        gridApiRef={gridApiRef}
        reportTabs={reportTabs}
        fullReportMappedToLocationDataWithUpdatedStatus={
          fullReportMappedToLocationDataWithUpdatedStatus
        }
      />

      <div className={classes.cardBody}>
        <DataGridPremium
          sx={premiumGridStyles}
          columns={columns}
          apiRef={gridApiRef}
          rows={preFilteredLocationDataList}
          getRowId={(row) => row.slot_label}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd'
          }
          getRowHeight={getGridRowHeight}
          columnHeaderHeight={45}
          slots={{
            toolbar: ReportTableToolbar,
            loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
          }}
          loading={isLoadingReportLocations}
          onRowClick={handleRowClick}
          localeText={{
            // @ts-expect-error customizing types for localeText is not fully supported currently. https://github.com/mui/mui-x/blob/HEAD/packages/x-data-grid/src/constants/localeTextConstants.ts
            headerFilterOperatorNotContains: 'not contain',
            headerFilterOperatorIsNotAnyOf: 'is not any of',
            headerFilterOperatorIsNot: 'is not',
          }}
          rowGroupingColumnMode="multiple"
          disableRowSelectionOnClick
          disableAggregation
          headerFilters
          headerFilterHeight={60}
          onStateChange={handleGridStateChange}
          initialState={gridViewFromURL ?? reportTabs[activeTab].gridState}
          data-testid="report-table"
        />
      </div>
      {currentLocationName && (
        <LocationModal
          closeModal={closeLocationModal}
          refreshTableData={() => invalidateReportLocationData(currentLocationName)}
          refreshTableDataChunk={() => invalidateReportLocationData(currentLocationName)}
          filteredLocationList={locationModalLocations}
          parentPage="Report"
          reportId={reportId}
        />
      )}
    </Card>
  );
};
