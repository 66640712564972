import { Button, TextField } from '@mui/material';
import { KeyboardEvent } from 'react';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { sharedStyles } from './styles';

export const MfaSubmitForm = ({
  handleSubmit,
  handleChange,
  value,
}: {
  value: string;
  handleChange: (e: React.ChangeEvent<unknown>) => void;
  handleSubmit: () => void;
}) => {
  const { classes } = sharedStyles();
  return (
    <>
      <TextField
        className={classes.textField}
        variant="outlined"
        required
        fullWidth
        id="code"
        label="Authentication Code"
        name="mfaCode"
        onChange={handleChange}
        autoFocus
        onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && handleSubmit()}
      />

      <Button
        fullWidth
        variant="contained"
        color="primary"
        disabled={value.length !== 6}
        onClick={handleSubmit}
        endIcon={<KeyboardArrowRightIcon />}
      >
        Submit
      </Button>
    </>
  );
};
