import {
  ILocationDataST,
  IVeritySlotStatusST,
  IVeritySlotStatusStateST,
  IWMSSlotStatusST,
} from 'codegen/warehouse_status';
import { DISPLAY_VERITY_STATES } from 'common/slotStates';
import { matchBarcodes } from 'common/functions/barcodes/matchBarcode.util';
import { emptyCellString } from './collectionDelayValueGetter';

export const contentFoundValueGetter = (row: ILocationDataST): string => {
  const wmsStatus = row.wms_status as IWMSSlotStatusST;
  const verityStatus = row.verity_status as IVeritySlotStatusST;

  if (!verityStatus || typeof verityStatus === 'string') {
    return verityStatus;
  }

  if (verityStatus.state === IVeritySlotStatusStateST.Empty) {
    const { unexpected, missing } = matchBarcodes({
      expected: wmsStatus?.barcodes,
      found: verityStatus?.barcodes,
    });

    if (unexpected.length !== 0 || missing.length !== 0) {
      return DISPLAY_VERITY_STATES[verityStatus.state];
    }
  }

  const { barcodes: verityBarcodes, user_overrides: userOverrides } = verityStatus;

  let content: string = emptyCellString;

  const currentOverride = userOverrides?.length ? userOverrides[userOverrides.length - 1] : null;

  if (currentOverride && currentOverride.state !== 'BARCODE') {
    return `${DISPLAY_VERITY_STATES[currentOverride.state]} *`;
  }
  const barcodes = currentOverride ? currentOverride.barcodes : verityBarcodes;

  if (barcodes.length > 0) {
    const list = barcodes.map((barcode) => `${barcode}${currentOverride ? ' *' : ''}`);
    content = list.join(', ');
  } else {
    content = DISPLAY_VERITY_STATES[verityStatus.state];
  }

  return content;
};
