import { issuesStore } from 'store/IssuesStore';
import { IRequestController } from 'hooks';
import { IOverwritingItem } from '../../AmendValues.model';

export const overwriteVerityValue = ({
  systemId,
  requestController,
  overwritingItem,
  location,
  version,
  callbackSuccess,
  callbackError,
}: {
  systemId: string;
  requestController: IRequestController;
  overwritingItem: IOverwritingItem;
  location: string;
  version: number;
  callbackSuccess: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callbackError?: (e: any) => void;
}) => {
  requestController.doRequest({
    request: issuesStore.userOverride,
    requestParams: [
      {
        systemId,
        data: overwritingItem,
        slot_label: location,
        version,
        isReview: false,
      },
    ],
    callbackSuccess: () => callbackSuccess(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callbackError: (e: any) => {
      if (callbackError) {
        callbackError(e);
      }
    },
    messageSuccess: `Location ${location} was successfully modified.  Please note it may take a few minutes for your change to reflect in the system.`,
    messageErrorFallback: `Location ${location} could not be modified`,
  });
};
