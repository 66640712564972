import type { BottomLeftDimensions, ImperialBottomLeftDimensions } from '../../../droneZone.model';

export const convertFeetToMeters = (feetValue: string): number => {
  const regex = /^-?([0-9]*')?([0-9]*?("{1}|'{2}))?$/;
  const matches = feetValue.toString().match(regex);

  if (!matches) {
    return 0;
  }

  const feet = matches[1] ? parseInt(matches[1], 10) : 0;
  const inches = matches[2] ? parseInt(matches[2], 10) : 0;

  const signFactor = feetValue.charAt(0) === '-' ? -1 : 1;
  const totalInches = feet * 12 + inches;
  const meters = totalInches * 0.0254 * signFactor;

  return meters;
};

export const convertAllFeetToMeters = (
  sizeAndPosition: ImperialBottomLeftDimensions,
): BottomLeftDimensions =>
  Object.entries(sizeAndPosition).reduce((acc, [key, value]) => {
    acc[key as keyof BottomLeftDimensions] = convertFeetToMeters((value as string) ?? '0\'0"');
    return acc;
  }, {} as BottomLeftDimensions);
