import { CameraControls } from '@react-three/drei';
import CameraControlsLib from 'camera-controls';
import { RefObject } from 'react';
import { Group } from 'three';
import { useFitInLandscape } from './hooks/useFitInLandscape';
import { useTopZoomClamp } from '../../hooks/useTopZoomClamp';

export const TopViewScene = ({
  topViewRef,
  selectedFlightDomain,
}: {
  topViewRef: RefObject<Group>;
  selectedFlightDomain: string;
}) => {
  useTopZoomClamp(topViewRef, { padding: 10, selectedFlightDomain });
  useFitInLandscape({ topViewRef, selectedFlightDomain });

  return (
    <CameraControls
      makeDefault
      verticalDragToForward
      smoothTime={0.75}
      mouseButtons={{
        left: CameraControlsLib.ACTION.TRUCK,
        right: CameraControlsLib.ACTION.ROTATE,
        middle: CameraControlsLib.ACTION.NONE,
        wheel: CameraControlsLib.ACTION.ZOOM,
      }}
      minPolarAngle={0}
      maxPolarAngle={0}
    />
  );
};
