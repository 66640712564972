import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { TokenManager } from 'common/tokenManager';
import { AUTH_PAGES_URLS } from 'common/pages';
import { refreshToken } from './tokenConfiguration';
import { CustomAxiosRequestConfig } from './axios/axiosRequestConfig.model';
import { axiosInstance } from './axios/axiosInstance';

/**
 * URls for authentication requests
 */
const authUrls = ['/sign-in', '/refresh-token', '/forgot-password', '/auth-challenge'];

/**
 * Request interceptor that will refresh the token if the request is made using the expired token
 * @param request current created request before it will be sent to the server
 * @returns request with fresh new token
 */
export const tokenExpiryRequestInterceptor = async (
  request: CustomAxiosRequestConfig,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<InternalAxiosRequestConfig<any>> => {
  const lp = getLogPrefixForType('FUNCTION', 'tokenExpiryRequestInterceptor');

  const tokenManager = TokenManager.getInstance();
  const idToken = tokenManager.getIdToken();

  if (idToken) {
    axiosInstance.defaults.headers.common.Authorization = idToken;
  }

  /**
   * If there's no idToken (ex: User is logged out)
   * and it's not sign-in request, then don't send any request to the server
   *
   * This will prevent sending request to the server when sign in modal is opened
   */
  if (!idToken && request.url !== '/sign-in') {
    console.debug(
      lp,
      'no idToken or sign in page, return the request unchanged',
      `url: ${request.url}`,
      'idToken => ',
      idToken,
    );
    return request;
  }

  /**
   * Flag that indicates if refresh token request should be sent
   *
   * - retryRequest - key from config which will indicate if the
   *   next queued request should be retried
   */
  const shouldRefreshToken =
    !authUrls.includes(request.url as string) &&
    tokenManager.areAccessAndIdTokensExpired() &&
    !request.raxConfig?.retryRequest;

  console.debug(lp, `shouldRefreshToken: ${shouldRefreshToken}`, 'request', request);

  if (shouldRefreshToken) {
    console.debug(lp, 'token refresh required');

    Object.assign(
      request.raxConfig || request,
      request.raxConfig ? { retryRequest: true } : { raxConfig: { retryRequest: true } },
    );

    console.debug(lp, 'Original request intercepted with expired token, queue for retry', request);

    try {
      console.debug(lp, 'Invoke refresh token function');
      const res = await refreshToken(request.url);
      if (res?.data.access_token && res.data.id_token) {
        console.debug(lp, 'Tokens available, update headers with new id token');
        axiosInstance.defaults.headers.common.Authorization = res.data.id_token;
      }
      return request;
    } catch {
      window.location.pathname = AUTH_PAGES_URLS.SIGNOUT;
    }
  } else {
    console.debug(lp, 'token refresh not required');
  }
  return request;
};

/**
 * Response interceptor that will refresh the token if the request response has an error
 * for Authorization 401 (expired token)
 * @param error response error before it will reach .then() or .catch() on client side
 * @returns new instance with fresh token
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const badTokenResponseInterceptor = async (error: AxiosError<any, any>) => {
  const lp = getLogPrefixForType('FUNCTION', 'badTokenResponseInterceptor');
  const config = error as unknown as CustomAxiosRequestConfig | undefined;
  console.debug(lp, 'error occurred in response', error);

  const tokenManager = TokenManager.getInstance();

  const shouldRefreshToken =
    tokenManager.areAccessAndIdTokensExpired() &&
    error.response &&
    error.response.status === 401 &&
    !config?.raxConfig?.retryRequest;

  if (shouldRefreshToken) {
    console.debug(lp, 'refresh tokens');
    Object.assign(
      config?.raxConfig || config || {},
      config?.raxConfig ? { retryRequest: true } : { raxConfig: { retryRequest: true } },
    );
    try {
      const res = await refreshToken(config?.url);
      if (res?.data.access_token) {
        axiosInstance.defaults.headers.common.Authorization = res.data.id_token;
      }
      console.debug(lp, 'token successfully refreshed');
      return axiosInstance(config as CustomAxiosRequestConfig);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  return Promise.reject(error);
};
