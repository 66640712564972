import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useImageGridMessageContainerStyles = makeStyles()((theme: Theme) => ({
  paper: {
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(2),
  },
  messageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  messageContainerText: {
    width: '80%',
    marginLeft: '10%',
  },
}));
