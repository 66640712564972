import React, { useEffect } from 'react';

// material-ui core
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { makeStyles } from 'tss-react/mui';

// material-ui icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// components
import { bytesToSize } from 'common/functions/fileFunctions';
import { Box } from '../Box';
import { CustomDropZone } from '../CustomDropZone';

// variables, functions, configurations

const useStyles = makeStyles()(() => ({
  accordionStyle: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.125)',
    boxShadow: 'none !important',
  },
  bold: {
    fontWeight: 700,
  },
}));

export const ExpansibleDragAndDropFile = (props: {
  title: string;
  testId: string;
  dropzoneText: string;
  fileDetails?: {
    name: string;
    size: number;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDrop: (param: any) => void;
  isUploadDisabled?: boolean;
  uploadInProgress?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
  acceptedFiles?: {
    path: string;
    size: string;
  }[];
}) => {
  // props
  const {
    title,
    testId,
    dropzoneText,
    fileDetails,
    onDrop,
    isUploadDisabled,
    uploadInProgress,
    children,
    acceptedFiles = [],
  } = props;
  const { classes } = useStyles();

  // Mitigation for the material-ui generated warning about updating the state of an unmounted component
  const [showDropzone, setShowDropzone] = React.useState(false);

  useEffect(() => {
    setShowDropzone(true);
  }, []);

  return (
    <Accordion className={classes.accordionStyle}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          data-testid={`${testId}-accordion-title`}
          variant="body1"
          color="textSecondary"
          component="p"
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {onDrop ? (
          <Box data-testid={testId} width="100%" pt={2}>
            {showDropzone && (
              <CustomDropZone
                id={`c-${testId}-dropzone`}
                disabled={isUploadDisabled || uploadInProgress}
                maxFiles={1}
                text={dropzoneText}
                showFiles={!!fileDetails}
                acceptedFiles={acceptedFiles}
                onDrop={onDrop}
              />
            )}
            {fileDetails && (
              <Typography variant="caption" className={fileDetails.name ? classes.bold : ''}>
                {fileDetails.name
                  ? `File: ${fileDetails.name} - ${bytesToSize(fileDetails.size)}`
                  : 'No file selected'}
              </Typography>
            )}
          </Box>
        ) : (
          children
        )}
      </AccordionDetails>
    </Accordion>
  );
};
