import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

export enum BarcodeStatusEnum {
  Match = 'Match',
  Unexpected = 'Unexpected',
  Missing = 'Missing',
  None = 'None',
}

export type BarcodeRow = {
  id: number;
  barcodeStatus: BarcodeStatusEnum;
  expectedContent: string;
  contentFound: string;
  foundAt: string;
  shouldBeAt: string;
  wmsQuantity: string;
  articleNumber: string;
  customer: string;
  locationData: ILocationData;
};
