import { AxiosResponse } from 'axios';
import { UPLOADED_FILE_ACTIONS } from 'common/Actions/actionTypes';
import {
  IWMSDataFilesGetResponseST,
  IWMSDataUploadPostRequestST,
  IWMSDataUploadPostResponseST,
  IWMSMovedLocationsDataUploadPostRequestST,
  IWMSMovedLocationsDataUploadPostResponseST,
  IWMSStockCheckDataUploadPostRequestST,
  IWMSStockCheckDataUploadPostResponseST,
} from 'codegen/wms_data';
import { IReportSpecificationPostRequestST } from 'codegen/report_specification';
import { uploadServices } from '../services/UploadServices';
import { IRowActions, IUploadFileParams, UPLOADED_FILES_TYPES } from '../interfaces';

const uploadFile = (
  systemId: string,
  { file, setProgress, fileType, dispatcher }: IUploadFileParams,
) => {
  let presignedPostService:
    | undefined
    | ((
        systemId: string,
        data:
          | IWMSDataUploadPostRequestST
          | IWMSStockCheckDataUploadPostRequestST
          | IWMSMovedLocationsDataUploadPostRequestST,
      ) => Promise<
        AxiosResponse<
          | IWMSDataUploadPostResponseST
          | IWMSStockCheckDataUploadPostResponseST
          | IWMSMovedLocationsDataUploadPostResponseST
        >
      >);

  switch (fileType) {
    case 'WMSSnapshot':
      presignedPostService = () =>
        uploadServices.getWMSDataPresignedUrl(systemId, { upload_filename: file.name });
      break;
    case 'StockCheck':
      presignedPostService = () =>
        uploadServices.getStockCheckDataPresignedUrl(systemId, {
          upload_filename: file.name,
        });
      break;
    case 'MovedLocations':
      presignedPostService = () =>
        uploadServices.getMovedLocationsDataPresignedUrl(systemId, {
          upload_filename: file.name,
        });
      break;
    default:
      break;
  }

  if (presignedPostService !== undefined) {
    return uploadServices.uploadWMSFile(presignedPostService, file, setProgress, dispatcher);
  }
  return undefined;
};

const getUploadedFiles = (
  systemId: string,
  fileType: UPLOADED_FILES_TYPES,
  params: { from: string; until: string },
  signal: AbortSignal,
) => {
  let uploadedFilesService = null;

  switch (fileType) {
    case 'WMSSnapshot':
      uploadedFilesService = uploadServices.getUploadedWMSData;
      break;
    case 'StockCheck':
      uploadedFilesService = uploadServices.getUploadedStockCheckData;
      break;
    case 'MovedLocations':
      uploadedFilesService = uploadServices.getUploadedMovedLocationsData;
      break;
    default:
      break;
  }
  if (!uploadedFilesService) {
    return undefined;
  }
  return uploadedFilesService(systemId, params, signal).then(
    (r: { data: IWMSDataFilesGetResponseST }) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let fullResponse: any = 'item' in r.data ? r.data.item : r.data.items;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let uploadedFiles: any[] = [];
      const pageToken = 'page_token' in r.data ? r.data.page_token : undefined;

      // In case there are no uploaded files, set it to an empty object
      if (!fullResponse) fullResponse = {};

      if (Array.isArray(fullResponse)) {
        fullResponse.forEach((file) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const rowData: any = {};
          const wmsParserFileNotAvailable =
            !file?.wms_parser_output_url || file.status === 'invalid';
          const clientFileNotAvailable = !file?.client_file_url;

          const actions: IRowActions = {
            data: file,
            actions: [
              {
                label: clientFileNotAvailable
                  ? 'Client file not available'
                  : UPLOADED_FILE_ACTIONS.DOWNLOAD_CLIENT_FILE,
                disabled: clientFileNotAvailable,
              },
              {
                label: wmsParserFileNotAvailable
                  ? 'Parser output not available'
                  : UPLOADED_FILE_ACTIONS.DOWNLOAD_WMS_PARSER_OUTPUT,
                disabled: wmsParserFileNotAvailable,
              },
            ],
          };

          rowData.id = file.client_file_url;

          rowData.uploadDate = file.last_modified;
          rowData.uploadUser = file.uploader;
          rowData.clientFileName = file.client_file_name;
          rowData.mostRecentEntryUpdatedAt = file.most_recent_entry_updated_at;
          rowData.uploadStatus = file.status;
          rowData.updatedLocations =
            file.total_locations === 'n.a.'
              ? 'n.a.'
              : `${file.updated_locations}/${file.total_locations}`;
          rowData.requestWarnings = String(file.request_warnings);
          rowData.locationWarnings = String(file.location_warnings);
          rowData.skippedLocations = String(file.skipped_locations);
          rowData.processedChunks =
            file.num_chunks === 'n.a.' ? 'n.a.' : `${file.processed_chunks}/${file.num_chunks}`;
          rowData.actions = actions;

          uploadedFiles = [...uploadedFiles, rowData];
        });
      }

      return {
        fullResponse,
        uploadedFiles,
        pageToken,
      };
    },
  );
};

const uploadReportSpecification = (systemId: string, file: IReportSpecificationPostRequestST) =>
  uploadServices.uploadReportSpecification(systemId, file).then((r) => r);

const getReportSpecifications = (systemId: string) =>
  uploadServices.getReportSpecifications(systemId).then((r) => r.data.items);

const deleteReportSpecification = (systemId: string, reportSpecId: string) =>
  uploadServices.deleteReportSpecification(systemId, reportSpecId).then((r) => r);

export const uploadStore = {
  uploadFile,
  getUploadedFiles,
  getReportSpecifications,
  deleteReportSpecification,
  uploadReportSpecification,
};
