import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const NUMBER_OF_CONCURRENT_IMAGES_TO_DISPLAY = 3;

export const useImageGridStyles = makeStyles()((theme: Theme) => ({
  imageListContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    margin: 'auto 0',
    position: 'relative',
    width: '100%',
    paddingBottom: 16,

    '&::-webkit-scrollbar': {
      height: 9,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#6359D1',
      borderRadius: 200,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#D9D9D9',
      borderRadius: 10,
    },
  },

  imageListItem: {
    width: `calc((100% / ${NUMBER_OF_CONCURRENT_IMAGES_TO_DISPLAY}) - ${theme.spacing(1)})`,
    minWidth: `calc((100% / ${NUMBER_OF_CONCURRENT_IMAGES_TO_DISPLAY}) - ${theme.spacing(1)})`,
    aspectRatio: '4 / 3',
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    cursor: 'default',

    '&.alt-pressed': {
      cursor: 'pointer',
    },
  },
}));
