import { useTheme } from '@mui/material';
import { Line, Plane } from '@react-three/drei';
import { FC } from 'react';
import { ActiveAisleProps } from './ActiveAisle.model';

export const ActiveAisle: FC<ActiveAisleProps> = ({
  position,
  scale,
  lineWidth = 0.2,
  opacity = 0.08,
  ...dashedProps
}) => {
  let dashPropsX = {};
  let dashPropsY = {};

  if (dashedProps.dashed) {
    const dashSpacing = dashedProps.dashSpacing ?? 0.2;
    const normalizedSpacingX = dashSpacing / scale.x;
    const normalizedSpacingY = dashSpacing / scale.y;
    dashPropsX = {
      dashed: true,
      dashSize: normalizedSpacingX,
      gapSize: normalizedSpacingX,
    };
    dashPropsY = {
      dashed: true,
      dashSize: normalizedSpacingY,
      gapSize: normalizedSpacingY,
    };
  }

  const theme = useTheme();
  const color = theme.palette.info.main;
  return (
    <Plane position={position} scale={scale}>
      <meshStandardMaterial color={color} transparent opacity={opacity} />
      <Line
        points={[
          [0.5, -0.5, 0],
          [0.5, 0.5, 0],
        ]}
        color={color}
        lineWidth={lineWidth}
        worldUnits
        {...dashPropsY}
      />
      <Line
        points={[
          [0.5, 0.5, 0],
          [-0.5, 0.5, 0],
        ]}
        color={color}
        lineWidth={lineWidth}
        worldUnits
        {...dashPropsX}
      />
      <Line
        points={[
          [-0.5, 0.5, 0],
          [-0.5, -0.5, 0],
        ]}
        color={color}
        lineWidth={lineWidth}
        worldUnits
        {...dashPropsY}
      />
      <Line
        points={[
          [-0.5, -0.5, 0],
          [0.5, -0.5, 0],
        ]}
        color={color}
        lineWidth={lineWidth}
        worldUnits
        {...dashPropsX}
      />
    </Plane>
  );
};
