import { IDroneStatusSTChargingStrategyEnum } from 'codegen/flight_domain';
import { Metric } from './Metric';

type ChargingStrategyProps = { strategy?: IDroneStatusSTChargingStrategyEnum };

export const ChargingStrategy = ({ strategy }: ChargingStrategyProps) => {
  let message = 'None';
  switch (strategy) {
    case IDroneStatusSTChargingStrategyEnum.AlwaysAvailableDuringOperatingHours:
      message = 'Always available during operating hours';
      break;
    case IDroneStatusSTChargingStrategyEnum.AlwaysCharge:
      message = 'Always charging';
      break;
    case IDroneStatusSTChargingStrategyEnum.AvailableFromStartOfOperatingHForMinimumDuration:
      message = 'Available from the start of operating hours for a minimum duration';
      break;
    case IDroneStatusSTChargingStrategyEnum.OnlyAvailableWhenActivities:
      message = 'Available only during activities';
      break;
    case IDroneStatusSTChargingStrategyEnum.AvailableFromStartOfOperatingHForMinimumDurationAndUnlocked:
      message =
        'Available from the start of operating hours for a minimum duration and system is unlocked';
      break;
    case IDroneStatusSTChargingStrategyEnum.Null:
    default:
      break;
  }

  return <Metric value={message} />;
};
