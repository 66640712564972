/* tslint:disable */
/* eslint-disable */
/**
 * Inventory Request
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IInventoryRequest1ST
 */
export interface IInventoryRequest1ST {
  /**
   * Timestamp of when the description was created
   * @type {string}
   * @memberof IInventoryRequest1ST
   */
  created_at: string;
  /**
   * Next scheduling time UTC
   * @type {string}
   * @memberof IInventoryRequest1ST
   */
  next_scheduling_time_utc: string;
  /**
   *
   * @type {Array<IInventoryRequestParameter1ST>}
   * @memberof IInventoryRequest1ST
   */
  params: Array<IInventoryRequestParameter1ST>;
  /**
   * Detailed recurrence description
   * @type {string}
   * @memberof IInventoryRequest1ST
   */
  recurrence_description: string;
  /**
   * A name for the report
   * @type {string}
   * @memberof IInventoryRequest1ST
   */
  report_name: string;
  /**
   *
   * @type {IReportSpecificationST}
   * @memberof IInventoryRequest1ST
   */
  report_spec: IReportSpecificationST;
  /**
   * The unique identifier of the request
   * @type {string}
   * @memberof IInventoryRequest1ST
   */
  request_id: string;
  /**
   * The version of the request
   * @type {number}
   * @memberof IInventoryRequest1ST
   */
  request_version: number;
  /**
   * The name the user creating the request
   * @type {string}
   * @memberof IInventoryRequest1ST
   */
  requesting_user_email: string;
  /**
   * The unique identifier of the user creating the request
   * @type {string}
   * @memberof IInventoryRequest1ST
   */
  requesting_user_id: string;
  /**
   * The recurrence rule of an inventory request specifies its recurrence
   * @type {string}
   * @memberof IInventoryRequest1ST
   */
  rrule: string;
  /**
   * Inventory request state
   * @type {string}
   * @memberof IInventoryRequest1ST
   */
  state: IInventoryRequest1STStateEnum;
  /**
   * Priority of an inventory request. Higher number means higher priority.
   * @type {number}
   * @memberof IInventoryRequest1ST
   */
  priority: number;
  /**
   * Report timeout in minutes. Auto-abort will be triggered if the report isn\'t finish within the expected time. When not set, the deadline in facility settings will be used.
   * @type {number}
   * @memberof IInventoryRequest1ST
   */
  timeout: number | null;
  /**
   * Type of insights to be generated by the drones
   * @type {string}
   * @memberof IInventoryRequest1ST
   */
  insight_type?: IInventoryRequest1STInsightTypeEnum;
}

export const IInventoryRequest1STStateEnum = {
  Enabled: 'ENABLED',
  Expired: 'EXPIRED',
  Disabled: 'DISABLED',
} as const;

export type IInventoryRequest1STStateEnum =
  typeof IInventoryRequest1STStateEnum[keyof typeof IInventoryRequest1STStateEnum];
export const IInventoryRequest1STInsightTypeEnum = {
  BarcodeScanning: 'BARCODE_SCANNING',
  ItemCounting: 'ITEM_COUNTING',
} as const;

export type IInventoryRequest1STInsightTypeEnum =
  typeof IInventoryRequest1STInsightTypeEnum[keyof typeof IInventoryRequest1STInsightTypeEnum];

/**
 *
 * @export
 * @interface IInventoryRequestGetAllResponseST
 */
export interface IInventoryRequestGetAllResponseST {
  /**
   *
   * @type {Array<IInventoryRequest1ST>}
   * @memberof IInventoryRequestGetAllResponseST
   */
  items: Array<IInventoryRequest1ST>;
}
/**
 *
 * @export
 * @interface IInventoryRequestGetResponseST
 */
export interface IInventoryRequestGetResponseST {
  /**
   *
   * @type {IInventoryRequest1ST}
   * @memberof IInventoryRequestGetResponseST
   */
  item: IInventoryRequest1ST;
}
/**
 *
 * @export
 * @interface IInventoryRequestNextOccurrenceGetResponseST
 */
export interface IInventoryRequestNextOccurrenceGetResponseST {
  /**
   * Datetime value for next occurrence
   * @type {string}
   * @memberof IInventoryRequestNextOccurrenceGetResponseST
   */
  next_occurrence: string | null;
}
/**
 *
 * @export
 * @interface IInventoryRequestParameter1ST
 */
export interface IInventoryRequestParameter1ST {
  /**
   *
   * @type {IReportParameterUniqueNameST}
   * @memberof IInventoryRequestParameter1ST
   */
  unique_name: IReportParameterUniqueNameST;
  /**
   * List of values for param
   * @type {Array<string>}
   * @memberof IInventoryRequestParameter1ST
   */
  values: Array<string>;
}

/**
 *
 * @export
 * @interface IInventoryRequestParameterST
 */
export interface IInventoryRequestParameterST {
  /**
   *
   * @type {IReportParameterUniqueNameST}
   * @memberof IInventoryRequestParameterST
   */
  unique_name: IReportParameterUniqueNameST;
  /**
   * List of values for param
   * @type {Array<string>}
   * @memberof IInventoryRequestParameterST
   */
  values: Array<string>;
}

/**
 *
 * @export
 * @interface IInventoryRequestPostRequestST
 */
export interface IInventoryRequestPostRequestST {
  /**
   *
   * @type {Array<IInventoryRequestParameter1ST>}
   * @memberof IInventoryRequestPostRequestST
   */
  params: Array<IInventoryRequestParameter1ST>;
  /**
   * A name for the report
   * @type {string}
   * @memberof IInventoryRequestPostRequestST
   */
  report_name: string;
  /**
   * ID of report specification
   * @type {string}
   * @memberof IInventoryRequestPostRequestST
   */
  report_spec_id: string;
  /**
   * Version number
   * @type {number}
   * @memberof IInventoryRequestPostRequestST
   */
  report_spec_version: number;
  /**
   * The recurrence rule of an inventory request specifies its recurrence
   * @type {string}
   * @memberof IInventoryRequestPostRequestST
   */
  rrule: string;
  /**
   * Priority of an inventory request. Higher number means higher priority.
   * @type {number}
   * @memberof IInventoryRequestPostRequestST
   */
  priority?: number;
  /**
   * Report timeout in minutes. Auto-abort will be triggered if the report isn\'t finish within the expected time. When not set, the deadline in facility settings will be used.
   * @type {number}
   * @memberof IInventoryRequestPostRequestST
   */
  timeout?: number | null;
  /**
   * Start the report immediately. When set to true, the next report will be scheduled immediately.
   * @type {boolean}
   * @memberof IInventoryRequestPostRequestST
   */
  instant_report?: boolean;
  /**
   * Type of insights to be generated by the drones
   * @type {string}
   * @memberof IInventoryRequestPostRequestST
   */
  insight_type?: IInventoryRequestPostRequestSTInsightTypeEnum;
}

export const IInventoryRequestPostRequestSTInsightTypeEnum = {
  BarcodeScanning: 'BARCODE_SCANNING',
  ItemCounting: 'ITEM_COUNTING',
} as const;

export type IInventoryRequestPostRequestSTInsightTypeEnum =
  typeof IInventoryRequestPostRequestSTInsightTypeEnum[keyof typeof IInventoryRequestPostRequestSTInsightTypeEnum];

/**
 *
 * @export
 * @interface IInventoryRequestPostResponseST
 */
export interface IInventoryRequestPostResponseST {
  /**
   * ID of created item
   * @type {string}
   * @memberof IInventoryRequestPostResponseST
   */
  item_id: string;
}
/**
 *
 * @export
 * @interface IInventoryRequestPutRequestOneOfST
 */
export interface IInventoryRequestPutRequestOneOfST {
  /**
   *
   * @type {Array<IInventoryRequestParameter1ST>}
   * @memberof IInventoryRequestPutRequestOneOfST
   */
  params: Array<IInventoryRequestParameter1ST>;
  /**
   * A name for the report
   * @type {string}
   * @memberof IInventoryRequestPutRequestOneOfST
   */
  report_name: string;
  /**
   * ID of report specification
   * @type {string}
   * @memberof IInventoryRequestPutRequestOneOfST
   */
  report_spec_id: string;
  /**
   * Version number
   * @type {number}
   * @memberof IInventoryRequestPutRequestOneOfST
   */
  report_spec_version: number;
  /**
   * The recurrence rule of an inventory request specifies its recurrence
   * @type {string}
   * @memberof IInventoryRequestPutRequestOneOfST
   */
  rrule: string;
  /**
   * Priority of an inventory request. Higher number means higher priority.
   * @type {number}
   * @memberof IInventoryRequestPutRequestOneOfST
   */
  priority?: number;
  /**
   * Report timeout in minutes. Auto-abort will be triggered if the report isn\'t finish within the expected time. When not set, the deadline in facility settings will be used.
   * @type {number}
   * @memberof IInventoryRequestPutRequestOneOfST
   */
  timeout?: number | null;
  /**
   * Start the report immediately. When set to true, the next report will be scheduled immediately.
   * @type {boolean}
   * @memberof IInventoryRequestPutRequestOneOfST
   */
  instant_report?: boolean;
  /**
   * Type of insights to be generated by the drones
   * @type {string}
   * @memberof IInventoryRequestPutRequestOneOfST
   */
  insight_type?: IInventoryRequestPutRequestOneOfSTInsightTypeEnum;
}

export const IInventoryRequestPutRequestOneOfSTInsightTypeEnum = {
  BarcodeScanning: 'BARCODE_SCANNING',
  ItemCounting: 'ITEM_COUNTING',
} as const;

export type IInventoryRequestPutRequestOneOfSTInsightTypeEnum =
  typeof IInventoryRequestPutRequestOneOfSTInsightTypeEnum[keyof typeof IInventoryRequestPutRequestOneOfSTInsightTypeEnum];

/**
 * @type IInventoryRequestPutRequestST
 * @export
 */
export type IInventoryRequestPutRequestST = IInventoryRequestPutRequestOneOfST | object;

/**
 *
 * @export
 * @interface IInventoryRequestPutResponseST
 */
export interface IInventoryRequestPutResponseST {
  /**
   * New version number
   * @type {number}
   * @memberof IInventoryRequestPutResponseST
   */
  new_version?: number;
  /**
   * Message description
   * @type {string}
   * @memberof IInventoryRequestPutResponseST
   */
  message?: string;
}
/**
 *
 * @export
 * @interface IInventoryRequestRRuleInfoGetResponseST
 */
export interface IInventoryRequestRRuleInfoGetResponseST {
  /**
   * Is the number of occurrences exceeded
   * @type {boolean}
   * @memberof IInventoryRequestRRuleInfoGetResponseST
   */
  capped: boolean;
  /**
   * Number of occurrences
   * @type {number}
   * @memberof IInventoryRequestRRuleInfoGetResponseST
   */
  count: number;
  /**
   * Detailed recurrence description
   * @type {string}
   * @memberof IInventoryRequestRRuleInfoGetResponseST
   */
  description: string;
  /**
   * Datetime of first occurrence
   * @type {string}
   * @memberof IInventoryRequestRRuleInfoGetResponseST
   */
  first_occurrence: string | null;
}
/**
 *
 * @export
 * @interface IInventoryRequestST
 */
export interface IInventoryRequestST {
  /**
   * Timestamp of when the description was created
   * @type {string}
   * @memberof IInventoryRequestST
   */
  created_at: string;
  /**
   * Next scheduling time UTC
   * @type {string}
   * @memberof IInventoryRequestST
   */
  next_scheduling_time_utc: string;
  /**
   *
   * @type {Array<IInventoryRequestParameter1ST>}
   * @memberof IInventoryRequestST
   */
  params: Array<IInventoryRequestParameter1ST>;
  /**
   * Detailed recurrence description
   * @type {string}
   * @memberof IInventoryRequestST
   */
  recurrence_description: string;
  /**
   * A name for the report
   * @type {string}
   * @memberof IInventoryRequestST
   */
  report_name: string;
  /**
   *
   * @type {IReportSpecificationST}
   * @memberof IInventoryRequestST
   */
  report_spec: IReportSpecificationST;
  /**
   * The unique identifier of the request
   * @type {string}
   * @memberof IInventoryRequestST
   */
  request_id: string;
  /**
   * The version of the request
   * @type {number}
   * @memberof IInventoryRequestST
   */
  request_version: number;
  /**
   * The name the user creating the request
   * @type {string}
   * @memberof IInventoryRequestST
   */
  requesting_user_email: string;
  /**
   * The unique identifier of the user creating the request
   * @type {string}
   * @memberof IInventoryRequestST
   */
  requesting_user_id: string;
  /**
   * The recurrence rule of an inventory request specifies its recurrence
   * @type {string}
   * @memberof IInventoryRequestST
   */
  rrule: string;
  /**
   * Inventory request state
   * @type {string}
   * @memberof IInventoryRequestST
   */
  state: IInventoryRequestSTStateEnum;
  /**
   * Priority of an inventory request. Higher number means higher priority.
   * @type {number}
   * @memberof IInventoryRequestST
   */
  priority: number;
  /**
   * Report timeout in minutes. Auto-abort will be triggered if the report isn\'t finish within the expected time. When not set, the deadline in facility settings will be used.
   * @type {number}
   * @memberof IInventoryRequestST
   */
  timeout: number | null;
  /**
   * Type of insights to be generated by the drones
   * @type {string}
   * @memberof IInventoryRequestST
   */
  insight_type?: IInventoryRequestSTInsightTypeEnum;
}

export const IInventoryRequestSTStateEnum = {
  Enabled: 'ENABLED',
  Expired: 'EXPIRED',
  Disabled: 'DISABLED',
} as const;

export type IInventoryRequestSTStateEnum =
  typeof IInventoryRequestSTStateEnum[keyof typeof IInventoryRequestSTStateEnum];
export const IInventoryRequestSTInsightTypeEnum = {
  BarcodeScanning: 'BARCODE_SCANNING',
  ItemCounting: 'ITEM_COUNTING',
} as const;

export type IInventoryRequestSTInsightTypeEnum =
  typeof IInventoryRequestSTInsightTypeEnum[keyof typeof IInventoryRequestSTInsightTypeEnum];

/**
 *
 * @export
 * @interface IReportParameterCustomValuesInputSettingsST
 */
export interface IReportParameterCustomValuesInputSettingsST {
  /**
   * Value lower bound
   * @type {number}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  lower_bound: number | null;
  /**
   * Value upper bound
   * @type {number}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  upper_bound: number | null;
  /**
   * A regex for cases in which something more strict than upper and lower bounds is needed
   * @type {string}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  allowed_values_regex: string;
  /**
   *
   * @type {IUiWidgetTypeST}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  ui_widget_type: IUiWidgetTypeST;
  /**
   * The label to be displayed next to the widget
   * @type {string}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  ui_widget_label: string;
  /**
   * List of accepted values to be shown to the user as hint
   * @type {Array<string>}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  accepted_values: Array<string>;
}

/**
 * @type IReportParameterInputSettingsST
 * @export
 */
export type IReportParameterInputSettingsST =
  | IReportParameterCustomValuesInputSettingsST
  | IReportParameterPredefinedValuesInputSettingsST;

/**
 *
 * @export
 * @interface IReportParameterPredefinedValuesInputSettingsOptionsST
 */
export interface IReportParameterPredefinedValuesInputSettingsOptionsST {
  /**
   * Name for option value
   * @type {string}
   * @memberof IReportParameterPredefinedValuesInputSettingsOptionsST
   */
  label: string;
  /**
   * Value for option
   * @type {string}
   * @memberof IReportParameterPredefinedValuesInputSettingsOptionsST
   */
  value: string;
}
/**
 *
 * @export
 * @interface IReportParameterPredefinedValuesInputSettingsST
 */
export interface IReportParameterPredefinedValuesInputSettingsST {
  /**
   *
   * @type {Array<IReportParameterPredefinedValuesInputSettingsOptionsST>}
   * @memberof IReportParameterPredefinedValuesInputSettingsST
   */
  options: Array<IReportParameterPredefinedValuesInputSettingsOptionsST>;
  /**
   *
   * @type {IUiWidgetTypeST}
   * @memberof IReportParameterPredefinedValuesInputSettingsST
   */
  ui_widget_type: IUiWidgetTypeST;
  /**
   * The label to be displayed next to the widget
   * @type {string}
   * @memberof IReportParameterPredefinedValuesInputSettingsST
   */
  ui_widget_label: string;
  /**
   *
   * @type {IReportParameterCustomValuesInputSettingsST}
   * @memberof IReportParameterPredefinedValuesInputSettingsST
   */
  custom_values_input_settings?: IReportParameterCustomValuesInputSettingsST;
}

/**
 *
 * @export
 * @interface IReportParameterST
 */
export interface IReportParameterST {
  /**
   *
   * @type {IReportParameterUniqueNameST}
   * @memberof IReportParameterST
   */
  unique_name: IReportParameterUniqueNameST;
  /**
   * The name of the parameter shown to the user
   * @type {string}
   * @memberof IReportParameterST
   */
  user_facing_name: string;
  /**
   * Type for the parameter value
   * @type {string}
   * @memberof IReportParameterST
   */
  value_type: IReportParameterSTValueTypeEnum;
  /**
   * If set to false, the parameter is ignored in the query
   * @type {boolean}
   * @memberof IReportParameterST
   */
  enabled: boolean;
  /**
   * If set to false, the parameter is shown to the user but is not editable
   * @type {boolean}
   * @memberof IReportParameterST
   */
  editable: boolean;
  /**
   * If set to false, the parameter is not shown to the user
   * @type {boolean}
   * @memberof IReportParameterST
   */
  visible: boolean;
  /**
   * Defines how the user can select the values for this parameter
   * @type {string}
   * @memberof IReportParameterST
   */
  input_mode: IReportParameterSTInputModeEnum;
  /**
   * Minimum number of values for this parameter
   * @type {number}
   * @memberof IReportParameterST
   */
  cardinality_min: number;
  /**
   * Maximum number of values for this parameter
   * @type {number}
   * @memberof IReportParameterST
   */
  cardinality_max: number;
  /**
   * Default values for this parameter
   * @type {Array<string>}
   * @memberof IReportParameterST
   */
  default_values: Array<string>;
  /**
   *
   * @type {IReportParameterInputSettingsST}
   * @memberof IReportParameterST
   */
  input_settings?: IReportParameterInputSettingsST;
}

export const IReportParameterSTValueTypeEnum = {
  Integer: 'INTEGER',
  Decimal: 'DECIMAL',
  String: 'STRING',
  Date: 'DATE',
  Time: 'TIME',
  DateTime: 'DATE_TIME',
} as const;

export type IReportParameterSTValueTypeEnum =
  typeof IReportParameterSTValueTypeEnum[keyof typeof IReportParameterSTValueTypeEnum];
export const IReportParameterSTInputModeEnum = {
  FixedValue: 'FIXED_VALUE',
  CustomValues: 'CUSTOM_VALUES',
  PredefinedValues: 'PREDEFINED_VALUES',
  Automatic: 'AUTOMATIC',
} as const;

export type IReportParameterSTInputModeEnum =
  typeof IReportParameterSTInputModeEnum[keyof typeof IReportParameterSTInputModeEnum];

/**
 * Unique name of the parameter
 * @export
 * @enum {string}
 */

export const IReportParameterUniqueNameST = {
  LocationLabel: 'LOCATION_LABEL',
  PalletNumber: 'PALLET_NUMBER',
  ArticleNumber: 'ARTICLE_NUMBER',
  Customer: 'CUSTOMER',
  WmsStatusAge: 'WMS_STATUS_AGE',
  NewIssueAge: 'NEW_ISSUE_AGE',
  LocationsPercentage: 'LOCATIONS_PERCENTAGE',
  LocationLabelType: 'LOCATION_LABEL_TYPE',
} as const;

export type IReportParameterUniqueNameST =
  typeof IReportParameterUniqueNameST[keyof typeof IReportParameterUniqueNameST];

/**
 *
 * @export
 * @interface IReportSchedulingParametersST
 */
export interface IReportSchedulingParametersST {
  /**
   * Specifies the source that triggers the inventory request for this report
   * @type {string}
   * @memberof IReportSchedulingParametersST
   */
  triggering_source: IReportSchedulingParametersSTTriggeringSourceEnum;
  /**
   * Specifies what kind of event can trigger an inventory request for this report
   * @type {Array<string>}
   * @memberof IReportSchedulingParametersST
   */
  triggering_events?: Array<IReportSchedulingParametersSTTriggeringEventsEnum>;
  /**
   * If set to true, the task can be recurrent
   * @type {boolean}
   * @memberof IReportSchedulingParametersST
   */
  allows_recurrence: boolean;
}

export const IReportSchedulingParametersSTTriggeringSourceEnum = {
  TimeBasedSchedule: 'TIME_BASED_SCHEDULE',
  ExternalEvent: 'EXTERNAL_EVENT',
} as const;

export type IReportSchedulingParametersSTTriggeringSourceEnum =
  typeof IReportSchedulingParametersSTTriggeringSourceEnum[keyof typeof IReportSchedulingParametersSTTriggeringSourceEnum];
export const IReportSchedulingParametersSTTriggeringEventsEnum = {
  None: 'NONE',
  WmsSnapshotUpload: 'WMS_SNAPSHOT_UPLOAD',
  WmsMovedLocationUpload: 'WMS_MOVED_LOCATION_UPLOAD',
  WmsStockCheckListUpload: 'WMS_STOCK_CHECK_LIST_UPLOAD',
} as const;

export type IReportSchedulingParametersSTTriggeringEventsEnum =
  typeof IReportSchedulingParametersSTTriggeringEventsEnum[keyof typeof IReportSchedulingParametersSTTriggeringEventsEnum];

/**
 *
 * @export
 * @interface IReportSpecificationBaseST
 */
export interface IReportSpecificationBaseST {
  /**
   * Report specification ID
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  id?: string;
  /**
   * Report specification version
   * @type {number}
   * @memberof IReportSpecificationBaseST
   */
  version?: number;
  /**
   * Timestamp of when the report specification was created
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  created_at?: string;
  /**
   * Name of the the report type
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_spec_name: string;
  /**
   * Default name for the inventory request generated for this report specification
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  request_default_name: string;
  /**
   * Description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_description: string;
  /**
   * Short description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_short_description: string;
  /**
   * Path of the picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_picture: string;
  /**
   * Path of the thumbnail picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_thumbnail_picture: string;
  /**
   *
   * @type {IReportSchedulingParametersST}
   * @memberof IReportSpecificationBaseST
   */
  scheduling: IReportSchedulingParametersST;
  /**
   * Defines what slots should be considered expired
   * @type {number}
   * @memberof IReportSpecificationBaseST
   */
  max_slot_status_age_sec: number;
  /**
   * Defines the list of user groups that have access to this report specification
   * @type {Array<string>}
   * @memberof IReportSpecificationBaseST
   */
  allowed_user_groups: Array<IReportSpecificationBaseSTAllowedUserGroupsEnum>;
  /**
   *
   * @type {Array<IReportParameterST>}
   * @memberof IReportSpecificationBaseST
   */
  params: Array<IReportParameterST>;
}

export const IReportSpecificationBaseSTAllowedUserGroupsEnum = {
  InventoryManager: 'InventoryManager',
  SuperAdmin: 'SuperAdmin',
  GroundControlOfficer: 'GroundControlOfficer',
  SafetyControls: 'SafetyControls',
  Analytics: 'Analytics',
  VerityUser: 'VerityUser',
  Reviewer: 'Reviewer',
  Client: 'Client',
  Verity: 'Verity',
  GroundControl: 'GroundControl',
} as const;

export type IReportSpecificationBaseSTAllowedUserGroupsEnum =
  typeof IReportSpecificationBaseSTAllowedUserGroupsEnum[keyof typeof IReportSpecificationBaseSTAllowedUserGroupsEnum];

/**
 *
 * @export
 * @interface IReportSpecificationST
 */
export interface IReportSpecificationST {
  /**
   * Report specification ID
   * @type {string}
   * @memberof IReportSpecificationST
   */
  id: string;
  /**
   * Report specification version
   * @type {number}
   * @memberof IReportSpecificationST
   */
  version: number;
  /**
   * Timestamp of when the report specification was created
   * @type {string}
   * @memberof IReportSpecificationST
   */
  created_at: string;
  /**
   * Name of the the report type
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_spec_name: string;
  /**
   * Default name for the inventory request generated for this report specification
   * @type {string}
   * @memberof IReportSpecificationST
   */
  request_default_name: string;
  /**
   * Description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_description: string;
  /**
   * Short description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_short_description: string;
  /**
   * Path of the picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_picture: string;
  /**
   * Path of the thumbnail picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_thumbnail_picture: string;
  /**
   *
   * @type {IReportSchedulingParametersST}
   * @memberof IReportSpecificationST
   */
  scheduling: IReportSchedulingParametersST;
  /**
   * Defines what slots should be considered expired
   * @type {number}
   * @memberof IReportSpecificationST
   */
  max_slot_status_age_sec: number;
  /**
   * Defines the list of user groups that have access to this report specification
   * @type {Array<string>}
   * @memberof IReportSpecificationST
   */
  allowed_user_groups: Array<IReportSpecificationSTAllowedUserGroupsEnum>;
  /**
   *
   * @type {Array<IReportParameterST>}
   * @memberof IReportSpecificationST
   */
  params: Array<IReportParameterST>;
}

export const IReportSpecificationSTAllowedUserGroupsEnum = {
  InventoryManager: 'InventoryManager',
  SuperAdmin: 'SuperAdmin',
  GroundControlOfficer: 'GroundControlOfficer',
  SafetyControls: 'SafetyControls',
  Analytics: 'Analytics',
  VerityUser: 'VerityUser',
  Reviewer: 'Reviewer',
  Client: 'Client',
  Verity: 'Verity',
  GroundControl: 'GroundControl',
} as const;

export type IReportSpecificationSTAllowedUserGroupsEnum =
  typeof IReportSpecificationSTAllowedUserGroupsEnum[keyof typeof IReportSpecificationSTAllowedUserGroupsEnum];

/**
 * Widget that should be used to show and select them
 * @export
 * @enum {string}
 */

export const IUiWidgetTypeST = {
  TextField: 'TEXT_FIELD',
  DropDown: 'DROP_DOWN',
  RadioBox: 'RADIO_BOX',
  CheckBox: 'CHECK_BOX',
  FileChooser: 'FILE_CHOOSER',
  DatePicker: 'DATE_PICKER',
  DatetimePicker: 'DATETIME_PICKER',
  Custom: 'CUSTOM',
} as const;

export type IUiWidgetTypeST = typeof IUiWidgetTypeST[keyof typeof IUiWidgetTypeST];

/**
 * InventoryRequestApi - axios parameter creator
 * @export
 */
export const InventoryRequestApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create inventory request.
     * @summary Create inventory request.
     * @param {string} systemIdStr
     * @param {IInventoryRequestPostRequestST} iInventoryRequestPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInventoryRequest: async (
      systemIdStr: string,
      iInventoryRequestPostRequestST: IInventoryRequestPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('createInventoryRequest', 'systemIdStr', systemIdStr);
      // verify required parameter 'iInventoryRequestPostRequestST' is not null or undefined
      assertParamExists(
        'createInventoryRequest',
        'iInventoryRequestPostRequestST',
        iInventoryRequestPostRequestST,
      );
      const localVarPath = `/{system_id_str}/inventory-request`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iInventoryRequestPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove inventory request.
     * @summary Remove inventory request.
     * @param {string} systemIdStr
     * @param {string} inventoryRequestId Inventory request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInventoryRequest: async (
      systemIdStr: string,
      inventoryRequestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('deleteInventoryRequest', 'systemIdStr', systemIdStr);
      // verify required parameter 'inventoryRequestId' is not null or undefined
      assertParamExists('deleteInventoryRequest', 'inventoryRequestId', inventoryRequestId);
      const localVarPath = `/{system_id_str}/inventory-request/{inventory_request_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'inventory_request_id'}}`, encodeURIComponent(String(inventoryRequestId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update inventory request.
     * @summary Update inventory request.
     * @param {string} systemIdStr
     * @param {string} inventoryRequestId Inventory request ID
     * @param {IInventoryRequestPutRequestST} iInventoryRequestPutRequestST
     * @param {'true' | 'false'} [enable] Enable/disable inventory request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editInventoryRequest: async (
      systemIdStr: string,
      inventoryRequestId: string,
      iInventoryRequestPutRequestST: IInventoryRequestPutRequestST,
      enable?: 'true' | 'false',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('editInventoryRequest', 'systemIdStr', systemIdStr);
      // verify required parameter 'inventoryRequestId' is not null or undefined
      assertParamExists('editInventoryRequest', 'inventoryRequestId', inventoryRequestId);
      // verify required parameter 'iInventoryRequestPutRequestST' is not null or undefined
      assertParamExists(
        'editInventoryRequest',
        'iInventoryRequestPutRequestST',
        iInventoryRequestPutRequestST,
      );
      const localVarPath = `/{system_id_str}/inventory-request/{inventory_request_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'inventory_request_id'}}`, encodeURIComponent(String(inventoryRequestId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (enable !== undefined) {
        localVarQueryParameter['enable'] = enable;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iInventoryRequestPutRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return all inventory requests.
     * @summary Return all inventory requests.
     * @param {string} systemIdStr
     * @param {Array<string>} [states] Inventory request states
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInventoryRequests: async (
      systemIdStr: string,
      states?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getAllInventoryRequests', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/inventory-request`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (states) {
        localVarQueryParameter['states'] = states;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return inventory request.
     * @summary Return inventory request.
     * @param {string} systemIdStr
     * @param {string} inventoryRequestId Inventory request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInventoryRequest: async (
      systemIdStr: string,
      inventoryRequestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getInventoryRequest', 'systemIdStr', systemIdStr);
      // verify required parameter 'inventoryRequestId' is not null or undefined
      assertParamExists('getInventoryRequest', 'inventoryRequestId', inventoryRequestId);
      const localVarPath = `/{system_id_str}/inventory-request/{inventory_request_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'inventory_request_id'}}`, encodeURIComponent(String(inventoryRequestId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns next occurrence.
     * @summary Return next occurrence.
     * @param {string} systemIdStr
     * @param {string} rrule The recurrence rule of an inventory request specifies its recurrence
     * @param {string} [after] The datetime which specifies the timepoint after which the first occurrence should be computed
     * @param {boolean} [include] Specifies if after itself should be return if it is a valid occurrence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNextOccurrence: async (
      systemIdStr: string,
      rrule: string,
      after?: string,
      include?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getNextOccurrence', 'systemIdStr', systemIdStr);
      // verify required parameter 'rrule' is not null or undefined
      assertParamExists('getNextOccurrence', 'rrule', rrule);
      const localVarPath = `/{system_id_str}/inventory-request/next-occurrence`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (rrule !== undefined) {
        localVarQueryParameter['rrule'] = rrule;
      }

      if (after !== undefined) {
        localVarQueryParameter['after'] =
          (after as any) instanceof Date ? (after as any).toISOString() : after;
      }

      if (include !== undefined) {
        localVarQueryParameter['include'] = include;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Parse rrule string and return information about recurrence.
     * @summary Return rrule info.
     * @param {string} systemIdStr
     * @param {string} rrule The recurrence rule of an inventory request specifies its recurrence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRRuleInfo: async (
      systemIdStr: string,
      rrule: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getRRuleInfo', 'systemIdStr', systemIdStr);
      // verify required parameter 'rrule' is not null or undefined
      assertParamExists('getRRuleInfo', 'rrule', rrule);
      const localVarPath = `/{system_id_str}/inventory-request/rrule-info`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (rrule !== undefined) {
        localVarQueryParameter['rrule'] = rrule;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InventoryRequestApi - functional programming interface
 * @export
 */
export const InventoryRequestApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InventoryRequestApiAxiosParamCreator(configuration);
  return {
    /**
     * Create inventory request.
     * @summary Create inventory request.
     * @param {string} systemIdStr
     * @param {IInventoryRequestPostRequestST} iInventoryRequestPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createInventoryRequest(
      systemIdStr: string,
      iInventoryRequestPostRequestST: IInventoryRequestPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInventoryRequestPostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createInventoryRequest(
        systemIdStr,
        iInventoryRequestPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Remove inventory request.
     * @summary Remove inventory request.
     * @param {string} systemIdStr
     * @param {string} inventoryRequestId Inventory request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteInventoryRequest(
      systemIdStr: string,
      inventoryRequestId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInventoryRequest(
        systemIdStr,
        inventoryRequestId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update inventory request.
     * @summary Update inventory request.
     * @param {string} systemIdStr
     * @param {string} inventoryRequestId Inventory request ID
     * @param {IInventoryRequestPutRequestST} iInventoryRequestPutRequestST
     * @param {'true' | 'false'} [enable] Enable/disable inventory request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editInventoryRequest(
      systemIdStr: string,
      inventoryRequestId: string,
      iInventoryRequestPutRequestST: IInventoryRequestPutRequestST,
      enable?: 'true' | 'false',
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInventoryRequestPutResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editInventoryRequest(
        systemIdStr,
        inventoryRequestId,
        iInventoryRequestPutRequestST,
        enable,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return all inventory requests.
     * @summary Return all inventory requests.
     * @param {string} systemIdStr
     * @param {Array<string>} [states] Inventory request states
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllInventoryRequests(
      systemIdStr: string,
      states?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInventoryRequestGetAllResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllInventoryRequests(
        systemIdStr,
        states,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return inventory request.
     * @summary Return inventory request.
     * @param {string} systemIdStr
     * @param {string} inventoryRequestId Inventory request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInventoryRequest(
      systemIdStr: string,
      inventoryRequestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInventoryRequestGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInventoryRequest(
        systemIdStr,
        inventoryRequestId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns next occurrence.
     * @summary Return next occurrence.
     * @param {string} systemIdStr
     * @param {string} rrule The recurrence rule of an inventory request specifies its recurrence
     * @param {string} [after] The datetime which specifies the timepoint after which the first occurrence should be computed
     * @param {boolean} [include] Specifies if after itself should be return if it is a valid occurrence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNextOccurrence(
      systemIdStr: string,
      rrule: string,
      after?: string,
      include?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IInventoryRequestNextOccurrenceGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNextOccurrence(
        systemIdStr,
        rrule,
        after,
        include,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Parse rrule string and return information about recurrence.
     * @summary Return rrule info.
     * @param {string} systemIdStr
     * @param {string} rrule The recurrence rule of an inventory request specifies its recurrence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRRuleInfo(
      systemIdStr: string,
      rrule: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IInventoryRequestRRuleInfoGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRRuleInfo(
        systemIdStr,
        rrule,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InventoryRequestApi - factory interface
 * @export
 */
export const InventoryRequestApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InventoryRequestApiFp(configuration);
  return {
    /**
     * Create inventory request.
     * @summary Create inventory request.
     * @param {string} systemIdStr
     * @param {IInventoryRequestPostRequestST} iInventoryRequestPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInventoryRequest(
      systemIdStr: string,
      iInventoryRequestPostRequestST: IInventoryRequestPostRequestST,
      options?: any,
    ): AxiosPromise<IInventoryRequestPostResponseST> {
      return localVarFp
        .createInventoryRequest(systemIdStr, iInventoryRequestPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Remove inventory request.
     * @summary Remove inventory request.
     * @param {string} systemIdStr
     * @param {string} inventoryRequestId Inventory request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInventoryRequest(
      systemIdStr: string,
      inventoryRequestId: string,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .deleteInventoryRequest(systemIdStr, inventoryRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update inventory request.
     * @summary Update inventory request.
     * @param {string} systemIdStr
     * @param {string} inventoryRequestId Inventory request ID
     * @param {IInventoryRequestPutRequestST} iInventoryRequestPutRequestST
     * @param {'true' | 'false'} [enable] Enable/disable inventory request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editInventoryRequest(
      systemIdStr: string,
      inventoryRequestId: string,
      iInventoryRequestPutRequestST: IInventoryRequestPutRequestST,
      enable?: 'true' | 'false',
      options?: any,
    ): AxiosPromise<IInventoryRequestPutResponseST> {
      return localVarFp
        .editInventoryRequest(
          systemIdStr,
          inventoryRequestId,
          iInventoryRequestPutRequestST,
          enable,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Return all inventory requests.
     * @summary Return all inventory requests.
     * @param {string} systemIdStr
     * @param {Array<string>} [states] Inventory request states
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInventoryRequests(
      systemIdStr: string,
      states?: Array<string>,
      options?: any,
    ): AxiosPromise<IInventoryRequestGetAllResponseST> {
      return localVarFp
        .getAllInventoryRequests(systemIdStr, states, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return inventory request.
     * @summary Return inventory request.
     * @param {string} systemIdStr
     * @param {string} inventoryRequestId Inventory request ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInventoryRequest(
      systemIdStr: string,
      inventoryRequestId: string,
      options?: any,
    ): AxiosPromise<IInventoryRequestGetResponseST> {
      return localVarFp
        .getInventoryRequest(systemIdStr, inventoryRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns next occurrence.
     * @summary Return next occurrence.
     * @param {string} systemIdStr
     * @param {string} rrule The recurrence rule of an inventory request specifies its recurrence
     * @param {string} [after] The datetime which specifies the timepoint after which the first occurrence should be computed
     * @param {boolean} [include] Specifies if after itself should be return if it is a valid occurrence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNextOccurrence(
      systemIdStr: string,
      rrule: string,
      after?: string,
      include?: boolean,
      options?: any,
    ): AxiosPromise<IInventoryRequestNextOccurrenceGetResponseST> {
      return localVarFp
        .getNextOccurrence(systemIdStr, rrule, after, include, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Parse rrule string and return information about recurrence.
     * @summary Return rrule info.
     * @param {string} systemIdStr
     * @param {string} rrule The recurrence rule of an inventory request specifies its recurrence
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRRuleInfo(
      systemIdStr: string,
      rrule: string,
      options?: any,
    ): AxiosPromise<IInventoryRequestRRuleInfoGetResponseST> {
      return localVarFp
        .getRRuleInfo(systemIdStr, rrule, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InventoryRequestApi - object-oriented interface
 * @export
 * @class InventoryRequestApi
 * @extends {BaseAPI}
 */
export class InventoryRequestApi extends BaseAPI {
  /**
   * Create inventory request.
   * @summary Create inventory request.
   * @param {string} systemIdStr
   * @param {IInventoryRequestPostRequestST} iInventoryRequestPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryRequestApi
   */
  public createInventoryRequest(
    systemIdStr: string,
    iInventoryRequestPostRequestST: IInventoryRequestPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return InventoryRequestApiFp(this.configuration)
      .createInventoryRequest(systemIdStr, iInventoryRequestPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Remove inventory request.
   * @summary Remove inventory request.
   * @param {string} systemIdStr
   * @param {string} inventoryRequestId Inventory request ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryRequestApi
   */
  public deleteInventoryRequest(
    systemIdStr: string,
    inventoryRequestId: string,
    options?: AxiosRequestConfig,
  ) {
    return InventoryRequestApiFp(this.configuration)
      .deleteInventoryRequest(systemIdStr, inventoryRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update inventory request.
   * @summary Update inventory request.
   * @param {string} systemIdStr
   * @param {string} inventoryRequestId Inventory request ID
   * @param {IInventoryRequestPutRequestST} iInventoryRequestPutRequestST
   * @param {'true' | 'false'} [enable] Enable/disable inventory request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryRequestApi
   */
  public editInventoryRequest(
    systemIdStr: string,
    inventoryRequestId: string,
    iInventoryRequestPutRequestST: IInventoryRequestPutRequestST,
    enable?: 'true' | 'false',
    options?: AxiosRequestConfig,
  ) {
    return InventoryRequestApiFp(this.configuration)
      .editInventoryRequest(
        systemIdStr,
        inventoryRequestId,
        iInventoryRequestPutRequestST,
        enable,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return all inventory requests.
   * @summary Return all inventory requests.
   * @param {string} systemIdStr
   * @param {Array<string>} [states] Inventory request states
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryRequestApi
   */
  public getAllInventoryRequests(
    systemIdStr: string,
    states?: Array<string>,
    options?: AxiosRequestConfig,
  ) {
    return InventoryRequestApiFp(this.configuration)
      .getAllInventoryRequests(systemIdStr, states, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return inventory request.
   * @summary Return inventory request.
   * @param {string} systemIdStr
   * @param {string} inventoryRequestId Inventory request ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryRequestApi
   */
  public getInventoryRequest(
    systemIdStr: string,
    inventoryRequestId: string,
    options?: AxiosRequestConfig,
  ) {
    return InventoryRequestApiFp(this.configuration)
      .getInventoryRequest(systemIdStr, inventoryRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns next occurrence.
   * @summary Return next occurrence.
   * @param {string} systemIdStr
   * @param {string} rrule The recurrence rule of an inventory request specifies its recurrence
   * @param {string} [after] The datetime which specifies the timepoint after which the first occurrence should be computed
   * @param {boolean} [include] Specifies if after itself should be return if it is a valid occurrence
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryRequestApi
   */
  public getNextOccurrence(
    systemIdStr: string,
    rrule: string,
    after?: string,
    include?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return InventoryRequestApiFp(this.configuration)
      .getNextOccurrence(systemIdStr, rrule, after, include, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Parse rrule string and return information about recurrence.
   * @summary Return rrule info.
   * @param {string} systemIdStr
   * @param {string} rrule The recurrence rule of an inventory request specifies its recurrence
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InventoryRequestApi
   */
  public getRRuleInfo(systemIdStr: string, rrule: string, options?: AxiosRequestConfig) {
    return InventoryRequestApiFp(this.configuration)
      .getRRuleInfo(systemIdStr, rrule, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
