import { capitalize } from 'lodash';
import { IVeritySlotStatusStateST, IWMSSlotStatusSTStateEnum } from 'codegen/location_information';

export const timelineMapState = (
  state: IVeritySlotStatusStateST | IWMSSlotStatusSTStateEnum,
): string => {
  switch (state) {
    case 'NOT_EMPTY':
      return 'No barcode';
    case 'NO_RESULT':
      return 'Inconclusive';
    default:
      return capitalize(state);
  }
};
