import { DroneZoneTypes } from 'shared/map/model/drone-zones.model';

export const zoneTypeProps: Record<
  keyof typeof DroneZoneTypes,
  { title: string; nameLabel: string; isHeightEnabled: boolean }
> = {
  controlledZone: {
    title: 'Controlled zone details',
    nameLabel: 'Controlled zone name',
    isHeightEnabled: false,
  },
  noFlyZone: {
    title: 'No-fly zone details',
    nameLabel: 'No-fly zone name',
    isHeightEnabled: true,
  },
  openFlyZone: {
    title: 'Open fly zone details',
    nameLabel: 'Open fly zone name',
    isHeightEnabled: true,
  },
};

export const zoneTypeValidationExceptions: Record<keyof typeof DroneZoneTypes, string[]> = {
  controlledZone: ['h'],
  noFlyZone: ['maxZ', 'minZ'],
  openFlyZone: [],
};
