import { Dispatch } from 'react';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { getAmendingRowsData, sortRows } from 'common/Tables/tableFunctions';
import { OrderValue } from 'components/EnhancedTable/types/rows';
import { IFacilityModalsAction } from 'store/Modals/facilityModals/IFacilityModalsActions';
import { FacilityModalsActionTypes } from 'store/Modals/types';
import { LocationReportData } from 'udb/inventory/models/LocationReportData';

const logPrefix = getLogPrefixForType('FUNCTION', 'handleAmendModalOpening');

type HandleAmendModal = {
  amendLocationName: string;
  isAmendModalOpened: boolean;
  rows: LocationReportData[];
  dispatchFacilityModals: Dispatch<IFacilityModalsAction>;
  refreshData: (reportId: string, nLocationsPerRequest: number) => void;
  orderBy: keyof LocationReportData;
  order: OrderValue;
  reportId?: string;
};

/**
 * This function is called to open amend modal if amendLocation param is present in url
 * @param params HandleAmendModal
 */
export const handleAmendModalOpening = ({
  amendLocationName,
  isAmendModalOpened,
  rows,
  dispatchFacilityModals,
  refreshData,
  orderBy,
  order,
  reportId,
}: HandleAmendModal) => {
  const row = rows.find((row) => row.location === amendLocationName);

  if (amendLocationName && !isAmendModalOpened) {
    console.debug(
      logPrefix,
      'useEffect =>',
      `opening Amending modal for location: ${amendLocationName}, from url params`,
    );

    if (!row) {
      console.debug(
        logPrefix,
        'useEffect =>',
        `Amending location: ${amendLocationName}, from url params, was not found`,
      );
    }

    // used to open amend dialog on reports to review
    dispatchFacilityModals({
      type: FacilityModalsActionTypes.AMEND_VERITY_VALUE,
      payload: getAmendingRowsData(sortRows(rows, order, orderBy), row, reportId),
      refreshData,
      tableAmending: true,
    });
  }
};
