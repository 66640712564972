import { useQuery } from '@tanstack/react-query';
import { Fragment } from 'react';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import {
  BarcodeMatchLogic,
  getBarcodeMuiColor,
} from 'common/functions/barcodes/getBarcodeMuiColor.util';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { QueryNames } from '../../../../../ts-types/QueryNames';
import { getLocationMetadata } from './api/getImageMetadata';
import { BarcodeFrame } from './BarcodeFrame';
import { BarcodeLabel } from './BarcodeLabel';
import { getBarcodeListFromMetadataResponse } from './utils/getBarcodeListFromMetadataResponse';
import { transformBarcodeRows } from '../barcodes/utils/transformBarcodeRows.util';
import { getIsVerityDataStale } from '../../utils/getIsVerityDataStale';

export const BarcodeVisualization = ({
  systemId,
  locationData,
  imageURL,
  highlightedBarcode,
  issueLogic,
  barcodeMatchLogic,
  setHighlightedBarcode,
}: {
  systemId: string;
  locationData: ILocationData;
  imageURL: string;
  highlightedBarcode: string;
  issueLogic: IRuleActionSTIssueLogicEnum | undefined;
  barcodeMatchLogic: BarcodeMatchLogic | undefined;
  setHighlightedBarcode: React.Dispatch<React.SetStateAction<string>>;
}) => {
  // Split imageURL on '/' and '?', and take the element that comes after "image" to get image GUID
  const urlParts = imageURL.split(/[/?]/);
  const imageGuid = urlParts[urlParts.indexOf('image') + 1];
  const { location, slotStatusVersion, isReview } = locationData;

  const transformedBarcodes = transformBarcodeRows(locationData, issueLogic);
  const isStale = getIsVerityDataStale(locationData.rowData);

  const { data: barcodesMetadata } = useQuery({
    queryKey: [
      QueryNames.LOCATION_MODAL_GET_LOCATION_METADATA,
      `${systemId}_${location}_${slotStatusVersion}_${isReview}`,
    ],
    queryFn: () => getLocationMetadata(systemId, location, slotStatusVersion, isReview),

    select: ({ data: resultMetadataResponse }) =>
      getBarcodeListFromMetadataResponse({ resultMetadataResponse, imageGuid })?.map((barcode) => ({
        ...barcode,
        id: crypto.randomUUID(),
      })),
  });
  if (barcodesMetadata) {
    return (
      <>
        {barcodesMetadata.map((metadata) => {
          const filteredBarcodes = transformedBarcodes.filter(
            (b) => b.contentFound === metadata.udb_value,
          );
          const color = getBarcodeMuiColor(
            filteredBarcodes[0]?.barcodeStatus,
            barcodeMatchLogic,
            issueLogic,
          );
          return (
            <Fragment key={metadata.id}>
              <BarcodeFrame
                barcodeMetadata={metadata}
                color={color}
                isStale={isStale}
                highlightedBarcode={highlightedBarcode}
                setHighlightedBarcode={setHighlightedBarcode}
              />
              <BarcodeLabel barcodeMetadata={metadata} color={color} isStale={isStale} />
            </Fragment>
          );
        })}
      </>
    );
  }
  return null;
};
