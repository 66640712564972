import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { BarcodeRow, BarcodeStatusEnum } from '../models/BarcodeRow.model';
import { makeBarcodeArray } from './makeBarcodeArray';
import { getVerityValuesAppliedByIssueLogic } from '../../../../../../common/functions/locations/getVerityValuesAppliedByIssueLogic';

export const transformBarcodeRows = (
  locationData: ILocationData,
  issueLogic: IRuleActionSTIssueLogicEnum | undefined,
): BarcodeRow[] => {
  const { contentExpected } = locationData.rowData;

  let nonBarcodes: BarcodeRow[] = [];
  const { userOverride } = locationData;

  const { verityState, contentFound } = getVerityValuesAppliedByIssueLogic(
    locationData,
    issueLogic,
  );

  if (
    locationData.wmsState !== 'BARCODE' &&
    verityState !== 'BARCODE' &&
    contentExpected[0] === contentFound[0]
  ) {
    // Note: this is the case where there are no barcodes (neither expected nor found) and
    // the location has a match (e.g. expected empty and found empty). As this is Facility
    // level information we might want to move it out of the table at some point, but for
    // the time being it is still here.
    // eno 2024-08-24
    return [
      {
        id: nonBarcodes.length,
        locationData,
        expectedContent: contentExpected[0],
        contentFound: userOverride ? `${contentFound[0]} *` : contentFound[0],
        barcodeStatus: BarcodeStatusEnum.None,
        foundAt: '',
        shouldBeAt: '',
        wmsQuantity: '',
        articleNumber: '',
        customer: '',
      },
    ];
  }

  if (locationData.wmsState !== 'BARCODE') {
    nonBarcodes.push({
      id: nonBarcodes.length,
      locationData,
      expectedContent: contentExpected[0],
      contentFound: '',
      barcodeStatus: BarcodeStatusEnum.None,
      foundAt: '',
      shouldBeAt: '',
      wmsQuantity: '',
      articleNumber: '',
      customer: '',
    });
  }

  if (verityState !== 'BARCODE') {
    nonBarcodes.push({
      id: nonBarcodes.length,
      locationData,
      expectedContent: '',
      contentFound: userOverride ? `${contentFound[0]} *` : contentFound[0],
      barcodeStatus: BarcodeStatusEnum.None,
      foundAt: '',
      shouldBeAt: '',
      wmsQuantity: '',
      articleNumber: '',
      customer: '',
    });
  }

  const barcodes = makeBarcodeArray({
    locationData,
    expectedBarcodes: locationData.wmsState === 'BARCODE' ? contentExpected : [],
    foundBarcodes: verityState === 'BARCODE' ? (contentFound as string[]) : [],
  });

  const priority = {
    [BarcodeStatusEnum.None]: 0,
    [BarcodeStatusEnum.Missing]: 1,
    [BarcodeStatusEnum.Unexpected]: 2,
    [BarcodeStatusEnum.Match]: 3,
  };

  let sortedBarcode = barcodes.sort(
    (a, b) => priority[a.barcodeStatus] - priority[b.barcodeStatus],
  );

  if (userOverride) {
    nonBarcodes = nonBarcodes.map((res) => ({
      ...res,
      contentFound: res.contentFound ? `${res.contentFound} *` : '',
    }));
    sortedBarcode = sortedBarcode.map((res) => ({
      ...res,
      contentFound: res.contentFound ? `${res.contentFound} *` : '',
    }));
  }

  return [...nonBarcodes, ...sortedBarcode];
};

// R13-076-C02
