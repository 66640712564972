import { Typography } from '@mui/material';
import { Canvas } from '@react-three/fiber';
import { ReactNode } from 'react';
import { Color, Object3D, Vector3 } from 'three';

Object3D.DEFAULT_UP = new Vector3(0, 0, 1);
const backgroundColor = new Color('#FAFAFA');

export const Map3DCanvas = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <Canvas
    className={className}
    gl={{
      useLegacyLights: true,
    }}
    scene={{ background: backgroundColor }}
    fallback={<Typography>Sorry couldn't display the 3d view right now</Typography>}
    orthographic
    camera={{
      near: -1000,
      far: 10000,
    }}
  >
    <ambientLight intensity={1} />
    {children}
  </Canvas>
);
