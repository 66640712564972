import { Dispatch, useEffect } from 'react';
import { LicenseInfo } from '@mui/x-license';
import { Routes, Route, useLocation } from 'react-router-dom';
import activeRequests from 'active-requests';
import { SignIn } from 'udb/sign-in/SignIn';
import { resetStoreState } from 'pages/Auth/utils/resetStoreState.util';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { useUserLevelStore } from 'store/UserLevelStore/userLevelStore';
import { useClientLevelStore } from 'store/ClientLevelStore/clientLevelStore';
import { useClientModalsStore, useFacilityModalsStore } from 'store/Modals';
import { muiXLicense } from 'MuiXLicense';
import { ClientLevelAction } from 'store/ClientLevelStore/ClientLevelStore.model';
import { configureTextBuilder } from 'troika-three-text';
import { ModalsAndPopupsClientLevel } from './components/ModalsAndPopups/ModalsAndPopupsClientLevel';
import { ModalsAndPopupsFacilityLevel } from './components/ModalsAndPopups/ModalsAndPopupsFacilityLevel';
import { ModalsAndPopupsGroundControlLevel } from './components/ModalsAndPopups/ModalsAndPopupGroundControlLevel';
import { AUTH_PAGES_URLS } from './common/pages';
import './App.css';
import { ClientRoutes } from './routes/ClientRoutes';
import { AuthRoutes } from './routes/AuthRoutes';
import { AuthPage } from './pages/Auth/AuthPage';
import { LocalStore } from './common/functions/storageFunctions';
import { useForceUpdate } from './hooks/useForceUpdate';
import { TokenManager } from './common/tokenManager';
import { WebSocketComponent } from './components/WebSocket/WebSocket';
import { WebSocketClient } from './shared/web-socket/web-socket.model';
import { webSocket } from './shared/web-socket/web-socket';
import { initSentry } from './common/sentry';

const tokenManager = TokenManager.getInstance();

configureTextBuilder({
  useWorker: false,
});

LicenseInfo.setLicenseKey(muiXLicense);
const websocket: WebSocketClient = webSocket();

export const App = () => {
  const location = useLocation();

  const forceUpdate = useForceUpdate('App');
  LocalStore.setTriggerUpdate(forceUpdate);
  const accessToken = tokenManager.getAccessToken();

  activeRequests.start();

  const deprecatedWebSocket = !tokenManager.userHaveAccess() ? null : <WebSocketComponent />;

  const { dispatchFacilityLevel } = useFacilityLevelStore();
  const { dispatchUserLevelStore } = useUserLevelStore();
  const { dispatchClientLevel } = useClientLevelStore();
  const { dispatchFacilityModals } = useFacilityModalsStore();
  const { dispatchClientModals } = useClientModalsStore();

  useEffect(() => {
    initSentry();
    if (accessToken) {
      websocket.connect();
    } else {
      resetStoreState(
        dispatchFacilityLevel,
        dispatchClientLevel as Dispatch<ClientLevelAction>,
        dispatchUserLevelStore,
        dispatchFacilityModals,
        dispatchClientModals,
      );
      websocket?.disconnect();
    }
    return () => {
      websocket.disconnect();
    };
  }, [
    accessToken,
    dispatchClientLevel,
    dispatchClientModals,
    dispatchFacilityLevel,
    dispatchFacilityModals,
    dispatchUserLevelStore,
  ]);

  return (
    <div className="App">
      <Routes location={location} key={location.pathname}>
        {/* Authentication pages */}
        <Route path={`${AUTH_PAGES_URLS.ROOT}/*`} element={<AuthRoutes />} />
        {/* Client pages */}
        <Route
          path="/*"
          element={
            accessToken ? (
              <AuthPage>
                <ClientRoutes />
              </AuthPage>
            ) : (
              <SignIn />
            )
          }
        />
      </Routes>

      <ModalsAndPopupsGroundControlLevel />
      <ModalsAndPopupsFacilityLevel />
      <ModalsAndPopupsClientLevel />
      {deprecatedWebSocket}
    </div>
  );
};
