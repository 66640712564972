import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

/**
 * Enum for upload file types
 */
export enum UPLOADED_FILES_TYPES {
  WMSSnapshot = 'WMSSnapshot',
  StockCheck = 'StockCheck',
  MovedLocations = 'MovedLocations',
}

/**
 * File Info Interface, Server type
 */
export interface IFileInfoST {
  wms_parser_output_url: string;
  client_file_url: string;
  client_file_name: string;
  total_locations: number;
  updated_locations: number;
  last_modified: moment.MomentInput;
  most_recent_entry_updated_at: moment.MomentInput;
}

/**
 * Interface with the parameters for the upload file function.
 */
export interface IUploadFileParams {
  file: File;
  setProgress: (percentage: number) => void;
  fileType: UPLOADED_FILES_TYPES;
  dispatcher?: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey;
}

/**
 * Interface for table row data
 */
export interface IFileData extends IFileInfoST {
  location_warnings: number;
  locations_with_updated_changed_at: number;
  num_chunks: number;
  processed_chunks: number;
  request_warnings: number;
  status: 'valid' | 'invalid' | 'n.a.';
  uploader: string;
  skipped_locations: number | 'n.a';
}

/**
 * Table row actions
 */
export type TRowAction = {
  label: string;
  disabled: boolean;
};

export interface IRowActions {
  data: IFileData;
  actions: TRowAction[];
}
