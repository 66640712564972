import { zoneCoordinatesToGeometry } from 'shared/map-container/utils/creation.util';
import { ControlledZone } from 'shared/map/model/controlled-zone.model';
import { ControlledZoneAPI } from '../../../../api/controlled-zone';

export const createControlledZone = (
  droneZone: ControlledZone,
  systemId: string,
  flightDomainId: string,
) =>
  ControlledZoneAPI.createControlledZone({
    systemId,
    flightDomainId,
    payload: {
      name: droneZone.name,
      description: droneZone.description,
      geometry: zoneCoordinatesToGeometry(droneZone.sizeAndPosition),
    },
  });
