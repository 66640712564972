import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0px, 1fr)',
    gridAutoRows: 'min-content',
    gap: '8px',
  },
  legendIcon: {
    borderRadius: '4px',
    display: 'block',
    alignSelf: 'baseline',
    transform: 'translateY(0.25em)',
    flex: '0 0 auto',
    height: '1em',
    width: '2em',
  },
  label: {
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    display: 'flex',
    gap: theme.spacing(0.75),
    flex: '1 1 auto',
    minWidth: '0',
  },
}));
