import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from 'components/common/Box';
import { ReactElement } from 'react';

export const EnhancedBox = (props: {
  icon: ReactElement;
  description: string;
  actionButtons: {
    label: string;
    testid: string;
    variant: string;
    class: string;
    disabled: boolean;
    action: () => void;
  }[];
  classes: {
    description: string;
    actionButtons: string;
  };
  children: React.ReactNode;
}) => {
  const { icon, description, actionButtons, classes, children } = props;

  return (
    <>
      {icon}
      <Typography className={classes.description} variant="subtitle1" color="textSecondary">
        {description}
      </Typography>
      <Box>{children}</Box>
      <Box width="100%" className={classes.actionButtons} display="flex">
        {actionButtons &&
          actionButtons.map((button) => (
            <Button
              key={`${button.label}-${button.testid}`}
              data-testid={button.testid}
              className={button.class && button.class}
              variant={button.variant}
              fullWidth
              disabled={button.disabled}
              color="primary"
              onClick={button.action}
            >
              {button.label}
            </Button>
          ))}
      </Box>
    </>
  );
};
