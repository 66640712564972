import { ReactNode } from 'react';
import { Placeholder } from '../placeholder/Placeholder';
import { useStyles } from './standardPlaceholder.styles';

export const StandardPlaceholder = ({
  icon,
  children,
}: {
  icon: ReactNode;
  children: ReactNode;
}) => {
  const { classes } = useStyles();

  return (
    <Placeholder>
      <div className={classes.wrapper}>
        <div className={classes.contentWrapper}>
          <div className={classes.iconWrapper}>{icon}</div>

          <div>{children}</div>
        </div>
      </div>
    </Placeholder>
  );
};
