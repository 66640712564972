import {
  IIssueSTTypeEnum,
  ILocationDataST,
  IVeritySlotStatusST,
  IWMSSlotStatusST,
} from 'codegen/warehouse_status';

export const getIncludedExcludedValidInvalid = (locationData: ILocationDataST[]) => {
  const excluded: ILocationDataST[] = [];
  const included: ILocationDataST[] = [];
  const valid: ILocationDataST[] = [];
  const invalid: ILocationDataST[] = [];
  locationData.forEach((l) => {
    if (l.slot_settings?.exclusion_status === 'EXCLUDE') {
      excluded.push(l);
    } else {
      included.push(l);
      if ((l.wms_status as IWMSSlotStatusST)?.state !== 'INVALID') {
        valid.push(l);
      } else {
        invalid.push(l);
      }
    }
  });
  return {
    included: {
      all: included,
      valid,
      invalid,
    },
    excluded,
  };
};

export const getIssueBreakdown = (locationData: ILocationDataST[]) => {
  const active = locationData.filter(
    (l) => l.issues[0]?.state === 'NEW' || l.issues[0]?.state === 'KNOWN',
  );

  // NOTE: we're temporarily storing the locations instead of directly the count
  // so, that in case of need we can debug which locations is mapped where.
  // eno 2024-11-11
  const issues: { [key: string]: ILocationDataST[] } = {
    [IIssueSTTypeEnum.Issue1WBcVEmpty]: [],
    [IIssueSTTypeEnum.Issue2WBcNeVBc]: [],
    [IIssueSTTypeEnum.Issue3WEmptyVBc]: [],
    [IIssueSTTypeEnum.Issue4WEmptyVNotempty]: [],
    [IIssueSTTypeEnum.Issue5WBcDuplicate]: [],
  };

  active.forEach((l) => {
    if (l.issues[0]?.type === IIssueSTTypeEnum.Issue1WBcVEmpty) {
      issues[IIssueSTTypeEnum.Issue1WBcVEmpty].push(l);
    }
    if (l.issues[0]?.type === IIssueSTTypeEnum.Issue2WBcNeVBc) {
      issues[IIssueSTTypeEnum.Issue2WBcNeVBc].push(l);
    }
    if (l.issues[0]?.type === IIssueSTTypeEnum.Issue3WEmptyVBc) {
      issues[IIssueSTTypeEnum.Issue3WEmptyVBc].push(l);
    }
    if (l.issues[0]?.type === IIssueSTTypeEnum.Issue4WEmptyVNotempty) {
      issues[IIssueSTTypeEnum.Issue4WEmptyVNotempty].push(l);
    }
    if (l.issues[0]?.type === IIssueSTTypeEnum.Issue5WBcDuplicate) {
      issues[IIssueSTTypeEnum.Issue5WBcDuplicate].push(l);
    }
  });

  return {
    ISSUE1_W_BC_V_EMPTY: issues.ISSUE1_W_BC_V_EMPTY.length,
    ISSUE2_W_BC_NE_V_BC: issues.ISSUE2_W_BC_NE_V_BC.length,
    ISSUE3_W_EMPTY_V_BC: issues.ISSUE3_W_EMPTY_V_BC.length,
    ISSUE4_W_EMPTY_V_NOTEMPTY: issues.ISSUE4_W_EMPTY_V_NOTEMPTY.length,
    ISSUE5_W_BC_DUPLICATE: issues.ISSUE5_W_BC_DUPLICATE.length,
  };
};

export const getCoverage = (locationData: ILocationDataST[]) => {
  const { included, excluded } = getIncludedExcludedValidInvalid(locationData);
  const { valid, invalid } = included;

  const noWmsNoVerity = [];
  const wmsOnly = [];
  const verityOnly = [];
  const visited = [];
  const upToDate = [];
  const staleData = [];

  valid.forEach((l) => {
    if (!l.wms_status && !l.verity_status) {
      noWmsNoVerity.push(l);
    }
    if (l.wms_status && !l.verity_status) {
      wmsOnly.push(l);
    }
    if (!l.wms_status && l.verity_status) {
      verityOnly.push(l);
    }
    if (l.wms_status && l.verity_status) {
      visited.push(l);
      if (
        (l.wms_status as IWMSSlotStatusST).updated_at <=
        (l.verity_status as IVeritySlotStatusST).collected_at
      ) {
        upToDate.push(l);
      } else {
        staleData.push(l);
      }
    }
  });

  return {
    noWmsNoVerity: noWmsNoVerity.length,
    wmsOnly: wmsOnly.length,
    verityOnly: verityOnly.length,
    visited: visited.length,
    upToDate: upToDate.length,
    staleData: staleData.length,
    excluded: excluded.length,
    included: included.all.length,
    invalid: invalid.length,
  };
};
