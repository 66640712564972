import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { Box } from 'components/common/Box';
import { tableStyles } from '../styles';
import { ToolbarAction } from '../types/toolbar';

type EnhancedTableToolbarProps = {
  selectedRows: string[];
  noOfRowsFound?: number;
  searchTerm: string;
  searching: boolean;
  tabStatus: boolean;
  action?: ToolbarAction;
};

/**
 * Old Table implementation, please use DataGrid instead
 * @deprecated
 */
export const EnhancedTableToolbar = ({
  noOfRowsFound,
  searchTerm,
  searching,
  selectedRows,
  tabStatus,
  action,
}: EnhancedTableToolbarProps) => {
  const { classes, cx } = tableStyles();

  const numSelected = selectedRows.length;

  return (
    <Collapse className={classes.tableElement} in={true}>
      <div
        className={cx(classes.toolbar, {
          [classes.highlight]: !!numSelected,
          [classes.hidden]: !numSelected && !searchTerm && !searching && !tabStatus,
        })}
      >
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            {!!numSelected && action && (
              <Tooltip title={action.title}>
                <IconButton
                  size="small"
                  onClick={() => action.onClick(selectedRows)}
                  data-testid="c-table-toolbar-action"
                >
                  <action.icon />
                </IconButton>
              </Tooltip>
            )}

            <Box>
              <Typography
                className={cx({
                  [classes.hidden]: numSelected === 0,
                  [classes.centered]: noOfRowsFound === null,
                })}
                fontSize="inherit"
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            {!!tabStatus && (
              <Box display="flex" alignItems="center" gap={1}>
                <CircularProgress
                  className={classes.spinner}
                  disableShrink={true}
                  color="secondary"
                  size={20}
                />
                <Typography
                  fontSize="inherit"
                  className={classes.toolbarText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  This tab is still loading data...
                </Typography>
              </Box>
            )}
          </Box>

          <Box flex={1} alignItems="center">
            <Typography
              fontSize="inherit"
              data-testid="c-table-search-results"
              className={cx(classes.toolbarText, {
                [classes.hidden]: noOfRowsFound === null,
              })}
              color="textSecondary"
              variant="subtitle1"
              component="div"
            >
              {searching && searchTerm ? (
                <>
                  <CircularProgress
                    className={classes.spinner}
                    disableShrink={true}
                    color="secondary"
                    size={20}
                  />
                  Searching...
                </>
              ) : (
                searchTerm &&
                `Found ${noOfRowsFound} ${
                  noOfRowsFound === 1 ? 'row' : 'rows'
                } containing '${searchTerm}'`
              )}
            </Typography>
          </Box>
        </Box>
      </div>
    </Collapse>
  );
};
