import { UseMutateFunction, useMutation, useQuery } from '@tanstack/react-query';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import { issueServices } from 'services/IssuesServices';
import { IImageGetResponseST } from 'codegen/image/api';
import { QueryNames } from 'ts-types/QueryNames';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { webSocket } from 'shared/web-socket/web-socket';
import { subscribeToImageStatus } from 'common/functions/web-socket/subscribe-to-image-status';
import { ImageSubscribersOptions, imageSubscribers } from 'common/functions/web-socket/subscribers';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { MutationNames } from 'ts-types/MutationNames';

export type LocationImagesState = {
  imageUrls: string[];
  numberOfImageUrlsRequested: number;
  generateAndDownloadZip: UseMutateFunction<void, Error, void, unknown>;
  isLoadingImageUrls: boolean;
  isDownloadingImages: boolean;
  showBarcodeHighlighting: boolean;
  toggleBarcodeHighlighting: () => void;
};

export const useLocationImagesState = (
  systemId: string,
  locationData: ILocationData,
): LocationImagesState => {
  const { location, slotStatusVersion, isReview, imageIds } = locationData;

  const socket = webSocket();
  const isFetchEnabled = slotStatusVersion !== undefined;

  const { enqueueSnackbar } = useSnackbar();
  const {
    isLoading: isLoadingImageUrls,
    data = { urls: [], num_requested: 0, num_failed: 0 } as IImageGetResponseST,
    refetch,
  } = useQuery({
    queryKey: [QueryNames.LOCATION_MODAL_GET_IMAGES, location],
    queryFn: () =>
      issueServices.getImages(systemId, location, slotStatusVersion as number, isReview),
    select: ({ data }) => data,
    enabled: isFetchEnabled,
  });

  useEffect(() => {
    if (locationData && isFetchEnabled) {
      refetch();
    }
  }, [locationData, refetch, isFetchEnabled]);

  const getImage = async (url: string) => {
    const response = await fetch(url, {
      mode: 'cors',
      credentials: 'include',
      cache: 'no-store',
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${url}`);
    }
    return response.blob();
  };

  const { mutate: generateAndDownloadZip, isPending: isDownloadingImages } = useMutation({
    mutationKey: [MutationNames.DOWNLOAD_IMAGES, location],
    mutationFn: async () => {
      const zip = new JSZip();

      try {
        const getImagePromises = data.urls.map(async (url: string, index: number) => {
          const imageName = `${location}-img-${index + 1}.png`;
          const imageData = await getImage(url);
          zip.file(imageName, imageData);
        });

        await Promise.all(getImagePromises);
        const content = await zip.generateAsync({ type: 'blob' });
        const zipFolderName = `${location}-images.zip`;
        saveAs(content, zipFolderName);
      } catch (error) {
        enqueueSnackbar(`${error}`, {
          variant: 'error',
        });
      }
    },
  });

  useEffect(() => {
    subscribeToImageStatus({
      socket,
      onSubscribe: ({ image_id }) => {
        if (imageIds?.includes(image_id)) {
          refetch();
        }
      },
    });

    return () => socket.unsubscribeAll<ImageSubscribersOptions>(imageSubscribers);
  }, [imageIds, refetch, socket]);

  const [showBarcodeHighlighting, setShowBarcodeHighlighting] = useState<boolean>(() => {
    const saved = localStorage.getItem('showBarcodeHighlighting');
    return saved ? JSON.parse(saved) : true;
  });

  useEffect(() => {
    localStorage.setItem('showBarcodeHighlighting', JSON.stringify(showBarcodeHighlighting));
  }, [showBarcodeHighlighting]);

  const toggleBarcodeHighlighting = () => {
    setShowBarcodeHighlighting((prev) => !prev);
  };

  return {
    imageUrls: data.urls,
    numberOfImageUrlsRequested: data.num_requested,
    generateAndDownloadZip,
    isLoadingImageUrls,
    isDownloadingImages,
    showBarcodeHighlighting,
    toggleBarcodeHighlighting,
  };
};
