import Drawer from '@mui/material/Drawer';
import { LocationSummaryPopup } from 'components/ModalsAndPopups/LocationModal/features/location-summary-popup/LocationSummaryPopup';
import { LocationDetailsDrawerProps } from './model/locationDetailsDrawerProps.model';
import { useLocationDetailsDrawerStyle } from './styles/locationDetailsDrawer.style';

export const LocationDetailsDrawer = ({
  details = [],
  open,
  containerRef,
  onClose,
  locationName,
}: LocationDetailsDrawerProps) => {
  const { classes } = useLocationDetailsDrawerStyle();
  return (
    <Drawer
      variant="persistent"
      open={open}
      anchor="right"
      container={containerRef.current}
      PaperProps={{
        className: classes.drawerPaper,
      }}
    >
      <LocationSummaryPopup locationName={locationName} details={details} onClose={onClose} />
    </Drawer>
  );
};
