import { IReportSTInsightTypeEnum } from 'codegen/report';

export const getInsightTypeLabel = (insightType: IReportSTInsightTypeEnum | undefined): string => {
  switch (insightType) {
    case IReportSTInsightTypeEnum.BarcodeScanning:
      return 'Barcodes';
    case IReportSTInsightTypeEnum.ItemCounting:
      return 'Counting';
    default:
      return 'N/A';
  }
};
