/**
 * Authentication pages URLs
 */
export const AUTH_PAGES_URLS = {
  ROOT: '/auth',
  SIGNIN: '/auth/signin',
  SIGNOUT: '/auth/signout',
  MFA_AUTHENTICATION: '/auth/mfa-authentication',
  FORGOT_PASSWORD: '/auth/forgot-password',
  NEW_PASSWORD: '/auth/new-password',
  CHANGE_TEMPORARY_PASSWORD: '/auth/change-temporary-password',
};

/**
 * Inventory pages URLs
 */
const INVENTORY = '/inventory';

export const INVENTORY_PAGES_URLS = {
  SET_MFA_PREFERENCE: '/set-mfa-preference',
  OVERVIEW: `${INVENTORY}`,
  WAREHOUSE_STATUS_MAP: `${INVENTORY}/warehouse-status-map`,
  LOCATIONS_MANAGEMENT: `${INVENTORY}/locations-management`,
  WAREHOUSE_STATUS: `${INVENTORY}/warehouse-status`,
  WAREHOUSE_STATUS_X: `${INVENTORY}/warehouse-status-x`,
  ITEM_VIEW: `${INVENTORY}/warehouse-status-x/item-view`,
  REPORTS: `${INVENTORY}/reports`,
  REPORT: `${INVENTORY}/report`,
  SCHEDULING: `${INVENTORY}/reports/scheduler`,
  WAREHOUSE_DATA_UPLOADS: `${INVENTORY}/warehouse-data-uploads`,
  ANALYTICS: `${INVENTORY}/analytics`,
};

/**
 * Facility Settings pages URLs
 */
export const FACILITY_SETTINGS_PAGES_URLS = {
  ROOT: `${INVENTORY}/admin`,
  SYSTEM_DETAILS: `${INVENTORY}/admin/system-details`,
  FACILITY_DETAILS: `${INVENTORY}/admin/facility-details`,
  FACILITY_SETTINGS: `${INVENTORY}/admin/facility-settings`,
  UPLOADS: `${INVENTORY}/admin/uploads`,
  USERS: `${INVENTORY}/admin/users`,
};

/**
 * Client pages URLs
 */
export const CLIENT_PAGES_URLS = {
  SELECT_FACILITY: '/select-facility',
};

/**
 * Ground Control pages URLs
 */
export const GROUND_CONTROL_PAGES_URLS = (flightDomainId?: string) => ({
  INDEX: '/ground-control',
  OVERVIEW: `/ground-control/${flightDomainId}`,
  FLEET: `/ground-control/${flightDomainId}/fleet`,
  NO_FLY_ZONES: `/ground-control/${flightDomainId}/no-fly-zones`,
  DRONE_ZONES: `/ground-control/${flightDomainId}/drone-zones`,
  SCHEDULE: `/ground-control/${flightDomainId}/building-schedule`,
});

/**
 * Commissioning pages URLs
 */
export const DELIVERY_PAGES_URLS = (flightDomainId?: string) => ({
  INDEX: '/delivery',
  FD: `/delivery/${flightDomainId}`,
  COMMISSIONING: `/delivery/${flightDomainId}/commissioning`,
  FLEET: `/delivery/${flightDomainId}/fleet`,
  DRONE_ZONES: `/delivery/${flightDomainId}/drone-zones`,
});

/**
 * Remove Picking Locations pages URLs
 */
export const REMOTE_PICKING_LOCATIONS = '/counting';

export const REMOTE_PICKING_LOCATIONS_URLS = {
  REPORT: `${REMOTE_PICKING_LOCATIONS}/report`,
};

/**
 * Pages that don't require authentication.
 * This list is used for PrivateRoute and SignOut components
 */
export const NO_AUTH_PAGES = [
  AUTH_PAGES_URLS.SIGNIN,
  AUTH_PAGES_URLS.FORGOT_PASSWORD,
  AUTH_PAGES_URLS.NEW_PASSWORD,
  AUTH_PAGES_URLS.CHANGE_TEMPORARY_PASSWORD,
  AUTH_PAGES_URLS.MFA_AUTHENTICATION,
];

/**
 * Warning pages URLs
 */
export const WARNING_PAGES_URLS = {
  NOT_FOUND: '/not-found',
  NO_FACILITY: '/no-facility-warning',
};

/**
 * Get the list of routes which can be visited with a system ID
 * for a facility which hasn't been setup yet.
 * @param systemId System ID
 * @returns the list of reserved routes
 */
export const NON_CONFIGURED_FACILITY_PAGE_URLS = (systemId: string) => [
  WARNING_PAGES_URLS.NOT_FOUND,
  `/${systemId}${FACILITY_SETTINGS_PAGES_URLS.FACILITY_DETAILS}`,
  `/${systemId}${FACILITY_SETTINGS_PAGES_URLS.SYSTEM_DETAILS}`,
  `/${systemId}${FACILITY_SETTINGS_PAGES_URLS.USERS}`,
  `/${systemId}${INVENTORY_PAGES_URLS.SET_MFA_PREFERENCE}`,
  `/${systemId}${WARNING_PAGES_URLS.NO_FACILITY}`,
];
