import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import { OrthographicCamera } from 'three';

export function useCameraFrustumSizing() {
  const camera = useThree((state) => state.camera);
  const size = useThree((state) => state.size);

  useEffect(() => {
    if (!(camera instanceof OrthographicCamera)) {
      return;
    }

    const halfWidth = size.width / 2;
    const halfHeight = size.height / 2;

    camera.left = -halfWidth;
    camera.right = halfWidth;

    camera.top = halfHeight;
    camera.bottom = -halfHeight;

    camera.updateProjectionMatrix();
  }, [camera, size]);
}
