import { IFacilityInfoST, IFacilityMapInfoST } from 'codegen/facility';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { IFlightDomainData } from '../GroundControl/groundControlLevelStore.model';

export enum AppActionNames {
  SET_SOFTWARE_VERSION = 'SET_SOFTWARE_VERSION',
  /**
   * Set the system ID of the last facility which couldn't be found.
   */
  SET_NOT_FOUND = 'SET_NOT_FOUND',
  APP_DATA_LOADED = 'APP_DATA_LOADED',
  GET_BE_VERSION = 'GET_BE_VERSION',
}

/**
 * Actions which cannot be classified any better at this stage.
 *
 * BUGBUG / TODO: find a better classification for these ones.
 */
enum MiscellaneousActionNames {
  TOGGLE_ADMIN_DRAWER = 'TOGGLE_ADMIN_DRAWER',
}

export enum FacilitySettingsActionNames {
  /**
   * Websocket related action: contains the % of the map updated in progress.
   */
  WS_FACILITY_MAP_UPDATE = 'WS_FACILITY_MAP_UPDATE',
}

/**
 * Actions related to Warehouse data.
 */
export enum InventoryActionNames {
  GET_REPORT_SPECIFICATIONS = 'GET_REPORT_SPECIFICATIONS',
  // FIXME: move to notification actions.
  WS_WAREHOUSE_EXPORT = 'WS_WAREHOUSE_EXPORT',
  WS_REPORT_STATUS_UPDATE = 'WS_REPORT_STATUS_UPDATE',
  WS_REPORT_EXPORT = 'WS_REPORT_EXPORT',
  WS_IMAGE_STATUS = 'WS_IMAGE_STATUS',
}

export enum FacilityActionNames {
  /**
   * Set the facility info data
   */
  SET_FACILITY_INFO = 'SET_FACILITY_INFO',
  /**
   * Set flag indicator whether fetching request is in progress
   */
  SET_IS_FACILITY_INFO_LOADING = 'SET_IS_FACILITY_INFO_LOADING',
  /**
   * Set the flag indicating whether the facility info data is currently loaded
   */
  SET_FACILITY_INFO_LOADED = 'SET_FACILITY_INFO_LOADED',
  /**
   * Set the data regarding the map info of the facility
   */
  SET_FACILITY_MAP_INFO = 'SET_FACILITY_MAP_INFO',
  /**
   * Set the flag indicating whether the facility map info is currently loaded
   */
  SET_FACILITY_MAP_INFO_LOADED = 'SET_FACILITY_MAP_INFO_LOADED',
  SET_FLIGHT_DOMAINS = 'SET_FLIGHT_DOMAINS',
  SET_FLIGHT_DOMAINS_LOADED = 'SET_FLIGHT_DOMAINS_LOADED',
  CLEAR_FACILITY_DATA = 'CLEAR_FACILITY_DATA',
  SET_FACILITY_SETTINGS = 'SET_FACILITY_SETTINGS',
  SET_IS_FACILITY_DATA_INITIATED = 'SET_IS_FACILITY_DATA_INITIATED',
  /**
   * Set the flag indicating whether the facility settings are currently loaded
   */
  SET_FACILITY_SETTINGS_LOADED = 'SET_FACILITY_SETTINGS_LOADED',
  /**
   * Set the permissions for the current facility.
   */
  CLEAR_FACILITY_SETTINGS = 'CLEAR_FACILITY_SETTINGS',
  SET_CURRENT_SYSTEM_ID = 'SET_CURRENT_SYSTEM_ID',
  CLEAR_SETTINGS = 'CLEAR_SETTINGS',
  CLEAR_FACILITY_STORE = 'CLEAR_FACILITY_STORE',
  SET_WEBSOCKET_ERROR = 'SET_WEBSOCKET_ERROR',
  /**
   * Flag indicating whether the facility data is ready
   */
  SET_IS_DATA_READY = 'SET_IS_DATA_READY',
}

export type InventoryLevelAction =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { type: InventoryActionNames.GET_REPORT_SPECIFICATIONS; payload: any }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { type: InventoryActionNames.WS_IMAGE_STATUS; payload: any }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { type: InventoryActionNames.WS_REPORT_EXPORT; payload: any }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { type: InventoryActionNames.WS_REPORT_STATUS_UPDATE; payload: any }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { type: InventoryActionNames.WS_WAREHOUSE_EXPORT; payload: any };

/**
 * Types of Facility level actions
 */
export type FacilityLevelAction =
  | InventoryLevelAction
  | { type: AppActionNames.APP_DATA_LOADED; payload: boolean }
  | { type: AppActionNames.SET_NOT_FOUND; payload: boolean }
  | {
      type: AppActionNames.SET_SOFTWARE_VERSION;
      payload: {
        version: string;
        last_updated: string;
      };
    }
  | { type: MiscellaneousActionNames.TOGGLE_ADMIN_DRAWER; payload: boolean }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { type: FacilitySettingsActionNames.WS_FACILITY_MAP_UPDATE; payload: any }
  | { type: FacilityActionNames.SET_FACILITY_INFO; payload: IFacilityInfoST }
  | { type: FacilityActionNames.SET_FACILITY_INFO_LOADED; payload: boolean }
  | { type: FacilityActionNames.SET_IS_FACILITY_DATA_INITIATED; payload: boolean }
  | { type: FacilityActionNames.SET_FACILITY_MAP_INFO; payload: IFacilityMapInfoST }
  | { type: FacilityActionNames.SET_FACILITY_MAP_INFO_LOADED; payload: boolean }
  | { type: FacilityActionNames.SET_IS_FACILITY_INFO_LOADING; payload: boolean }
  | { type: FacilityActionNames.SET_FLIGHT_DOMAINS; payload: IFlightDomainData[] }
  | { type: FacilityActionNames.SET_FLIGHT_DOMAINS_LOADED; payload: boolean }
  | { type: FacilityActionNames.CLEAR_FACILITY_DATA }
  | { type: FacilityActionNames.SET_FACILITY_SETTINGS; payload: IFacilitySettingsST }
  | { type: FacilityActionNames.SET_FACILITY_SETTINGS_LOADED; payload: boolean }
  | { type: FacilityActionNames.CLEAR_FACILITY_SETTINGS }
  | { type: FacilityActionNames.SET_CURRENT_SYSTEM_ID; payload: string | null }
  | { type: FacilityActionNames.CLEAR_SETTINGS }
  | { type: FacilityActionNames.CLEAR_FACILITY_STORE }
  | { type: FacilityActionNames.SET_IS_DATA_READY; payload: boolean }
  | { type: FacilityActionNames.SET_WEBSOCKET_ERROR; payload: boolean };
