import { ThreeEvent } from '@react-three/fiber';
import { useState, useMemo, useCallback } from 'react';
import { Bin3DProps } from 'shared/map-3d/bin3D/model/bin3DProps.model';

export function useActiveBinSelection(aisleBins: Bin3DProps[]) {
  const [activeBinName, setActiveBinName] = useState('');

  const aisleBinsWithHandlers = useMemo(
    () =>
      aisleBins.map((bin) => ({
        ...bin,
        onClick: (event: ThreeEvent<MouseEvent>) => {
          setActiveBinName(event.object.parent?.name ?? 'Unknown');
        },
      })),
    [aisleBins],
  );

  const resetActiveBin = useCallback(() => {
    setActiveBinName('');
  }, []);

  return useMemo(
    () => ({ activeBinName, aisleBinsWithHandlers, resetActiveBin }),
    [activeBinName, aisleBinsWithHandlers, resetActiveBin],
  );
}
