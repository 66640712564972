import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from './loadingPlaceholder.styles';

export const LoadingPlaceholder = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <CircularProgress color="secondary" size={30} disableShrink={true} />
    </div>
  );
};
