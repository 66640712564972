// ###########################################################################
// This file contains the functions needed to handle actions across different
// components
// - Report Tables
// - Issue Tables
// - Location Tables
// - Home page cards
// - Delete, enable, overwrite, snooze modals
// - Download uploaded files
// ###########################################################################

import find from 'lodash/find';

import {
  INVENTORY_REQUEST_ACTIONS,
  REPORT_ENTRY_ACTIONS,
  ISSUE_ACTIONS,
  DISPATCHED_AND_ONGOING_REPORT_ACTIONS,
  ONGOING_REPORT_ACTIONS,
  UPLOADED_FILE_ACTIONS,
  REPORT_ACTIONS,
  COMMISSIONING_TASK_ACTIONS,
} from './actionTypes';

import { FacilityModalsActionTypes } from '../../store/Modals/types';
import { IFacilityModalsAction } from '../../store/Modals/facilityModals/IFacilityModalsActions';

/**
 * Handle action click type
 */
type HandleActionClick =
  | INVENTORY_REQUEST_ACTIONS
  | DISPATCHED_AND_ONGOING_REPORT_ACTIONS
  | ONGOING_REPORT_ACTIONS
  | REPORT_ENTRY_ACTIONS
  | ISSUE_ACTIONS
  | UPLOADED_FILE_ACTIONS
  | REPORT_ACTIONS;
// Handle clicks for actions
export const handleActionClick = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[],
  action: HandleActionClick,
  dispatchFacilityModals: (args: IFacilityModalsAction) => void,
  refreshData: { refreshData: () => void },
  id?: string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let itemToEdit: any = {};

  // items is an array that can hold data for multiple locations
  // In case of editing, deleting, enabling or disabling inventory
  // requests, we pass only data for a single location,
  // therefore we must pull it from items array
  if (id) {
    itemToEdit =
      find(items, { request_id: id }) ||
      find(items, { report_id: id }) ||
      find(items, { username: id }) ||
      find(items, { id }) ||
      find(items, { wms_parser_output_url: id }) ||
      find(items, { client_file_url: id });
  } else {
    itemToEdit = items;
  }

  // UD-6187: This can apparently be an object or an array.
  // This seems to most compatible way to access the values below.
  // This needs properly refactoring as this is very difficult to debug.
  const data = Array.isArray(itemToEdit) ? itemToEdit[0] : itemToEdit;

  switch (action) {
    case INVENTORY_REQUEST_ACTIONS.EDIT:
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.EDIT_REQUEST,
        payload: itemToEdit,
        refreshData,
      });
      break;
    case INVENTORY_REQUEST_ACTIONS.DELETE:
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.DELETE_REQUEST,
        payload: itemToEdit,
        refreshData,
      });
      break;
    case INVENTORY_REQUEST_ACTIONS.ENABLE:
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.TOGGLE_REQUEST_STATE,
        payload: itemToEdit,
        refreshData,
      });
      break;
    case INVENTORY_REQUEST_ACTIONS.DISABLE:
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.TOGGLE_REQUEST_STATE,
        payload: itemToEdit,
        refreshData,
      });
      break;
    case DISPATCHED_AND_ONGOING_REPORT_ACTIONS.ABORT:
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.REPORT_ABORT,
        payload: {
          reportId: itemToEdit.report_id,
          reportName: itemToEdit.request.report_name,
        },
        refreshData,
      });
      break;
    case ONGOING_REPORT_ACTIONS.UPDATE:
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.REPORT_UPDATE,
        payload: {
          reportId: itemToEdit.report_id,
          reportName: itemToEdit.request.report_name,
        },
        refreshData,
      });
      break;
    case REPORT_ENTRY_ACTIONS.AMEND:
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.AMEND_VERITY_VALUE,
        payload: items,
        refreshData,
        tableAmending: true,
      });
      break;
    case ISSUE_ACTIONS.SNOOZE:
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.TOGGLE_SNOOZE_ISSUE,
        payload: itemToEdit,
        refreshData,
      });
      break;
    case ISSUE_ACTIONS.UNSNOOZE:
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.TOGGLE_SNOOZE_ISSUE,
        payload: itemToEdit,
        refreshData,
      });
      break;
    case COMMISSIONING_TASK_ACTIONS.ABORT.action:
      itemToEdit.abortTaskFunction();
      break;
    case UPLOADED_FILE_ACTIONS.DOWNLOAD_WMS_PARSER_OUTPUT:
      if (data.wms_parser_output_url) {
        // TODO: genericise programtic links to click an anchor with download to prevent navigation on broken files.
        window.open(data.wms_parser_output_url, '_parent');
      }
      break;
    case UPLOADED_FILE_ACTIONS.DOWNLOAD_CLIENT_FILE:
      if (data.client_file_url) {
        // TODO: genericise programtic links to click an anchor with download to prevent navigation on broken files.
        window.open(data.client_file_url, '_parent');
      }
      break;
    case REPORT_ACTIONS.ARCHIVE:
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.REPORT_ARCHIVE,
        payload: {
          reportIds: [data.report_id],
          reportName: data.request.report_name,
        },
        refreshData,
      });

      break;
    case REPORT_ACTIONS.RESTORE:
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.REPORT_RESTORE,
        payload: {
          reportIds: [data.report_id],
          reportName: data.request.report_name,
        },
        refreshData,
      });

      break;
    default:
      break;
  }
};
