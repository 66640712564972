import moment from 'moment';
import { WeekDayShort } from 'common/datetimeFormats';
import { generateRruleFromUIComponents } from 'common/functionsReportSpec';
import { IReportSpecificationST } from 'codegen/report_specification';
import { IInventoryRequestPostRequestST } from 'codegen/inventory_request';
import { ScheduleFormState } from '../reducers/ScheduleForm.model';

export const handleSubmit = ({
  state,
  setDays,
  timezone,
  reportSpec,
  onSubmit,
}: {
  state: ScheduleFormState;
  setDays: (days: WeekDayShort[]) => void;
  timezone: string;
  reportSpec: IReportSpecificationST;
  onSubmit: (request: IInventoryRequestPostRequestST) => void;
}) => {
  // Generate rRule string, text and all occurrence dates
  const { rString } = generateRruleFromUIComponents({
    days: state.days,
    setDays,
    dateFrom: state.dateFrom,
    dateUntil: state.dateUntil,
    isRecurring: state.isRecurring,
    occurrenceInterval: state.occurrenceInterval,
    interval: state.interval,
    timezone,
    reportSpecTriggering: reportSpec.scheduling.triggering_source,
  });

  const isReportStartingNow =
    state.recurrenceState.occurrencesCount <= 1 &&
    moment(state.dateFrom) <= moment().add(1, 'minutes');

  // Payload item
  const item = {
    report_spec_id: reportSpec.id,
    report_spec_version: reportSpec.version,
    insight_type: state.reportInsightType,
    report_name: state.reportName,
    params: state.reportParamValues,
    priority: state.priority,
    rrule: rString,
    timeout: state.reportDeadlineTimeoutInMinutes,
    // Start an instant report if it is scheduled for now.
    instant_report: isReportStartingNow,
  };

  // call the submit method received in the props
  // can be either a create or an update
  onSubmit(item);
};
