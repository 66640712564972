import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';

export const WarehouseStatusOverviewLoader = ({
  alreadyLoaded,
  total,
}: {
  alreadyLoaded?: number;
  total?: number;
}) => {
  const progress = alreadyLoaded && total ? (100 * alreadyLoaded) / total : undefined;

  return (
    <>
      <div
        style={{
          minHeight: '400px',
        }}
      >
        <Skeleton
          variant="rounded"
          width="100%"
          height="88px"
          sx={{
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
            bgcolor: 'white',
          }}
        />
        <Skeleton
          variant="rounded"
          width="100%"
          height="310px"
          sx={{
            marginTop: '2px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
            bgcolor: 'white',
          }}
        />
      </div>

      <div
        style={{
          position: 'relative',
          minHeight: '400px',
        }}
      >
        <Skeleton
          variant="rounded"
          width="100%"
          height="88px"
          sx={{
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
            bgcolor: 'white',
          }}
        />
        <Skeleton
          variant="rounded"
          width="100%"
          height="310px"
          sx={{
            marginTop: '2px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
            bgcolor: 'white',
          }}
        />

        <Box
          component="div"
          alignItems="center"
          display="flex"
          justifyContent="center"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
        >
          <Box component="div" display="inline-flex" position="relative">
            <CircularProgress />
            {progress && (
              <Box
                component="div"
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                data-testid="c-whs-loader-percent"
              >
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  component="div"
                  sx={{ color: 'text.secondary' }}
                >
                  {`${Math.round(progress)}%`}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </div>

      <div
        style={{
          minHeight: '400px',
        }}
      >
        <Skeleton
          variant="rounded"
          width="100%"
          height="88px"
          sx={{
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
            bgcolor: 'white',
          }}
        />
        <Skeleton
          variant="rounded"
          width="100%"
          height="310px"
          sx={{
            marginTop: '2px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
            bgcolor: 'white',
          }}
        />
      </div>
    </>
  );
};
