import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0,1fr)',
    alignItems: 'center',
    justifyItems: 'center',
    padding: theme.spacing(4),
    textAlign: 'center',
    minHeight: '300px',
  },
  contentWrapper: {
    display: 'grid',
    justifyItems: 'center',
    gap: theme.spacing(5),
    gridTemplateColumns: 'minmax(0,1fr)',
    gridAutoRows: 'min-content',
  },
  iconWrapper: {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: '12px',
    fontSize: '2.5rem',

    svg: {
      display: 'block',
      lineHeight: 1,
      fontSize: 'inherit',
    },
  },
}));
