import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    borderWidth: '1px',
    borderStyle: 'dashed',
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.content.secondary,
    borderRadius: theme.spacing(2),
  },
}));
