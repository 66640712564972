import { useCallback } from 'react';
import { Vec6 } from 'shared/map-container/MapContainer.model';
import type { DroneZone } from 'shared/map/model/drone-zone.model';
import { DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import type { BottomLeftDimensions, DroneZoneErrors } from '../../../droneZone.model';
import { zoneTypeValidationExceptions } from '../../../defaults/droneZoneForm.defaults';
import { validateSizeAndPositionFitsWithinWorldBox } from '../utils/validateSizeAndPositionFitsWithinWorldBox.util';

export const useValidateDroneZoneForm = <T extends DroneZone>(
  zoneType: keyof typeof DroneZoneTypes,
  onUpdate: (droneZone: T) => void,
): ((worldBox: Vec6) => (values: T) => DroneZoneErrors) => {
  const validate = useCallback(
    (worldBox: Vec6) => (values: T) => {
      let errors: DroneZoneErrors = {};

      Object.keys(values.sizeAndPosition).forEach((key) => {
        if (zoneTypeValidationExceptions[zoneType].includes(key as string)) return;

        const validationMessage: string | null = validateSizeAndPositionFitsWithinWorldBox({
          zoneVectors: values.sizeAndPosition,
          facilityVectors: worldBox,
          fieldKey: key as keyof BottomLeftDimensions,
        });

        if (validationMessage) {
          errors = {
            ...errors,
            sizeAndPosition: {
              ...(typeof errors.sizeAndPosition === 'object' ? errors.sizeAndPosition : {}),
              [key]: validationMessage,
            },
          };
        }
      });

      onUpdate(values);

      return errors;
    },
    [onUpdate, zoneType],
  );

  return validate;
};
