import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { BarcodeMatchLogic } from 'common/functions/barcodes/getBarcodeMuiColor.util';
import { IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { isTextAreaOrInputActive } from 'common/functions/domFunctions';
import { ImageGrid } from './ImageGrid';
import { LocationImagesState } from '../../hooks/useLocationImagesState';
import { ImageGridMessageContainer } from './ImageGridMessageContainer';

export const ImageGridCard = ({
  systemId,
  locationData,
  locationImagesState,
  highlightedBarcode,
  issueLogic,
  barcodeMatchLogic,
  setHighlightedBarcode,
}: {
  locationData: ILocationData;
  systemId: string;
  locationImagesState: LocationImagesState;
  highlightedBarcode: string;
  issueLogic: IRuleActionSTIssueLogicEnum | undefined;
  barcodeMatchLogic: BarcodeMatchLogic | undefined;
  setHighlightedBarcode: Dispatch<SetStateAction<string>>;
}) => {
  const { verityState } = locationData;
  const {
    imageUrls,
    isLoadingImageUrls,
    numberOfImageUrlsRequested,
    showBarcodeHighlighting,
    toggleBarcodeHighlighting,
  } = locationImagesState;

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (isTextAreaOrInputActive()) return;

      if (event.key === 'b') {
        toggleBarcodeHighlighting();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [toggleBarcodeHighlighting]);

  return (
    <div style={{ aspectRatio: 3.7 }}>
      {imageUrls?.length > 0 ? (
        <ImageGrid
          imageUrls={imageUrls}
          locationData={locationData}
          systemId={systemId}
          showBarcodeHighlighting={showBarcodeHighlighting}
          highlightedBarcode={highlightedBarcode}
          barcodeMatchLogic={barcodeMatchLogic}
          issueLogic={issueLogic}
          setHighlightedBarcode={setHighlightedBarcode}
        />
      ) : (
        <ImageGridMessageContainer
          verityState={verityState}
          isLoadingImageUrls={isLoadingImageUrls}
          imageUrlsLength={imageUrls.length}
          numberOfImageUrlsRequested={numberOfImageUrlsRequested}
        />
      )}
    </div>
  );
};
