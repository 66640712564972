/* tslint:disable */
/* eslint-disable */
/**
 * Flight Domain
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IBox3DST
 */
export interface IBox3DST {
  /**
   *
   * @type {IMapPointST}
   * @memberof IBox3DST
   */
  min: IMapPointST;
  /**
   *
   * @type {IMapPointST}
   * @memberof IBox3DST
   */
  max: IMapPointST;
}
/**
 *
 * @export
 * @interface IDroneOperatingHoursST
 */
export interface IDroneOperatingHoursST {
  /**
   * The start time of the drone operating hours. Expressed in UTC time.
   * @type {string}
   * @memberof IDroneOperatingHoursST
   */
  start: string;
  /**
   * The end time of the drone operating hours. Expressed in UTC time.
   * @type {string}
   * @memberof IDroneOperatingHoursST
   */
  end: string;
}
/**
 *
 * @export
 * @interface IDroneStatus1ST
 */
export interface IDroneStatus1ST {
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  additional_diagnostics?: string | null;
  /**
   * The current of the battery
   * @type {number}
   * @memberof IDroneStatus1ST
   */
  battery_current?: number | null;
  /**
   * The drone battery level
   * @type {number}
   * @memberof IDroneStatus1ST
   */
  battery_level: number;
  /**
   * Serial number of the battery
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  battery_serial_number?: string | null;
  /**
   * The drone battery level
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  battery_state: IDroneStatus1STBatteryStateEnum;
  /**
   * Temperature of the battery
   * @type {number}
   * @memberof IDroneStatus1ST
   */
  battery_temperature?: number | null;
  /**
   * Voltage of the battery
   * @type {number}
   * @memberof IDroneStatus1ST
   */
  battery_voltage?: number | null;
  /**
   * The id of the drone charger
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  charger_id: string;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  charger_pos_description: string;
  /**
   * Charging current limit
   * @type {number}
   * @memberof IDroneStatus1ST
   */
  charging_current_limit?: number | null;
  /**
   * Charging strategy
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  charging_strategy?: IDroneStatus1STChargingStrategyEnum;
  /**
   * Detailed Battery state
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  detailed_battery_state?: IDroneStatus1STDetailedBatteryStateEnum;
  /**
   * The drone name
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  drone_name: string;
  /**
   * The drone state
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  drone_state: IDroneStatus1STDroneStateEnum;
  /**
   * The drone connection state
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  drone_online: IDroneStatus1STDroneOnlineEnum;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  last_operation_feedback?: string | null;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  last_reliable_pose_time?: string | null;
  /**
   * Maximum number of recommended charging cycles
   * @type {number}
   * @memberof IDroneStatus1ST
   */
  max_recommended_num_charging_cycles?: number | null;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  not_ready_reason?: string | null;
  /**
   * Number of charging cycles
   * @type {number}
   * @memberof IDroneStatus1ST
   */
  num_charging_cycles?: number | null;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  pose_state?: IDroneStatus1STPoseStateEnum;
  /**
   *
   * @type {IPositionAndYawST}
   * @memberof IDroneStatus1ST
   */
  position_and_yaw?: IPositionAndYawST;
  /**
   * Time to the optimal charge
   * @type {number}
   * @memberof IDroneStatus1ST
   */
  time_to_optimal_charge?: number | null;
  /**
   * The timestamp of the status update
   * @type {string}
   * @memberof IDroneStatus1ST
   */
  updated_at: string;
}

export const IDroneStatus1STBatteryStateEnum = {
  Charging: 'CHARGING',
  FullyCharged: 'FULLY_CHARGED',
  NotPluggedIn: 'NOT_PLUGGED_IN',
  Recovering: 'RECOVERING',
  Error: 'ERROR',
  ShipMode: 'SHIP_MODE',
  NotCharging: 'NOT_CHARGING',
  Standby: 'STANDBY',
} as const;

export type IDroneStatus1STBatteryStateEnum =
  typeof IDroneStatus1STBatteryStateEnum[keyof typeof IDroneStatus1STBatteryStateEnum];
export const IDroneStatus1STChargingStrategyEnum = {
  AlwaysCharge: 'ALWAYS_CHARGE',
  AlwaysAvailableDuringOperatingHours: 'ALWAYS_AVAILABLE_DURING_OPERATING_HOURS',
  OnlyAvailableWhenActivities: 'ONLY_AVAILABLE_WHEN_ACTIVITIES',
  AvailableFromStartOfOperatingHForMinimumDuration:
    'AVAILABLE_FROM_START_OF_OPERATING_H_FOR_MINIMUM_DURATION',
  AvailableFromStartOfOperatingHForMinimumDurationAndUnlocked:
    'AVAILABLE_FROM_START_OF_OPERATING_H_FOR_MINIMUM_DURATION_AND_UNLOCKED',
  Null: 'null',
} as const;

export type IDroneStatus1STChargingStrategyEnum =
  typeof IDroneStatus1STChargingStrategyEnum[keyof typeof IDroneStatus1STChargingStrategyEnum];
export const IDroneStatus1STDetailedBatteryStateEnum = {
  InvalidBmsDataForTooLong: 'INVALID_BMS_DATA_FOR_TOO_LONG',
  MaxFailedChargingStartAttemptsReached: 'MAX_FAILED_CHARGING_START_ATTEMPTS_REACHED',
  UnknownFunctionalRevision: 'UNKNOWN_FUNCTIONAL_REVISION',
  IrStrobeNotOffForTooLong: 'IR_STROBE_NOT_OFF_FOR_TOO_LONG',
  DepletedBattery: 'DEPLETED_BATTERY',
  ChargingCircuitTooHotForTooLong: 'CHARGING_CIRCUIT_TOO_HOT_FOR_TOO_LONG',
  ChargingCircuitTooHotTooOften: 'CHARGING_CIRCUIT_TOO_HOT_TOO_OFTEN',
  FatalFailureLatched: 'FATAL_FAILURE_LATCHED',
  CannotChargeHotBattery: 'CANNOT_CHARGE_HOT_BATTERY',
  CannotChargeColdBattery: 'CANNOT_CHARGE_COLD_BATTERY',
  CannotChargeDisconnectedFromCharger: 'CANNOT_CHARGE_DISCONNECTED_FROM_CHARGER',
  CannotEscapeStateTriage: 'CANNOT_ESCAPE_STATE_TRIAGE',
  UnknownState: 'UNKNOWN_STATE',
  ChargingWillCommenceShortly: 'CHARGING_WILL_COMMENCE_SHORTLY',
  ChargingTheBattery: 'CHARGING_THE_BATTERY',
  BatteryIsFullyCharged: 'BATTERY_IS_FULLY_CHARGED',
  BatteryCellsAreBeingBalanced: 'BATTERY_CELLS_ARE_BEING_BALANCED',
  DecidingOnNextSteps: 'DECIDING_ON_NEXT_STEPS',
  BatteryIsTooHotToCharge: 'BATTERY_IS_TOO_HOT_TO_CHARGE',
  BatteryIsTooColdToCharge: 'BATTERY_IS_TOO_COLD_TO_CHARGE',
  WaitingForIrStrobeToTurnOff: 'WAITING_FOR_IR_STROBE_TO_TURN_OFF',
  ChargingCircuitIsTooHot: 'CHARGING_CIRCUIT_IS_TOO_HOT',
  ChargerIsNotConnected: 'CHARGER_IS_NOT_CONNECTED',
  CellVoltagesAreTooHigh: 'CELL_VOLTAGES_ARE_TOO_HIGH',
  InvalidBmsData: 'INVALID_BMS_DATA',
  DroneIsFlying: 'DRONE_IS_FLYING',
  BatteryIsInStandby: 'BATTERY_IS_IN_STANDBY',
  Null: 'null',
} as const;

export type IDroneStatus1STDetailedBatteryStateEnum =
  typeof IDroneStatus1STDetailedBatteryStateEnum[keyof typeof IDroneStatus1STDetailedBatteryStateEnum];
export const IDroneStatus1STDroneStateEnum = {
  OnCharger: 'ON_CHARGER',
  Flying: 'FLYING',
  LandedInError: 'LANDED_IN_ERROR',
} as const;

export type IDroneStatus1STDroneStateEnum =
  typeof IDroneStatus1STDroneStateEnum[keyof typeof IDroneStatus1STDroneStateEnum];
export const IDroneStatus1STDroneOnlineEnum = {
  Online: 'ONLINE',
  Stale: 'STALE',
  Offline: 'OFFLINE',
} as const;

export type IDroneStatus1STDroneOnlineEnum =
  typeof IDroneStatus1STDroneOnlineEnum[keyof typeof IDroneStatus1STDroneOnlineEnum];
export const IDroneStatus1STPoseStateEnum = {
  Unkown: 'UNKOWN',
  Reliable: 'RELIABLE',
  Unreliable: 'UNRELIABLE',
  UnreliableButInferredFromCharger: 'UNRELIABLE_BUT_INFERRED_FROM_CHARGER',
} as const;

export type IDroneStatus1STPoseStateEnum =
  typeof IDroneStatus1STPoseStateEnum[keyof typeof IDroneStatus1STPoseStateEnum];

/**
 *
 * @export
 * @interface IDroneStatusST
 */
export interface IDroneStatusST {
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatusST
   */
  additional_diagnostics?: string | null;
  /**
   * The current of the battery
   * @type {number}
   * @memberof IDroneStatusST
   */
  battery_current?: number | null;
  /**
   * The drone battery level
   * @type {number}
   * @memberof IDroneStatusST
   */
  battery_level: number;
  /**
   * Serial number of the battery
   * @type {string}
   * @memberof IDroneStatusST
   */
  battery_serial_number?: string | null;
  /**
   * The drone battery level
   * @type {string}
   * @memberof IDroneStatusST
   */
  battery_state: IDroneStatusSTBatteryStateEnum;
  /**
   * Temperature of the battery
   * @type {number}
   * @memberof IDroneStatusST
   */
  battery_temperature?: number | null;
  /**
   * Voltage of the battery
   * @type {number}
   * @memberof IDroneStatusST
   */
  battery_voltage?: number | null;
  /**
   * The id of the drone charger
   * @type {string}
   * @memberof IDroneStatusST
   */
  charger_id: string;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatusST
   */
  charger_pos_description: string;
  /**
   * Charging current limit
   * @type {number}
   * @memberof IDroneStatusST
   */
  charging_current_limit?: number | null;
  /**
   * Charging strategy
   * @type {string}
   * @memberof IDroneStatusST
   */
  charging_strategy?: IDroneStatusSTChargingStrategyEnum;
  /**
   * Detailed Battery state
   * @type {string}
   * @memberof IDroneStatusST
   */
  detailed_battery_state?: IDroneStatusSTDetailedBatteryStateEnum;
  /**
   * The drone name
   * @type {string}
   * @memberof IDroneStatusST
   */
  drone_name: string;
  /**
   * The drone state
   * @type {string}
   * @memberof IDroneStatusST
   */
  drone_state: IDroneStatusSTDroneStateEnum;
  /**
   * The drone connection state
   * @type {string}
   * @memberof IDroneStatusST
   */
  drone_online: IDroneStatusSTDroneOnlineEnum;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatusST
   */
  last_operation_feedback?: string | null;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatusST
   */
  last_reliable_pose_time?: string | null;
  /**
   * Maximum number of recommended charging cycles
   * @type {number}
   * @memberof IDroneStatusST
   */
  max_recommended_num_charging_cycles?: number | null;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatusST
   */
  not_ready_reason?: string | null;
  /**
   * Number of charging cycles
   * @type {number}
   * @memberof IDroneStatusST
   */
  num_charging_cycles?: number | null;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatusST
   */
  pose_state?: IDroneStatusSTPoseStateEnum;
  /**
   *
   * @type {IPositionAndYawST}
   * @memberof IDroneStatusST
   */
  position_and_yaw?: IPositionAndYawST;
  /**
   * Time to the optimal charge
   * @type {number}
   * @memberof IDroneStatusST
   */
  time_to_optimal_charge?: number | null;
  /**
   * The timestamp of the status update
   * @type {string}
   * @memberof IDroneStatusST
   */
  updated_at: string;
}

export const IDroneStatusSTBatteryStateEnum = {
  Charging: 'CHARGING',
  FullyCharged: 'FULLY_CHARGED',
  NotPluggedIn: 'NOT_PLUGGED_IN',
  Recovering: 'RECOVERING',
  Error: 'ERROR',
  ShipMode: 'SHIP_MODE',
  NotCharging: 'NOT_CHARGING',
  Standby: 'STANDBY',
} as const;

export type IDroneStatusSTBatteryStateEnum =
  typeof IDroneStatusSTBatteryStateEnum[keyof typeof IDroneStatusSTBatteryStateEnum];
export const IDroneStatusSTChargingStrategyEnum = {
  AlwaysCharge: 'ALWAYS_CHARGE',
  AlwaysAvailableDuringOperatingHours: 'ALWAYS_AVAILABLE_DURING_OPERATING_HOURS',
  OnlyAvailableWhenActivities: 'ONLY_AVAILABLE_WHEN_ACTIVITIES',
  AvailableFromStartOfOperatingHForMinimumDuration:
    'AVAILABLE_FROM_START_OF_OPERATING_H_FOR_MINIMUM_DURATION',
  AvailableFromStartOfOperatingHForMinimumDurationAndUnlocked:
    'AVAILABLE_FROM_START_OF_OPERATING_H_FOR_MINIMUM_DURATION_AND_UNLOCKED',
  Null: 'null',
} as const;

export type IDroneStatusSTChargingStrategyEnum =
  typeof IDroneStatusSTChargingStrategyEnum[keyof typeof IDroneStatusSTChargingStrategyEnum];
export const IDroneStatusSTDetailedBatteryStateEnum = {
  InvalidBmsDataForTooLong: 'INVALID_BMS_DATA_FOR_TOO_LONG',
  MaxFailedChargingStartAttemptsReached: 'MAX_FAILED_CHARGING_START_ATTEMPTS_REACHED',
  UnknownFunctionalRevision: 'UNKNOWN_FUNCTIONAL_REVISION',
  IrStrobeNotOffForTooLong: 'IR_STROBE_NOT_OFF_FOR_TOO_LONG',
  DepletedBattery: 'DEPLETED_BATTERY',
  ChargingCircuitTooHotForTooLong: 'CHARGING_CIRCUIT_TOO_HOT_FOR_TOO_LONG',
  ChargingCircuitTooHotTooOften: 'CHARGING_CIRCUIT_TOO_HOT_TOO_OFTEN',
  FatalFailureLatched: 'FATAL_FAILURE_LATCHED',
  CannotChargeHotBattery: 'CANNOT_CHARGE_HOT_BATTERY',
  CannotChargeColdBattery: 'CANNOT_CHARGE_COLD_BATTERY',
  CannotChargeDisconnectedFromCharger: 'CANNOT_CHARGE_DISCONNECTED_FROM_CHARGER',
  CannotEscapeStateTriage: 'CANNOT_ESCAPE_STATE_TRIAGE',
  UnknownState: 'UNKNOWN_STATE',
  ChargingWillCommenceShortly: 'CHARGING_WILL_COMMENCE_SHORTLY',
  ChargingTheBattery: 'CHARGING_THE_BATTERY',
  BatteryIsFullyCharged: 'BATTERY_IS_FULLY_CHARGED',
  BatteryCellsAreBeingBalanced: 'BATTERY_CELLS_ARE_BEING_BALANCED',
  DecidingOnNextSteps: 'DECIDING_ON_NEXT_STEPS',
  BatteryIsTooHotToCharge: 'BATTERY_IS_TOO_HOT_TO_CHARGE',
  BatteryIsTooColdToCharge: 'BATTERY_IS_TOO_COLD_TO_CHARGE',
  WaitingForIrStrobeToTurnOff: 'WAITING_FOR_IR_STROBE_TO_TURN_OFF',
  ChargingCircuitIsTooHot: 'CHARGING_CIRCUIT_IS_TOO_HOT',
  ChargerIsNotConnected: 'CHARGER_IS_NOT_CONNECTED',
  CellVoltagesAreTooHigh: 'CELL_VOLTAGES_ARE_TOO_HIGH',
  InvalidBmsData: 'INVALID_BMS_DATA',
  DroneIsFlying: 'DRONE_IS_FLYING',
  BatteryIsInStandby: 'BATTERY_IS_IN_STANDBY',
  Null: 'null',
} as const;

export type IDroneStatusSTDetailedBatteryStateEnum =
  typeof IDroneStatusSTDetailedBatteryStateEnum[keyof typeof IDroneStatusSTDetailedBatteryStateEnum];
export const IDroneStatusSTDroneStateEnum = {
  OnCharger: 'ON_CHARGER',
  Flying: 'FLYING',
  LandedInError: 'LANDED_IN_ERROR',
} as const;

export type IDroneStatusSTDroneStateEnum =
  typeof IDroneStatusSTDroneStateEnum[keyof typeof IDroneStatusSTDroneStateEnum];
export const IDroneStatusSTDroneOnlineEnum = {
  Online: 'ONLINE',
  Stale: 'STALE',
  Offline: 'OFFLINE',
} as const;

export type IDroneStatusSTDroneOnlineEnum =
  typeof IDroneStatusSTDroneOnlineEnum[keyof typeof IDroneStatusSTDroneOnlineEnum];
export const IDroneStatusSTPoseStateEnum = {
  Unkown: 'UNKOWN',
  Reliable: 'RELIABLE',
  Unreliable: 'UNRELIABLE',
  UnreliableButInferredFromCharger: 'UNRELIABLE_BUT_INFERRED_FROM_CHARGER',
} as const;

export type IDroneStatusSTPoseStateEnum =
  typeof IDroneStatusSTPoseStateEnum[keyof typeof IDroneStatusSTPoseStateEnum];

/**
 *
 * @export
 * @interface IDroneVersionST
 */
export interface IDroneVersionST {
  /**
   * The drone name
   * @type {string}
   * @memberof IDroneVersionST
   */
  drone_name: string;
  /**
   * Drone Serial Number
   * @type {string}
   * @memberof IDroneVersionST
   */
  drone_serial: string;
  /**
   * Drone SW Version
   * @type {string}
   * @memberof IDroneVersionST
   */
  version: string;
  /**
   * Last SW Update Time
   * @type {string}
   * @memberof IDroneVersionST
   */
  last_updated: string;
}
/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IFleetStatusResponseST
 */
export interface IFleetStatusResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IFleetStatusResponseST
   */
  flight_domain_id: string;
  /**
   * The status of the fleet
   * @type {{ [key: string]: IDroneStatus1ST; }}
   * @memberof IFleetStatusResponseST
   */
  fleet_status: { [key: string]: IDroneStatus1ST };
}
/**
 *
 * @export
 * @interface IFleetStatusSummaryResponseST
 */
export interface IFleetStatusSummaryResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IFleetStatusSummaryResponseST
   */
  flight_domain_id: string;
  /**
   *
   * @type {IFleetStatusSummaryST}
   * @memberof IFleetStatusSummaryResponseST
   */
  fleet_status_summary: IFleetStatusSummaryST;
}
/**
 *
 * @export
 * @interface IFleetStatusSummaryST
 */
export interface IFleetStatusSummaryST {
  /**
   * The number of drones that are flying
   * @type {number}
   * @memberof IFleetStatusSummaryST
   */
  num_drones_flying: number;
  /**
   * The number of drones that are charging
   * @type {number}
   * @memberof IFleetStatusSummaryST
   */
  num_drones_on_charger: number;
  /**
   * The number of drones that landed
   * @type {number}
   * @memberof IFleetStatusSummaryST
   */
  num_drones_landed_in_error: number;
  /**
   * The time when the information was collected. Expressed in UTC time.
   * @type {string}
   * @memberof IFleetStatusSummaryST
   */
  updated_at: string;
}
/**
 *
 * @export
 * @interface IFleetVersionResponseST
 */
export interface IFleetVersionResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IFleetVersionResponseST
   */
  flight_domain_id: string;
  /**
   * The Drone Versions
   * @type {{ [key: string]: IDroneVersionST; }}
   * @memberof IFleetVersionResponseST
   */
  drone_versions: { [key: string]: IDroneVersionST };
}
/**
 * @type IFlightDomainStatusCurrentDroneOperatingHoursST
 * The current drone operating_hours. If null, there is no drone operating hours active.
 * @export
 */
export type IFlightDomainStatusCurrentDroneOperatingHoursST =
  | IDroneOperatingHoursST
  | INullableFieldST;

/**
 * @type IFlightDomainStatusNextDroneOperatingHoursST
 * The next drone operating hours.
 * @export
 */
export type IFlightDomainStatusNextDroneOperatingHoursST =
  | IDroneOperatingHoursST
  | INullableFieldST;

/**
 *
 * @export
 * @interface IFlightDomainStatusResponseST
 */
export interface IFlightDomainStatusResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IFlightDomainStatusResponseST
   */
  flight_domain_id: string;
  /**
   * The name of the flight domain
   * @type {string}
   * @memberof IFlightDomainStatusResponseST
   */
  flight_domain_name: string;
  /**
   *
   * @type {IFlightDomainStatusST}
   * @memberof IFlightDomainStatusResponseST
   */
  flight_domain_status: IFlightDomainStatusST;
}
/**
 *
 * @export
 * @interface IFlightDomainStatusST
 */
export interface IFlightDomainStatusST {
  /**
   * The lock status
   * @type {boolean}
   * @memberof IFlightDomainStatusST
   */
  locked: boolean;
  /**
   * The time when the flight domain was last locked/unlock the last time. Expressed in UTC time.
   * @type {string}
   * @memberof IFlightDomainStatusST
   */
  last_locked_unlocked_time: string;
  /**
   * The unique identifier of the ongoing unlock session
   * @type {string}
   * @memberof IFlightDomainStatusST
   */
  unlock_session_identifier: string;
  /**
   * The reason that triggered the system lock
   * @type {string}
   * @memberof IFlightDomainStatusST
   */
  lock_reason: IFlightDomainStatusSTLockReasonEnum;
  /**
   * The lock status
   * @type {boolean}
   * @memberof IFlightDomainStatusST
   */
  can_unlock: boolean;
  /**
   * The reason for which the system cannot be unlocked
   * @type {string}
   * @memberof IFlightDomainStatusST
   */
  cannot_unlock_reason: IFlightDomainStatusSTCannotUnlockReasonEnum;
  /**
   * The entity that triggered the system lock
   * @type {string}
   * @memberof IFlightDomainStatusST
   */
  triggered_by: string | null;
  /**
   *
   * @type {IFlightDomainStatusCurrentDroneOperatingHoursST}
   * @memberof IFlightDomainStatusST
   */
  current_drone_operating_hours: IFlightDomainStatusCurrentDroneOperatingHoursST | null;
  /**
   *
   * @type {IFlightDomainStatusNextDroneOperatingHoursST}
   * @memberof IFlightDomainStatusST
   */
  next_drone_operating_hours: IFlightDomainStatusNextDroneOperatingHoursST | null;
  /**
   * The time when the information was collected. Expressed in UTC time
   * @type {string}
   * @memberof IFlightDomainStatusST
   */
  updated_at: string;
  /**
   * The next time for which a lock is scheduled. Expressed in UTC time.
   * @type {string}
   * @memberof IFlightDomainStatusST
   */
  next_scheduled_lock?: string | null;
  /**
   * flag that allows drone to run at full speed even if controlled zone are setup
   * @type {boolean}
   * @memberof IFlightDomainStatusST
   */
  allow_normal_flight_in_controlled_zone?: boolean | null;
}

export const IFlightDomainStatusSTLockReasonEnum = {
  Manual: 'MANUAL',
  EmergencyLand: 'EMERGENCY_LAND',
  DroneOperatingHoursEnd: 'DRONE_OPERATING_HOURS_END',
  FleetDecimated: 'FLEET_DECIMATED',
  SystemInitialization: 'SYSTEM_INITIALIZATION',
  ScheduledLock: 'SCHEDULED_LOCK',
  Null: 'null',
} as const;

export type IFlightDomainStatusSTLockReasonEnum =
  typeof IFlightDomainStatusSTLockReasonEnum[keyof typeof IFlightDomainStatusSTLockReasonEnum];
export const IFlightDomainStatusSTCannotUnlockReasonEnum = {
  AlreadyUnlocked: 'ALREADY_UNLOCKED',
  OutsideDroneOperatingHours: 'OUTSIDE_DRONE_OPERATING_HOURS',
  Null: 'null',
} as const;

export type IFlightDomainStatusSTCannotUnlockReasonEnum =
  typeof IFlightDomainStatusSTCannotUnlockReasonEnum[keyof typeof IFlightDomainStatusSTCannotUnlockReasonEnum];

/**
 * @type IFlightDomainSummaryFleetStatusSummaryST
 * @export
 */
export type IFlightDomainSummaryFleetStatusSummaryST = IFleetStatusSummaryST | INullableFieldST;

/**
 * @type IFlightDomainSummaryFlightDomainStatusST
 * @export
 */
export type IFlightDomainSummaryFlightDomainStatusST = IFlightDomainStatusST | INullableFieldST;

/**
 * Flight domain info
 * @export
 * @interface IFlightDomainSummaryST
 */
export interface IFlightDomainSummaryST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IFlightDomainSummaryST
   */
  flight_domain_id: string;
  /**
   * Name of the flight domain
   * @type {string}
   * @memberof IFlightDomainSummaryST
   */
  flight_domain_name: string;
  /**
   *
   * @type {IFlightDomainSummaryFlightDomainStatusST}
   * @memberof IFlightDomainSummaryST
   */
  flight_domain_status: IFlightDomainSummaryFlightDomainStatusST | null;
  /**
   *
   * @type {IFlightDomainSummaryFleetStatusSummaryST}
   * @memberof IFlightDomainSummaryST
   */
  fleet_status_summary: IFlightDomainSummaryFleetStatusSummaryST | null;
}
/**
 *
 * @export
 * @interface IFlightDomainsGetResponseST
 */
export interface IFlightDomainsGetResponseST {
  /**
   * The mapping flight domain id => flight domain name
   * @type {{ [key: string]: string; }}
   * @memberof IFlightDomainsGetResponseST
   */
  flight_domains: { [key: string]: string };
}
/**
 *
 * @export
 * @interface IFlightDomainsSummaryGetResponseST
 */
export interface IFlightDomainsSummaryGetResponseST {
  /**
   *
   * @type {Array<IFlightDomainSummaryST>}
   * @memberof IFlightDomainsSummaryGetResponseST
   */
  flight_domains: Array<IFlightDomainSummaryST>;
}
/**
 *
 * @export
 * @interface IGetEstimatedObstaclesResponseST
 */
export interface IGetEstimatedObstaclesResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IGetEstimatedObstaclesResponseST
   */
  flight_domain_id: string;
  /**
   * The Estimated Obstacles
   * @type {{ [key: string]: IObstacleInformation1ST; }}
   * @memberof IGetEstimatedObstaclesResponseST
   */
  estimated_obstacles: { [key: string]: IObstacleInformation1ST };
}
/**
 *
 * @export
 * @interface IGetSpaceReservationsResponseST
 */
export interface IGetSpaceReservationsResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IGetSpaceReservationsResponseST
   */
  flight_domain_id: string;
  /**
   * The status of the fleet
   * @type {{ [key: string]: ISpaceReservationInformation1ST; }}
   * @memberof IGetSpaceReservationsResponseST
   */
  space_reservations: { [key: string]: ISpaceReservationInformation1ST };
}
/**
 *
 * @export
 * @interface IMapPointST
 */
export interface IMapPointST {
  /**
   * The value of the X coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  x: number;
  /**
   * The value of the Y coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  y: number;
  /**
   * The value of the Z coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  z: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const INullableFieldST = {
  Null: 'null',
  None: 'None',
} as const;

export type INullableFieldST = typeof INullableFieldST[keyof typeof INullableFieldST];

/**
 *
 * @export
 * @interface IObstacleInformation1ST
 */
export interface IObstacleInformation1ST {
  /**
   * Discovered by drone
   * @type {string}
   * @memberof IObstacleInformation1ST
   */
  discovered_by_drone: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof IObstacleInformation1ST
   */
  geometry: IBox3DST;
  /**
   * The timestamp of discovery
   * @type {string}
   * @memberof IObstacleInformation1ST
   */
  discovery_time: string;
  /**
   * The timestamp of time-out
   * @type {string}
   * @memberof IObstacleInformation1ST
   */
  times_out_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof IObstacleInformation1ST
   */
  type?: IObstacleInformation1STTypeEnum;
}

export const IObstacleInformation1STTypeEnum = {
  EstimatedFromCollisionAvoidanceSensors: 'ESTIMATED_FROM_COLLISION_AVOIDANCE_SENSORS',
  InferredFromSpaceReservation: 'INFERRED_FROM_SPACE_RESERVATION',
  NumSystemNoFlyZoneType: 'NUM_SYSTEM_NO_FLY_ZONE_TYPE',
  Null: 'null',
} as const;

export type IObstacleInformation1STTypeEnum =
  typeof IObstacleInformation1STTypeEnum[keyof typeof IObstacleInformation1STTypeEnum];

/**
 *
 * @export
 * @interface IObstacleInformationST
 */
export interface IObstacleInformationST {
  /**
   * Discovered by drone
   * @type {string}
   * @memberof IObstacleInformationST
   */
  discovered_by_drone: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof IObstacleInformationST
   */
  geometry: IBox3DST;
  /**
   * The timestamp of discovery
   * @type {string}
   * @memberof IObstacleInformationST
   */
  discovery_time: string;
  /**
   * The timestamp of time-out
   * @type {string}
   * @memberof IObstacleInformationST
   */
  times_out_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof IObstacleInformationST
   */
  type?: IObstacleInformationSTTypeEnum;
}

export const IObstacleInformationSTTypeEnum = {
  EstimatedFromCollisionAvoidanceSensors: 'ESTIMATED_FROM_COLLISION_AVOIDANCE_SENSORS',
  InferredFromSpaceReservation: 'INFERRED_FROM_SPACE_RESERVATION',
  NumSystemNoFlyZoneType: 'NUM_SYSTEM_NO_FLY_ZONE_TYPE',
  Null: 'null',
} as const;

export type IObstacleInformationSTTypeEnum =
  typeof IObstacleInformationSTTypeEnum[keyof typeof IObstacleInformationSTTypeEnum];

/**
 * PositionAndYaw
 * @export
 * @interface IPositionAndYawST
 */
export interface IPositionAndYawST {
  /**
   * The value of the X coordinate of drone position
   * @type {number}
   * @memberof IPositionAndYawST
   */
  x: number;
  /**
   * The value of the Y coordinate of drone position
   * @type {number}
   * @memberof IPositionAndYawST
   */
  y: number;
  /**
   * The value of the Z coordinate of drone position
   * @type {number}
   * @memberof IPositionAndYawST
   */
  z: number;
  /**
   * The yaw coordinate for drone position
   * @type {number}
   * @memberof IPositionAndYawST
   */
  yaw: number;
}
/**
 *
 * @export
 * @interface ISpaceReservationInformation1ST
 */
export interface ISpaceReservationInformation1ST {
  /**
   * Drone ID
   * @type {string}
   * @memberof ISpaceReservationInformation1ST
   */
  drone_id: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof ISpaceReservationInformation1ST
   */
  geometry: IBox3DST;
  /**
   * The timestamp of expiry
   * @type {string}
   * @memberof ISpaceReservationInformation1ST
   */
  expires_at: string;
}
/**
 *
 * @export
 * @interface ISpaceReservationInformationST
 */
export interface ISpaceReservationInformationST {
  /**
   * Drone ID
   * @type {string}
   * @memberof ISpaceReservationInformationST
   */
  drone_id: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof ISpaceReservationInformationST
   */
  geometry: IBox3DST;
  /**
   * The timestamp of expiry
   * @type {string}
   * @memberof ISpaceReservationInformationST
   */
  expires_at: string;
}

/**
 * FlightDomainApi - axios parameter creator
 * @export
 */
export const FlightDomainApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * This should be called to get the current estimated obstacles in a specific flight domain.
     * @summary Returns the estimated obstacles in a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEstimatedObstacles: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getEstimatedObstacles', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getEstimatedObstacles', 'flightDomainId', flightDomainId);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/estimated-obstacles`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This should be called to get the current status of the fleet of a specific flight domain.
     * @summary Returns the status of the fleet of a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetOverview: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFleetOverview', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getFleetOverview', 'flightDomainId', flightDomainId);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/fleet`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This should be called to get the summary of the current status of the fleet of a specific flight domain.
     * @summary Returns the summary of the status of the fleet of a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetStatusSummary: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFleetStatusSummary', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getFleetStatusSummary', 'flightDomainId', flightDomainId);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/fleet/status-summary`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This should be called to get the current drone versions for the fleet of a specific flight domain.
     * @summary Returns the drone versions for the fleet of a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetVersion: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFleetVersion', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getFleetVersion', 'flightDomainId', flightDomainId);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/fleet/version`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This should be called to get the current status of a specific flight domain.
     * @summary Returns the status of a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFlightDomainInfo: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFlightDomainInfo', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getFlightDomainInfo', 'flightDomainId', flightDomainId);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return the list of flight domains with summary information about their status
     * @summary Return the list of flight domains with summary information about their status
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFlightDomains: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFlightDomains', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/flight-domain/summary`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * The route returns a mapping flight domain id => flight domain name.
     * @summary Return the list of flight domains.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFlightDomainsInfo: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFlightDomainsInfo', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/flight-domain`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This should be called to get the current space reservations for a specific flight domain.
     * @summary Returns the space reservations for a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpaceReservations: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getSpaceReservations', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getSpaceReservations', 'flightDomainId', flightDomainId);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/space-reservations`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FlightDomainApi - functional programming interface
 * @export
 */
export const FlightDomainApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FlightDomainApiAxiosParamCreator(configuration);
  return {
    /**
     * This should be called to get the current estimated obstacles in a specific flight domain.
     * @summary Returns the estimated obstacles in a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEstimatedObstacles(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetEstimatedObstaclesResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEstimatedObstacles(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This should be called to get the current status of the fleet of a specific flight domain.
     * @summary Returns the status of the fleet of a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFleetOverview(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFleetStatusResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFleetOverview(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This should be called to get the summary of the current status of the fleet of a specific flight domain.
     * @summary Returns the summary of the status of the fleet of a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFleetStatusSummary(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFleetStatusSummaryResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFleetStatusSummary(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This should be called to get the current drone versions for the fleet of a specific flight domain.
     * @summary Returns the drone versions for the fleet of a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFleetVersion(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFleetVersionResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFleetVersion(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This should be called to get the current status of a specific flight domain.
     * @summary Returns the status of a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFlightDomainInfo(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFlightDomainStatusResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFlightDomainInfo(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return the list of flight domains with summary information about their status
     * @summary Return the list of flight domains with summary information about their status
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFlightDomains(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFlightDomainsSummaryGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFlightDomains(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * The route returns a mapping flight domain id => flight domain name.
     * @summary Return the list of flight domains.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFlightDomainsInfo(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFlightDomainsGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFlightDomainsInfo(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This should be called to get the current space reservations for a specific flight domain.
     * @summary Returns the space reservations for a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSpaceReservations(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetSpaceReservationsResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSpaceReservations(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FlightDomainApi - factory interface
 * @export
 */
export const FlightDomainApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FlightDomainApiFp(configuration);
  return {
    /**
     * This should be called to get the current estimated obstacles in a specific flight domain.
     * @summary Returns the estimated obstacles in a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEstimatedObstacles(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IGetEstimatedObstaclesResponseST> {
      return localVarFp
        .getEstimatedObstacles(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This should be called to get the current status of the fleet of a specific flight domain.
     * @summary Returns the status of the fleet of a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetOverview(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IFleetStatusResponseST> {
      return localVarFp
        .getFleetOverview(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This should be called to get the summary of the current status of the fleet of a specific flight domain.
     * @summary Returns the summary of the status of the fleet of a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetStatusSummary(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IFleetStatusSummaryResponseST> {
      return localVarFp
        .getFleetStatusSummary(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This should be called to get the current drone versions for the fleet of a specific flight domain.
     * @summary Returns the drone versions for the fleet of a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetVersion(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IFleetVersionResponseST> {
      return localVarFp
        .getFleetVersion(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This should be called to get the current status of a specific flight domain.
     * @summary Returns the status of a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFlightDomainInfo(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IFlightDomainStatusResponseST> {
      return localVarFp
        .getFlightDomainInfo(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return the list of flight domains with summary information about their status
     * @summary Return the list of flight domains with summary information about their status
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFlightDomains(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IFlightDomainsSummaryGetResponseST> {
      return localVarFp
        .getFlightDomains(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * The route returns a mapping flight domain id => flight domain name.
     * @summary Return the list of flight domains.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFlightDomainsInfo(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IFlightDomainsGetResponseST> {
      return localVarFp
        .getFlightDomainsInfo(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This should be called to get the current space reservations for a specific flight domain.
     * @summary Returns the space reservations for a specific flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpaceReservations(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IGetSpaceReservationsResponseST> {
      return localVarFp
        .getSpaceReservations(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FlightDomainApi - object-oriented interface
 * @export
 * @class FlightDomainApi
 * @extends {BaseAPI}
 */
export class FlightDomainApi extends BaseAPI {
  /**
   * This should be called to get the current estimated obstacles in a specific flight domain.
   * @summary Returns the estimated obstacles in a specific flight domain
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightDomainApi
   */
  public getEstimatedObstacles(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return FlightDomainApiFp(this.configuration)
      .getEstimatedObstacles(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This should be called to get the current status of the fleet of a specific flight domain.
   * @summary Returns the status of the fleet of a specific flight domain
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightDomainApi
   */
  public getFleetOverview(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return FlightDomainApiFp(this.configuration)
      .getFleetOverview(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This should be called to get the summary of the current status of the fleet of a specific flight domain.
   * @summary Returns the summary of the status of the fleet of a specific flight domain
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightDomainApi
   */
  public getFleetStatusSummary(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return FlightDomainApiFp(this.configuration)
      .getFleetStatusSummary(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This should be called to get the current drone versions for the fleet of a specific flight domain.
   * @summary Returns the drone versions for the fleet of a specific flight domain
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightDomainApi
   */
  public getFleetVersion(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return FlightDomainApiFp(this.configuration)
      .getFleetVersion(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This should be called to get the current status of a specific flight domain.
   * @summary Returns the status of a specific flight domain
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightDomainApi
   */
  public getFlightDomainInfo(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return FlightDomainApiFp(this.configuration)
      .getFlightDomainInfo(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return the list of flight domains with summary information about their status
   * @summary Return the list of flight domains with summary information about their status
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightDomainApi
   */
  public getFlightDomains(systemIdStr: string, options?: AxiosRequestConfig) {
    return FlightDomainApiFp(this.configuration)
      .getFlightDomains(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * The route returns a mapping flight domain id => flight domain name.
   * @summary Return the list of flight domains.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightDomainApi
   */
  public getFlightDomainsInfo(systemIdStr: string, options?: AxiosRequestConfig) {
    return FlightDomainApiFp(this.configuration)
      .getFlightDomainsInfo(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This should be called to get the current space reservations for a specific flight domain.
   * @summary Returns the space reservations for a specific flight domain
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightDomainApi
   */
  public getSpaceReservations(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return FlightDomainApiFp(this.configuration)
      .getSpaceReservations(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
