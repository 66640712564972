const isValidImperialFormat = (value: string) =>
  /^-?(\d[0-9]*')?((1[0-1]|[0-9])("{1}|'{2}))?$/.test(value);

export const isImperialValid = (value: string): string | undefined => {
  if (!isValidImperialFormat(value)) {
    return 'Please enter valid feet and inches (e.g., 5\'10")';
  }

  return undefined;
};
