import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { handleActionClick } from 'common/Actions/actionHandlers';
import { BaseCard, IBaseCardActionButton } from 'components/BaseCard/BaseCard';
import { BaseCardReportItem } from 'components/BaseCard/BaseCardReportItem';
import { IUpcomingReportsInternal, InventoryRequestActionInternal } from '../../interfaces';
import { getUpcomingReports } from './functions/getUpcomingReports';
import { useFacilityModalsStore } from '../../store/Modals';
import { useRequestController } from '../../hooks';
import { GetRequestsResponse } from '../../store/RequestStore/RequestStore';

interface IScheduledReportsCardProps {
  activeTab: number;
}

const logPrefix = getLogPrefixForType('COMPONENT', 'ScheduledReportsCard');

export const ScheduledReportsCard = ({ activeTab }: IScheduledReportsCardProps) => {
  const navigate = useNavigate();
  const { systemId = '' } = useParams();

  const { requestController } = useRequestController(logPrefix);
  const { dispatchFacilityModals } = useFacilityModalsStore();

  const [isLoading, setIsLoading] = useState(false);
  const [reportsNoInfo, setReportsNoInfo] = useState({
    numberOfReports: 0,
    nextSchedulingDate: '',
  });
  const [reports, setReports] = useState<IUpcomingReportsInternal[]>([]);

  const actionButtons: IBaseCardActionButton[] = [
    {
      variant: 'outlined',
      color: 'primary',
      fullWidth: true,
      label: 'See all scheduled reports',
      clickHandler: () => {
        navigate(`/${systemId}${INVENTORY_PAGES_URLS.REPORTS}`, {
          state: { activeTab },
        });
      },
    },
  ];

  const upcomingReportsData = {
    id: 'scheduled-reports-card',
    title: 'Scheduled reports',
    subtitle: reportsNoInfo.nextSchedulingDate ? 'Next reports are scheduled as follows: ' : '',
    counter: reportsNoInfo.numberOfReports,
    actionButtons,
    sections: reports,
  };

  const handleReports = useCallback(
    (r: GetRequestsResponse) => {
      const { fullResponse: inventoryRequests, reportsInfo } = r;
      let { upcomingReports } = r;

      upcomingReports = upcomingReports.map((report: IUpcomingReportsInternal) => {
        const fullRequest = inventoryRequests.find(
          (request) => request.request_id === report.requestId,
        );

        const optionsAux = report.actions.actions.map((action: InventoryRequestActionInternal) => ({
          id: crypto.randomUUID(),
          label: action.label,
          disabled: action.disabled,
          clickHandler: () =>
            handleActionClick(
              [fullRequest],
              action.label,
              dispatchFacilityModals,
              {
                refreshData: () =>
                  getUpcomingReports({
                    requestController,
                    systemId,
                    setIsLoading,
                    onSuccess: handleReports,
                  }),
              },
              fullRequest?.request_id,
            ),
        }));

        return {
          ...report,
          actionButtons: optionsAux,
        };
      });

      setReportsNoInfo(reportsInfo.upcomingReports);
      setReports(upcomingReports);
    },
    [dispatchFacilityModals, requestController, systemId],
  );

  useEffect(
    () =>
      getUpcomingReports({
        systemId,
        requestController,
        setIsLoading,
        onSuccess: handleReports,
      }),
    [handleReports, requestController, systemId],
  );

  return (
    <BaseCard
      cardFor="scheduled reports"
      dataTestId="c-scheduled-reports-card"
      showHeader={true}
      showContent={true}
      showActionButtons={true}
      showHeaderDivider={true}
      isLoading={isLoading}
      title={upcomingReportsData.title}
      subtitle={upcomingReportsData.subtitle}
      counter={upcomingReportsData.counter}
      actionButtons={upcomingReportsData.actionButtons}
      sx={{ order: 4 }}
    >
      {upcomingReportsData.sections &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        upcomingReportsData.sections.map((section: any) => (
          <BaseCardReportItem
            key={section.requestId}
            reportId={section.requestId}
            insightType={section.insightType}
            reportType={section.requestType}
            date={section.date}
            recurrence={section.recurrence}
            recurrenceDescription={section.recurrenceDescription}
            reportName={section.reportName}
            label={section.label}
            reportStatus={section.requestStatus}
            totalLocations={section.totalLocations}
            showBottomDivider={true}
            allowRedirect={false}
            actionButtons={section.actionButtons}
          />
        ))}
    </BaseCard>
  );
};
