import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import { BarcodeStatusEnum } from '../../../components/ModalsAndPopups/LocationModal/features/barcodes/models/BarcodeRow.model';

export const matchBarcode = (expectedBarcode: string, foundBarcode: string) => {
  if (expectedBarcode === foundBarcode) {
    return BarcodeStatusEnum.Match;
  }
  if (expectedBarcode === '') {
    return BarcodeStatusEnum.Unexpected;
  }
  if (expectedBarcode === 'Empty location' && foundBarcode !== 'Empty location') {
    return BarcodeStatusEnum.Unexpected;
  }
  if (foundBarcode !== 'No match' && expectedBarcode === 'No match') {
    return BarcodeStatusEnum.Unexpected;
  }
  return BarcodeStatusEnum.Missing;
};

export const filterOutNoMatch = (barcodes: string[]) =>
  barcodes.filter((barcode) => barcode !== 'No match');

export const matchBarcodes = ({ expected, found }: { expected?: string[]; found?: string[] }) => ({
  missing: found ? difference(expected, found) : expected || [],
  unexpected: expected ? difference(found, expected) : found || [],
  match: found && expected ? intersection(expected, found) : [],
});
