import { ILabelStatusST, ILabelTypeST } from 'codegen/location_action';
import moment from 'moment-timezone';
import { LabelsStatusAlert, LabelsStatusAlertProps } from './LabelsStatusAlert';
import { useLabelsStatusAlertProps } from './hooks/useLabelStatusAlertProps';

export const LabelsStatus = ({
  selectedLabelType,
  createdDateTime,
  wmsDateTime,
  verityDateTime,
  locationLabelStatus,
  onUnselect,
  closeModal,
}: {
  selectedLabelType?: ILabelTypeST;
  createdDateTime?: string;
  wmsDateTime?: string;
  verityDateTime?: string;
  locationLabelStatus?: ILabelStatusST;
  onUnselect: () => void;
  closeModal: () => void;
}) => {
  const labelsStatusAlertProps = useLabelsStatusAlertProps({
    selectedLabelType,
    createdDateTime,
    onUnselect,
    closeModal,
  });

  const isThereNewerWMSData = wmsDateTime
    ? moment(wmsDateTime).isAfter(moment(createdDateTime))
    : false;

  const isThereNewerVerityData = verityDateTime
    ? moment(verityDateTime).isAfter(moment(createdDateTime))
    : false;

  const getPropId = () => {
    if (locationLabelStatus === 'ARCHIVED') {
      return 'no-label';
    }

    if (isThereNewerWMSData && isThereNewerVerityData) {
      return moment(verityDateTime).isAfter(moment(wmsDateTime)) ? 're-scanned' : 'wms-updated';
    }

    if (isThereNewerVerityData) {
      return 're-scanned';
    }

    if (isThereNewerWMSData) {
      return 'wms-updated';
    }

    if (selectedLabelType) {
      return 'label-selected';
    }

    return 'no-label';
  };

  const getLabelsStatusAlertProp = () => labelsStatusAlertProps.find((p) => p.id === getPropId());

  return <LabelsStatusAlert {...(getLabelsStatusAlertProp() as LabelsStatusAlertProps)} />;
};
