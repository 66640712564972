import { useThree } from '@react-three/fiber';
import CameraControlsLib from 'camera-controls';
import { RefObject, useEffect } from 'react';
import { Box3, Group, Vector3 } from 'three';

function getAzimuthAngle(topViewRef: RefObject<Group>) {
  if (!topViewRef.current) {
    return 0;
  }

  const box = new Box3().setFromObject(topViewRef.current);
  const size = box.getSize(new Vector3());
  const direction = size.x > size.y ? 'x' : 'y';
  const rotationAngle = direction === 'y' ? Math.PI / 2 : 0;

  return rotationAngle;
}

export function useFitInLandscape({
  topViewRef,
  selectedFlightDomain,
}: {
  selectedFlightDomain: string;
  topViewRef: RefObject<Group>;
}) {
  const controls = useThree((state) => state.controls);

  useEffect(() => {
    if (controls instanceof CameraControlsLib && topViewRef.current) {
      controls.setLookAt(0, 0, 50, 0, 0, 0, false);
      controls.fitToBox(topViewRef.current, false);

      const rotationAngle = getAzimuthAngle(topViewRef);
      controls.minAzimuthAngle = rotationAngle;
      controls.maxAzimuthAngle = rotationAngle;

      controls.rotateAzimuthTo(rotationAngle);
    }
  }, [controls, topViewRef, selectedFlightDomain]);
}
