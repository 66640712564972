import { AxiosResponse } from 'axios';
import moment from 'moment';
import {
  IAuthenticationResponseST,
  IChallengeResponseST,
  IUserAttributesST,
} from 'codegen/authentication';
import { DATETIME_FORMAT } from './datetimeFormats';
import { getLogPrefixForType } from './functions/logFunctions';
import { LocalStore } from './functions/storageFunctions';
import { ACCESS_AND_ID_TOKENS_LIFESPAN_REDUCER } from './settings';
import { USER_GROUPS } from './userGroups';

const logPrefix = getLogPrefixForType('STORE', 'TokenManager');

/**
 * Class implementing the management of the OAuth authentication and refresh tokens
 */
export class TokenManager {
  private static instance: TokenManager | null = null;

  private refreshTokenTimeoutId: number | undefined;

  private accessAndIdTokenTimeoutId: number | undefined;

  private onRefreshTokenExpiration: (() => void) | undefined = undefined;

  public onAccessTokenAvailable: (() => void) | undefined = undefined;

  private refreshAccessAndIdToken = (
    _username: string,
    _refreshToken: string,
  ): Promise<AxiosResponse> => Promise.reject();

  private constructor() {
    if (this.getAccessToken() && this.getRefreshToken()) {
      this.startAccessTokenRefreshTimer();
      this.startRefreshTokenExpirationTimer();
    }
  }

  static getInstance = () => {
    if (!this.instance) {
      this.instance = new TokenManager();
    }
    return this.instance;
  };

  setOnRefreshTokenExpiration = (func: () => void): void => {
    this.onRefreshTokenExpiration = func;
  };

  setRefreshTokenFunction = (
    func: (username: string, refreshToken: string) => Promise<AxiosResponse>,
  ): void => {
    this.refreshAccessAndIdToken = func;
  };

  /**
   * Set auth challenge data
   */
  setAuthChallengeData = (authChallengeData: IChallengeResponseST) => {
    console.debug(logPrefix, 'setAuthChallengeData');
    this.setSession(authChallengeData.session);
  };

  /**
   * Brain-dead set
   */
  setAuthData = (authData: IAuthenticationResponseST, username?: string) => {
    console.debug(logPrefix, 'setAuthData');

    LocalStore.setAuthData(authData);

    this.setNewAccessAndIdToken(authData);
    this.setRefreshToken(authData.refresh_token);

    if (username) {
      this.setUsername(username);
    }

    const tokenReceivedAt = moment().utc().format(DATETIME_FORMAT);
    LocalStore.setRefreshTokenReceivedTime(tokenReceivedAt);

    this.startRefreshTokenExpirationTimer();
  };

  /**
   * Set new access and id tokens
   */
  setNewAccessAndIdToken = (data: { access_token: string; id_token: string }) => {
    this.setAccessToken(data.access_token);
    this.setIdToken(data.id_token);

    const tokenReceivedAt = moment().utc().format(DATETIME_FORMAT);
    LocalStore.setAccessAndIdTokenReceivedTime(tokenReceivedAt);

    this.startAccessTokenRefreshTimer();

    if (this.onAccessTokenAvailable) {
      this.onAccessTokenAvailable();
    }
  };

  /**
   * Get-Set tokens/session functions
   * --------------------------------
   */
  setRefreshToken = (refreshToken: string) => {
    console.debug(logPrefix, 'setRefreshToken');
    LocalStore.setRefreshToken(refreshToken);
  };

  getRefreshToken = () => LocalStore.getRefreshToken();

  setIdToken = (idToken: string) => {
    console.debug(logPrefix, 'setIdToken');
    LocalStore.setIdToken(idToken);
  };

  getIdToken = () => LocalStore.getIdToken();

  setAccessToken = (accessToken: string) => {
    console.debug(logPrefix, 'setAccessToken');
    LocalStore.setAccessToken(accessToken);
  };

  getAccessToken = () => LocalStore.getAccessToken();

  setSession = (session: string) => {
    console.debug(logPrefix, 'setSession');
    LocalStore.setSession(session);
  };

  setUsername = (username: string) => {
    console.debug(logPrefix, 'setUsername');
    LocalStore.setUsername(username);
  };

  setUserAttributes = (userAttributes: IUserAttributesST) => {
    LocalStore.setUserAttributes(userAttributes);
  };

  getUserAttributes = () => LocalStore.getUserAttributes();

  /**
   * Token expiration functions
   * --------------------------------
   */

  /**
   * Returns expiration time of Refresh Token
   */
  private refreshTokenExpiresAt = (): string | null => {
    if (!this.getAccessToken && !this.getIdToken()) return null;

    const authData = LocalStore.getAuthData();
    const refreshTokenReceivedAt = LocalStore.getRefreshTokenReceivedTime();

    return moment(refreshTokenReceivedAt)
      .utc()
      .add(
        authData?.user_pool_description?.refresh_token_validity,
        authData?.user_pool_description?.token_validity_units.RefreshToken,
      )
      .format(DATETIME_FORMAT);
  };

  /**
   * Returns expiration time of Access and Id Tokens
   */
  private accessAndIdTokenExpiresAt = (): string | null => {
    if (!this.getAccessToken && !this.getIdToken()) return null;

    const authData = LocalStore.getAuthData();
    const authTokenReceivedAt = LocalStore.getAccessAndIdTokenReceivedTime();
    const amount = authData?.user_pool_description?.access_token_validity;
    const unit = authData?.user_pool_description?.token_validity_units.AccessToken;

    if (!amount || !unit) {
      console.debug(logPrefix, 'accessAndIdTokenExpiresAt', 'amount or unit is undefined');
      return moment.utc().subtract(1, 'minutes').format(DATETIME_FORMAT);
    }

    return moment(authTokenReceivedAt)
      .utc()
      .add(amount, unit)
      .subtract(amount * ACCESS_AND_ID_TOKENS_LIFESPAN_REDUCER, unit)
      .format(DATETIME_FORMAT);
  };

  /**
   * Get the access and id tokens remaining lifetime, in milliseconds
   * @returns ms to the expiration of the tokens (0 if already expired, -1 if tokens are not available)
   */
  private getAccessTokenExpirationTimeInMs = () => {
    const currentDate = moment().utc().format(DATETIME_FORMAT);
    const accessAndIdTokensExpirationDate = this.accessAndIdTokenExpiresAt();

    if (!accessAndIdTokensExpirationDate) {
      return -1;
    }

    const accessAndIdTokensExpireIn =
      moment(accessAndIdTokensExpirationDate).diff(currentDate) > 0
        ? moment(accessAndIdTokensExpirationDate).diff(currentDate)
        : 0;

    return accessAndIdTokensExpireIn;
  };

  /**
   * Get the refresh token remaining lifetime, in milliseconds
   * @returns ms to the expiration of the token (0 if already expired, -1 if token is not available)
   */
  private getRefreshTokenExpirationTimeInMs = () => {
    const currentDate = moment().utc().format(DATETIME_FORMAT);
    const refreshTokenExpirationDate = this.refreshTokenExpiresAt();

    if (!refreshTokenExpirationDate) {
      return -1;
    }

    const refreshTokenExpireIn =
      moment(refreshTokenExpirationDate).diff(currentDate) > 0
        ? moment(refreshTokenExpirationDate).diff(currentDate)
        : 0;

    return refreshTokenExpireIn;
  };

  /**
   * Checks if Access and Id Tokens are expired
   */
  areAccessAndIdTokensExpired = (): boolean => {
    const lp = getLogPrefixForType('FUNCTION', 'areAccessAndIdTokensExpired');

    const expiresAt = this.accessAndIdTokenExpiresAt();
    const currentDate = moment().utc().format(DATETIME_FORMAT);
    const areTokensExpired = moment(currentDate).isAfter(expiresAt);

    if (areTokensExpired) {
      console.debug(
        `${logPrefix} ${lp}`,
        `tokens EXPIRED at: ${expiresAt}, current time: ${currentDate}`,
      );
    } else {
      console.debug(
        `${logPrefix} ${lp}`,
        `tokens VALID until: ${expiresAt}, current time: ${currentDate}`,
      );

      console.debug('refresh token expires in: ', this.getRefreshTokenExpirationTimeInMs());
      console.debug('access and id tokens expires in: ', this.getAccessTokenExpirationTimeInMs());
    }

    return areTokensExpired;
  };

  /**
   * Data from tokens
   * --------------------------------
   */

  userGroupsFromAccessToken = (): USER_GROUPS[] | undefined => {
    const accessToken: string | null = this.getAccessToken();

    if (!accessToken) {
      return [];
    }
    const decodedJwtData = this.decodedJWTFromAccessToken(accessToken);

    return decodedJwtData['cognito:groups'];
  };

  hashedUsernameFromAccessToken = (): string | undefined => {
    const accessToken: string | null = this.getAccessToken();
    if (!accessToken) {
      return '';
    }
    const decodedJwtData = this.decodedJWTFromAccessToken(accessToken);
    return decodedJwtData?.username;
  };

  decodedJWTFromAccessToken = (
    accessToken: string,
  ): { username: string; ['cognito:groups']: USER_GROUPS[] } => {
    try {
      const jwtData = accessToken?.split('.')[1];
      const decodedJwtJsonData = window.atob(jwtData);
      return JSON.parse(decodedJwtJsonData);
    } catch {
      return { username: '', 'cognito:groups': [] };
    }
  };

  /**
   * Verify whether the user has access
   * @returns true if the user has access
   */
  userHaveAccess = (): boolean => {
    // In order to have access the user must have an access token and that token needs to be valid.
    const areTokensValid = !!this.getAccessToken() && !this.areAccessAndIdTokensExpired();
    console.debug(logPrefix, `user access check -> ${areTokensValid}`);
    return areTokensValid;
  };

  /**
   * Function for logging out user
   */
  logoutUser = () => {
    LocalStore.removeAuthData();
    window.clearTimeout(this.refreshTokenTimeoutId);
    window.clearTimeout(this.accessAndIdTokenTimeoutId);
    this.refreshTokenTimeoutId = undefined;
    this.accessAndIdTokenTimeoutId = undefined;
  };

  private accessAndIdTokenRefreshHandler = () => {
    console.debug(logPrefix, 'startAccessTokenRefreshTimer', 'refreshing tokens');

    const usernameHashed: string | undefined = this.hashedUsernameFromAccessToken();
    const refreshToken: string | null = this.getRefreshToken();

    if (!usernameHashed || !refreshToken) {
      console.trace(
        logPrefix,
        'startAccessTokenRefreshTimer',
        'username or refreshToken are undefined',
        {
          username: usernameHashed,
          refreshToken,
          route: window.location.pathname,
        },
      );
      localStorage.clear();
      return null;
    }

    return this.refreshAccessAndIdToken(usernameHashed, refreshToken).then((r) => {
      this.setNewAccessAndIdToken(r.data);
    });
  };

  private startAccessTokenRefreshTimer = () => {
    const accessAndIdTokenExpireInMs = this.getAccessTokenExpirationTimeInMs();

    console.debug(logPrefix, 'startAccessTokenRefreshTimer', accessAndIdTokenExpireInMs);

    this.accessAndIdTokenTimeoutId = window.setTimeout(
      this.accessAndIdTokenRefreshHandler,
      accessAndIdTokenExpireInMs,
    );
  };

  private startRefreshTokenExpirationTimer = () => {
    const refreshTokenExpireInMs = this.getRefreshTokenExpirationTimeInMs();

    console.debug(logPrefix, 'startRefreshTokenExpirationTimer', refreshTokenExpireInMs);

    this.refreshTokenTimeoutId = window.setTimeout(() => {
      console.debug(logPrefix, 'startRefreshTokenExpirationTimer', 'open signin modal');

      if (this.onRefreshTokenExpiration) {
        this.onRefreshTokenExpiration();
      }
    }, refreshTokenExpireInMs);
  };
}
