import { IVeritySlotStatusStateST } from 'codegen/location_information';

export const getImageText = ({
  verityState,
  isLoadingImageUrls,
  imageUrlsLength,
  numberOfImageUrlsRequested,
}: {
  verityState?: IVeritySlotStatusStateST;
  isLoadingImageUrls: boolean;
  imageUrlsLength: number;
  numberOfImageUrlsRequested: number;
}): string => {
  if (!verityState) {
    return 'Images are not available because Verity has no data for this location.';
  }

  if (isLoadingImageUrls) {
    return 'Checking for images';
  }

  if (numberOfImageUrlsRequested > 0) {
    return 'Waiting for images to download';
  }

  if (imageUrlsLength === 0 && numberOfImageUrlsRequested === 0) {
    return 'There are no images available for this location.';
  }

  return '';
};
