import { Vec6 } from 'shared/map-container/MapContainer.model';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import { toFixed2 } from 'utils/numberFormatting';
import { ImperialBottomLeftDimensions } from '../../../droneZone.model';
import { imperialFormatter } from './imperialFormatter';
import { convertAllFeetToMeters } from './convertAllFeetToMeters';

export const validateSizeAndPositionFitsWithinWorldBox = ({
  imperialZoneVectors,
  facilityVectors,
  fieldKey,
}: {
  imperialZoneVectors: ImperialBottomLeftDimensions;
  facilityVectors: Vec6;
  fieldKey: keyof ImperialBottomLeftDimensions;
}): string | null => {
  const {
    w: imperialWidth,
    l: imperialLength,
    h: imperialHeight,
    minX: imperialMinX,
    minY: imperialMinY,
    minZ: imperialMinZ,
    maxZ: imperialMaxZ,
  } = imperialZoneVectors;

  const {
    minX: facilityMinX,
    minY: facilityMinY,
    maxX: facilityMaxX,
    maxY: facilityMaxY,
    minZ: facilityMinZ,
    maxZ: facilityMaxZ,
  } = mapFacilityVectors(facilityVectors);

  const {
    w: zoneWidth,
    l: zoneLength,
    h: zoneHeight,
    minX: zoneMinX,
    minY: zoneMinY,
    minZ: zoneMinZ,
    maxZ: zoneMaxZ,
  } = convertAllFeetToMeters(imperialZoneVectors);

  const MIN_DIFF_DELTA = 0.01;
  const MIN_DIFF_INCH = 0.0256;

  switch (fieldKey) {
    case 'w': {
      const validationMaxX = zoneMinX + zoneWidth;
      const deltaX = validationMaxX - facilityMaxX;

      if (zoneWidth < 0) {
        return '"Width" must be a positive number';
      }

      if (toFixed2(deltaX) >= MIN_DIFF_DELTA) {
        return `"Width" of ${imperialWidth} places this zone outside of the facility by ${imperialFormatter(
          deltaX < MIN_DIFF_INCH ? MIN_DIFF_INCH : deltaX,
        )}`;
      }

      return null;
    }
    case 'l': {
      const validationMaxY = zoneMinY + zoneLength;
      const deltaY = validationMaxY - facilityMaxY;

      if (zoneLength < 0) {
        return '"Length" must be a positive number';
      }

      if (toFixed2(deltaY) >= MIN_DIFF_DELTA) {
        return `"Length" of ${imperialLength} places this zone outside of the facility by ${imperialFormatter(
          deltaY < MIN_DIFF_INCH ? MIN_DIFF_INCH : deltaY,
        )}`;
      }

      return null;
    }
    case 'h': {
      const validationMaxZ = zoneMinZ + zoneHeight;
      const deltaZ = validationMaxZ - facilityMaxZ;

      if (zoneHeight < 0) {
        return '"Height" must be a positive number';
      }

      if (toFixed2(deltaZ) >= MIN_DIFF_DELTA) {
        return `"Height" of ${imperialHeight} places this zone outside of the facility by ${imperialFormatter(
          deltaZ < MIN_DIFF_INCH ? MIN_DIFF_INCH : deltaZ,
        )}`;
      }

      return null;
    }
    case 'minX': {
      const validationMaxX = zoneMinX + zoneWidth;
      const isBelowMin = toFixed2(zoneMinX - facilityMinX) <= -MIN_DIFF_DELTA;
      const isAboveMax = toFixed2(validationMaxX - facilityMaxX) >= MIN_DIFF_DELTA;

      if (!isBelowMin && !isAboveMax) {
        return null;
      }

      const deltaMinX = facilityMinX - zoneMinX;
      const deltaMaxX = validationMaxX - facilityMaxX;
      const validationValue = isBelowMin ? deltaMinX : deltaMaxX;

      return `"X-Position" of ${imperialMinX} places this zone outside of the facility by ${imperialFormatter(
        validationValue < MIN_DIFF_INCH ? MIN_DIFF_INCH : validationValue,
      )}`;
    }
    case 'minY': {
      const validationMaxY = zoneMinY + zoneLength;
      const isBelowMin = toFixed2(zoneMinY - facilityMinY) <= -MIN_DIFF_DELTA;
      const isAboveMax = toFixed2(validationMaxY - facilityMaxY) >= MIN_DIFF_DELTA;

      if (!isBelowMin && !isAboveMax) {
        return null;
      }

      const deltaMinY = facilityMinY - zoneMinY;
      const deltaMaxY = validationMaxY - facilityMaxY;
      const validationValue = isBelowMin ? deltaMinY : deltaMaxY;

      return `"Y-Position" of ${imperialMinY} places this zone outside of the facility by ${imperialFormatter(
        validationValue < MIN_DIFF_INCH ? MIN_DIFF_INCH : validationValue,
      )}`;
    }

    case 'minZ': {
      const isBelowMin = toFixed2(zoneMinZ - facilityMinZ) <= -MIN_DIFF_DELTA;
      const isAboveMax = toFixed2(zoneMinZ - facilityMaxZ) >= MIN_DIFF_DELTA;
      const isAboveZoneMax = zoneMinZ > zoneMaxZ;

      if (!isAboveZoneMax && !isBelowMin && !isAboveMax) {
        return null;
      }

      if (isAboveZoneMax) {
        return '"Bottom" value cannot be higher than the "top" value';
      }

      const deltaMinZ = facilityMinZ - zoneMinZ;
      const deltaMaxZ = zoneMinZ - facilityMaxZ;

      if (isBelowMin) {
        return `"Bottom" of ${imperialMinZ} places this zone below the facility by ${imperialFormatter(
          deltaMinZ < MIN_DIFF_INCH ? MIN_DIFF_INCH : deltaMinZ,
        )}`;
      }

      return `"Bottom" of ${imperialMinZ} places this zone above the facility by ${imperialFormatter(
        deltaMaxZ < MIN_DIFF_INCH ? MIN_DIFF_INCH : deltaMaxZ,
      )}`;
    }
    case 'maxZ': {
      const isBelowMin = toFixed2(zoneMaxZ - facilityMinZ) <= -MIN_DIFF_DELTA;
      const isAboveMax = toFixed2(zoneMaxZ - facilityMaxZ) >= MIN_DIFF_DELTA;
      const isBelowZoneMin = zoneMaxZ < zoneMinZ;

      if (isBelowZoneMin) {
        return '"Top" value cannot be lower than the "bottom" value';
      }

      if (isAboveMax) {
        const deltaMaxZAbove = zoneMaxZ - facilityMaxZ;

        return `"Top" of ${imperialMaxZ} places this zone above the facility by ${imperialFormatter(
          deltaMaxZAbove < MIN_DIFF_INCH ? MIN_DIFF_INCH : deltaMaxZAbove,
        )}`;
      }

      if (isBelowMin) {
        const deltaMaxZBelow = facilityMinZ - zoneMaxZ;

        return `"Top" of ${imperialMaxZ} places this zone below the facility by ${imperialFormatter(
          deltaMaxZBelow < MIN_DIFF_INCH ? MIN_DIFF_INCH : deltaMaxZBelow,
        )}`;
      }

      return null;
    }

    default:
      return null;
  }
};
