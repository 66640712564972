import { Outlet } from 'react-router-dom';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { IFacilityLevelContext } from 'store/FacilityLevelStore/facilityLevelStore.model';

type RemotePickingLocationsOutletProviderContext = {
  facility: IFacilityLevelContext['stateFacilityLevel']['facilityData'];
};

export const RemotePickingLocationsOutletProvider = () => {
  const { stateFacilityLevel } = useFacilityLevelStore();

  const facility = stateFacilityLevel?.facilityData ?? {};

  return <Outlet context={{ facility } satisfies RemotePickingLocationsOutletProviderContext} />;
};
