import { Box, LinearProgress, Tooltip } from '@mui/material';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { useStyles } from './warehouseStatsBar.styles';

export const WarehouseStatsBar = ({
  label,
  description,
  value,
  unit,
  total,
  color,
  showProgressBar,
  gridState,
  onGridStateSelect,
}: {
  label: string;
  description?: string;
  value?: number;
  unit: string;
  total: number;
  color?: string;
  showProgressBar?: boolean;
  gridState?: GridInitialStatePremium;
  onGridStateSelect?: (state: GridInitialStatePremium) => void;
}) => {
  const { classes } = useStyles();

  const changeGridState = (state: GridInitialStatePremium) => {
    if (onGridStateSelect) {
      onGridStateSelect(state);
    }
  };

  const tooltip = gridState ? (
    <Box component="div" display="flex" flexDirection="column" gap={2} sx={{ fontSize: '14px' }}>
      <Box component="div">{description}</Box>
      <Box component="div">
        {value} {unit}
      </Box>
      <Box component="div">Click to filter the table</Box>
    </Box>
  ) : (
    `${description} (${value} ${unit})`
  );

  return (
    <div className={classes.wrapper}>
      <Tooltip title={tooltip}>
        <Box
          component="div"
          display="flex"
          fontSize="14px"
          flexDirection="row"
          gap={1}
          onClick={() => {
            if (gridState) {
              changeGridState(gridState);
            }
          }}
          sx={{ cursor: gridState ? 'pointer' : 'default' }}
        >
          {!showProgressBar && (
            <Box
              className={classes.legendIcon}
              backgroundColor={showProgressBar ? undefined : color}
            />
          )}

          <Box className={classes.label}>
            <Box
              component="span"
              fontWeight="bold"
              textAlign="right"
              data-testid="c-stats-bar-percent"
              flex="0 0 auto"
            >
              {((100 * (value || 0)) / total).toFixed(0)}%
            </Box>

            <Box component="span" textAlign="left" flex="0 1 auto" minWidth="0">
              {label}
            </Box>
          </Box>

          {showProgressBar && (
            <Box
              paddingLeft="10px"
              component="div"
              marginLeft="auto"
              fontWeight="bold"
              data-testid="c-stats-bar-value"
              flex="0 1 auto"
            >
              {value}
            </Box>
          )}
        </Box>
      </Tooltip>

      {showProgressBar && (
        <div>
          <LinearProgress
            sx={{
              height: '4px',
              borderRadius: '8px',
              backgroundColor: '#E8EAED',

              '& .MuiLinearProgress-bar': {
                borderRadius: '8px',
                backgroundColor: color || 'gold',
              },
            }}
            variant="determinate"
            value={(100 * (value || 0)) / total}
          />
        </div>
      )}
    </div>
  );
};
