import { IReportST } from 'codegen/report';
import {
  REPORT_ACTIONS,
  ONGOING_REPORT_ACTIONS,
  DISPATCHED_AND_ONGOING_REPORT_ACTIONS,
} from 'common/Actions/actionTypes';
import { isReportFinishedIncomplete } from 'common/functions/reportFunctions';
import { getReqParamsStringForTablesFromInventoryRequest } from 'common/functions/requestParameterFunctions';
import { convertRruleToUIComponents, getRecurrenceText } from 'common/functionsReportSpec';
import { ISSUE_TYPES } from 'common/issueTypesAndStates';
import { REPORT_STATES } from 'common/reportStates';
import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { IReportRequestActionInternal } from 'interfaces';
import { createElement } from 'react';
import { IconColor } from 'ts-types/IconColor';
import { formatReportProgress } from '../../../reportsFunctions';
import { getReportPriorityIcons } from '../../../utils/getReportPriorityIcons';
import { getAllIncludedLocations } from '../ReportStore';
import { Report } from '../../../Reports.model';
import { getInsightTypeLabel } from './getInsightTypeLabel.util';

export const generateRowData = (row: IReportST) => {
  const rowData = {} as Report;
  rowData.rawData = row;

  const displayUpdateReportAction = userHasPermission(PERMISSION.UPDATE_REPORTS);
  const displayAbortReportAction = userHasPermission(PERMISSION.ABORT_ONGOING_REPORTS);
  const displayRestoreAction =
    row.state === REPORT_STATES.DELETED &&
    userHasPermission(PERMISSION.ARCHIVED_REPORTS_MANAGEMENT);
  const displayArchiveAction =
    row.state !== REPORT_STATES.DELETED &&
    row.state !== REPORT_STATES.ONGOING &&
    row.state !== REPORT_STATES.SCHEDULED &&
    userHasPermission(PERMISSION.ARCHIVED_REPORTS_MANAGEMENT);

  const { isRecurring } = convertRruleToUIComponents(row?.request?.rrule);
  const inventoryOperations: IReportRequestActionInternal = {
    data: row,
    actions: [],
  };

  const priority = getReportPriorityIcons(row.request.priority);

  if (displayRestoreAction) {
    inventoryOperations.actions.push({ label: REPORT_ACTIONS.RESTORE, disabled: false });
  }

  if (displayArchiveAction) {
    inventoryOperations.actions.push({ label: REPORT_ACTIONS.ARCHIVE, disabled: false });
  }

  // Ongoing reports can be updated by certain users
  // note: reports to review are technically ongoing, so this will also apply to those
  if (row.state === REPORT_STATES.ONGOING && displayUpdateReportAction) {
    inventoryOperations.actions.push({ label: ONGOING_REPORT_ACTIONS.UPDATE, disabled: false });
  }

  // Ongoing and dispatched reports can be aborted by certain users
  const isReportAbortable =
    [REPORT_STATES.ONGOING, REPORT_STATES.SCHEDULED].includes(row.state as REPORT_STATES) &&
    displayAbortReportAction;

  if (isReportAbortable) {
    inventoryOperations.actions.push({
      label: DISPATCHED_AND_ONGOING_REPORT_ACTIONS.ABORT,
      disabled: false,
    });
  }

  rowData.id = row.report_id;
  rowData.reportType = row.request.report_spec.report_spec_name;
  rowData.reportName = row.request.report_name || '-';
  rowData.requestParams = getReqParamsStringForTablesFromInventoryRequest(row.request);
  rowData.scannedLocations = row.location_data_count.SCANNED;
  rowData.completion = row.completion;
  rowData.scheduledBy = row.request.requesting_user_email || 'n.a.';
  rowData.scheduledFor = row.scheduled_for_utc;
  rowData.recurrence = getRecurrenceText(
    row.request.report_spec.report_spec_name,
    isRecurring,
    row.request.recurrence_description,
  );
  rowData.startedAt = row.started_at || '-';
  rowData.finishedAt = row.finished_at || '-';
  rowData.allLocations = isReportFinishedIncomplete(row)
    ? `${row.location_data_count.SCANNED}/${getAllIncludedLocations(row)}`
    : getAllIncludedLocations(row).toString();
  rowData.locationsToScan = row.num_locations_to_inspect;
  rowData.progress = formatReportProgress(row.progress);
  rowData.issues =
    row.issue_count.totals[ISSUE_TYPES.WMS_BARCODE_VERITY_EMPTY] +
    row.issue_count.totals[ISSUE_TYPES.WMS_BARCODE_NOT_EQUAL_VERITY_BARCODE] +
    row.issue_count.totals[ISSUE_TYPES.WMS_EMPTY_VERITY_BARCODE] +
    row.issue_count.totals[ISSUE_TYPES.WMS_EMPTY_VERITY_NOTEMPTY];
  rowData.status = row.state;
  rowData.priority =
    userHasPermission(PERMISSION.PRIORITY_PICKER_INTERNAL) && row.request.priority > 1 ? (
      <span>{row.request.priority}</span>
    ) : (
      createElement(priority.icon, { color: priority.color as IconColor })
    );

  rowData.actions = inventoryOperations;
  rowData.allowRedirection = true;
  rowData.insightType = row.insight_type;
  rowData.insightTypeLabel = getInsightTypeLabel(row.insight_type); // TODO:Move formatting to cell formatter

  return rowData;
};
