import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { getReportService } from 'services/services';
import { NETWORK_REQUEST_SETTINGS } from 'common/settings';
import pLimit from 'p-limit';
import { useCallback, useMemo } from 'react';
import { IReportDataST } from 'codegen/report';
import { getBatchStartSlotName } from 'udb/inventory/features/premium-grid/utils/getBatchStartSlotName';
import { transformReportData } from '../utils/transformReportData';

const requestPageSize = NETWORK_REQUEST_SETTINGS.REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB;
const REPORT_SUMMARY_QUERY_KEY = 'report-summary';
const REPORT_LOCATIONS_DATA_QUERY_KEY = 'report-locations-data';

const MAX_CONCURRENT_REQUESTS = 10;
const limit = pLimit(MAX_CONCURRENT_REQUESTS);

export const useReportData = ({ systemId, reportId }: { systemId: string; reportId: string }) => {
  const queryClient = useQueryClient();

  const { data: reportSummary, isLoading: isLoadingSummary } = useQuery({
    queryKey: [REPORT_SUMMARY_QUERY_KEY, reportId],
    queryFn: () => getReportService().getReportSummary(systemId, reportId),
    select: (data) => data.data,
  });

  const locationNames = useMemo(
    () => reportSummary?.item?.locations ?? [],
    [reportSummary?.item?.locations],
  );
  const requestChunk: { fromLocation: string; toLocation: string }[] = [];

  const getToLocation = (i: number) =>
    i + requestPageSize - 1 > locationNames.length
      ? locationNames[locationNames.length - 1]
      : locationNames[i + requestPageSize - 1];

  for (let i = 0; i < locationNames.length; i += requestPageSize) {
    requestChunk.push({
      fromLocation: locationNames[i],
      toLocation: getToLocation(i),
    });
  }

  const allLocationsQuery = useQueries({
    queries: requestChunk.map((chunk) => ({
      queryKey: [REPORT_LOCATIONS_DATA_QUERY_KEY, chunk.fromLocation],
      queryFn: () =>
        limit(() =>
          getReportService().getReportData(
            systemId,
            reportId,
            chunk.fromLocation,
            chunk.toLocation,
          ),
        ),

      staleTime: Infinity,
    })),
  });

  const isLoadingLocations = allLocationsQuery.some((result) => result.isFetching);

  const locationData = allLocationsQuery
    .map((result) =>
      Object.values(result.data?.data ?? {})
        .filter(Boolean)
        .flatMap((data: IReportDataST) => transformReportData(data)),
    )
    .flat();

  const invalidateReportLocationData = useCallback(
    (slotName: string) => {
      const startSlotName = getBatchStartSlotName(slotName, locationNames, requestPageSize);
      queryClient.invalidateQueries({
        queryKey: [REPORT_LOCATIONS_DATA_QUERY_KEY, startSlotName],
      });
    },
    [queryClient, locationNames],
  );

  return {
    locationData,
    isLoadingReportLocations: isLoadingLocations || isLoadingSummary,
    invalidateReportLocationData,
  };
};
