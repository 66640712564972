
      (function () {
        var appEnv = import.meta.env.VITE_APP_ENV;

        var pendoApiKey = import.meta.env.VITE_PENDO_API_KEY;

        if (pendoApiKey && appEnv == 'dev') {
          (function (apiKey) {
            (function (p, e, n, d, o) {
              var v, w, x, y, z;
              o = p[d] = p[d] || {};
              o._q = o._q || [];
              v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
              for (w = 0, x = v.length; w < x; ++w)
                (function (m) {
                  o[m] =
                    o[m] ||
                    function () {
                      o._q[m === v[0] ? 'unshift' : 'push'](
                        [m].concat([].slice.call(arguments, 0)),
                      );
                    };
                })(v[w]);
              y = e.createElement(n);
              y.async = !0;
              y.src = 'https://cdn.eu.pendo.io/agent/static/' + pendoApiKey + '/pendo.js';
              z = e.getElementsByTagName(n)[0];
              z.parentNode.insertBefore(y, z);
            })(window, document, 'script', 'pendo');
          })(pendoApiKey);
        } else {
          console.info('Pendo Api ID is not defined. Skipping Pendo script injection.');
        }
      })();
    