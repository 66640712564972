import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useLocationModalStyle = makeStyles()((theme: Theme) => ({
  dialog: {
    minWidth: '96%',
    margin: theme.spacing(3),
    backgroundColor: 'transparent',
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.offWhite.main,
      borderRadius: theme.spacing(1),
    },
  },
  dialogTitle: {
    textAlign: 'left',
    padding: theme.spacing(0, 0, 0, 0),
  },
  helpButton: {
    position: 'absolute',
    right: theme.spacing(6),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    marginBottom: theme.spacing(2),
  },
  barcodesContainer: {
    overflowX: 'auto',
  },
  barcodesContainerItem: {
    minWidth: 1100,
  },
}));
