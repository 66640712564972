import {
  ILocationDataST,
  IVeritySlotStatusST,
  IVeritySlotStatusStateST,
} from 'codegen/warehouse_status';
import { getIncludedExcludedValidInvalid } from './getLocationDataStats';

export const getLocationsByState = (locationData: ILocationDataST[]) => {
  // NOTE: we're temporarily storing the locations instead of directly the count
  // so, that in case of need we can debug which locations is mapped where.
  // eno 2024-11-11
  const amended: { [key in IVeritySlotStatusStateST | 'NoState']: ILocationDataST[] } = {
    [IVeritySlotStatusStateST.Barcode]: [],
    [IVeritySlotStatusStateST.Empty]: [],
    [IVeritySlotStatusStateST.NoResult]: [],
    [IVeritySlotStatusStateST.NotEmpty]: [],
    [IVeritySlotStatusStateST.Unreachable]: [],
    NoState: [],
  };

  const notAmended: { [key in IVeritySlotStatusStateST | 'NoState']: ILocationDataST[] } = {
    [IVeritySlotStatusStateST.Barcode]: [],
    [IVeritySlotStatusStateST.Empty]: [],
    [IVeritySlotStatusStateST.NoResult]: [],
    [IVeritySlotStatusStateST.NotEmpty]: [],
    [IVeritySlotStatusStateST.Unreachable]: [],
    NoState: [],
  };

  locationData.forEach((l) => {
    const user_overrides = l.verity_status
      ? (l.verity_status as IVeritySlotStatusST).user_overrides
      : null;
    const isAmended = user_overrides && user_overrides.length !== 0;
    if (isAmended) {
      const lastOverride = user_overrides[user_overrides.length - 1];
      if (lastOverride.state === 'BARCODE') {
        amended[IVeritySlotStatusStateST.Barcode].push(l);
      } else if (lastOverride.state === 'EMPTY') {
        amended[IVeritySlotStatusStateST.Empty].push(l);
      } else if (lastOverride.state === 'NOT_EMPTY') {
        amended[IVeritySlotStatusStateST.NotEmpty].push(l);
      } else if (lastOverride.state === 'NO_RESULT') {
        amended[IVeritySlotStatusStateST.NoResult].push(l);
      } else if (lastOverride.state === 'UNREACHABLE') {
        amended[IVeritySlotStatusStateST.Unreachable].push(l);
      } else {
        amended.NoState.push(l);
      }
    } else if ((l.verity_status as IVeritySlotStatusST)?.state === 'BARCODE') {
      notAmended[IVeritySlotStatusStateST.Barcode].push(l);
    } else if ((l.verity_status as IVeritySlotStatusST)?.state === 'EMPTY') {
      notAmended[IVeritySlotStatusStateST.Empty].push(l);
    } else if ((l.verity_status as IVeritySlotStatusST)?.state === 'NOT_EMPTY') {
      notAmended[IVeritySlotStatusStateST.NotEmpty].push(l);
    } else if ((l.verity_status as IVeritySlotStatusST)?.state === 'NO_RESULT') {
      notAmended[IVeritySlotStatusStateST.NoResult].push(l);
    } else if ((l.verity_status as IVeritySlotStatusST)?.state === 'UNREACHABLE') {
      notAmended[IVeritySlotStatusStateST.Unreachable].push(l);
    } else {
      notAmended.NoState.push(l);
    }
  });

  return { amended, notAmended };
};

export const getLocationUsage = (locationData: ILocationDataST[]) => {
  const { included } = getIncludedExcludedValidInvalid(locationData);
  const { amended, notAmended } = getLocationsByState(included.all);

  const empty = notAmended.EMPTY;
  const barcodes = notAmended.BARCODE;
  const unreachable = notAmended.UNREACHABLE;
  const notEmpty = notAmended.NOT_EMPTY;
  const noResult = notAmended.NO_RESULT;
  const noState = notAmended.NoState;

  const emptyAmended = amended.EMPTY;
  const barcodesAmended = amended.BARCODE;
  const unreachableAmended = amended.UNREACHABLE;
  const notEmptyAmended = amended.NOT_EMPTY;
  const noResultAmended = amended.NO_RESULT;

  const totalAmended =
    emptyAmended.length +
    barcodesAmended.length +
    unreachableAmended.length +
    notEmptyAmended.length +
    noResultAmended.length;

  const total =
    empty.length +
    barcodes.length +
    unreachable.length +
    notEmpty.length +
    noResult.length +
    noState.length +
    totalAmended;
  return {
    empty: empty.length,
    barcodes: barcodes.length,
    unreachable: unreachable.length,
    notEmpty: notEmpty.length,
    noResult: noResult.length,
    noState: noState.length,
    amended: totalAmended,
    emptyAmended: emptyAmended.length,
    barcodesAmended: barcodesAmended.length,
    notEmptyAmended: notEmptyAmended.length,
    unreachableAmended: unreachableAmended.length,
    noResultAmended: noResultAmended.length,
    total,
  };
};
