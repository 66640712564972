import { useQuery } from '@tanstack/react-query';
import { facilityServices } from 'services/FacilityServices';
import { QueryNames } from 'ts-types/QueryNames';

export const useSlots = (systemId: string) =>
  useQuery({
    queryKey: [QueryNames.GET_FACILITY_SLOTS, systemId],
    queryFn: () => facilityServices.getFacilitySlots(systemId),
    staleTime: Infinity,
    select: ({ data }) => data.slots,
  });
