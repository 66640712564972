import { Button, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import { FormInput } from 'components/FormFields/FormInput';
import { FormInputImperial } from 'components/FormFields/FormInputImperial/FormInputImperial';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import type { DroneZone } from 'shared/map/model/drone-zone.model';
import type { DroneZoneFormProps, ImperialBottomLeftDimensions } from '../../droneZone.model';
import { useDroneZoneFormStyles } from '../../useDroneZoneFormStyles';
import { getLabel } from '../../utils/getLabel';
import { validateName } from '../../utils/validateName';
import { getHeight } from './utils/getHeight';
import { zoneTypeProps } from '../../defaults/droneZoneForm.defaults';
import { imperialFormatter } from './utils/imperialFormatter';
import { convertAllFeetToMeters } from './utils/convertAllFeetToMeters';
import { convertAllMetersToFeet } from './utils/convertAllMetersToFeet';
import { useValidateDroneZoneForm } from './hooks/useValidateDroneZoneForm';

export const ImperialDroneZoneForm = <T extends DroneZone>({
  droneZone,
  worldBox,
  onCancel,
  onUpdate,
  onSubmit,
}: DroneZoneFormProps<T>) => {
  const { classes } = useDroneZoneFormStyles();

  const imperialDroneZone = {
    ...droneZone,
    sizeAndPosition: convertAllMetersToFeet(droneZone.sizeAndPosition),
  };

  const [height, setHeight] = useState<string>(
    getHeight(imperialDroneZone.sizeAndPosition.minZ, imperialDroneZone.sizeAndPosition.maxZ),
  );

  const originalZone = useRef(imperialDroneZone);
  const validate = useValidateDroneZoneForm(droneZone.type, onUpdate);

  const {
    minX: facilityMinX,
    maxX: facilityMaxX,
    minY: facilityMinY,
    maxY: facilityMaxY,
    minZ: facilityMinZ,
    maxZ: facilityMaxZ,
  } = mapFacilityVectors(worldBox);

  const handleSubmit = (
    zoneValues: T & {
      sizeAndPosition: ImperialBottomLeftDimensions;
    },
  ) =>
    onSubmit({
      ...zoneValues,
      sizeAndPosition: convertAllFeetToMeters(zoneValues.sizeAndPosition),
    });

  const inputProps = { step: 0.5, min: 1 };
  const maxHeight = facilityMaxZ - facilityMinZ;

  return (
    <Formik
      enableReinitialize
      initialValues={imperialDroneZone}
      onSubmit={handleSubmit}
      validate={validate(worldBox)}
    >
      {({ values, handleChange, isValid, isSubmitting }) => (
        <Form noValidate className={classes.form}>
          <Typography variant="h1" className={classes.formHeader}>
            {zoneTypeProps[imperialDroneZone.type].title}
          </Typography>

          <div className={classes.formBody}>
            <FormInput
              name="name"
              label={zoneTypeProps[imperialDroneZone.type].nameLabel}
              required
              fullWidth
              validate={(value) => validateName(value, imperialDroneZone.type)}
            />

            <FormInput name="description" label="Description" multiline fullWidth />

            {!zoneTypeProps[imperialDroneZone.type].isHeightEnabled ? (
              <div className={classes.formSection}>
                <FormInputImperial
                  inputProps={{ ...inputProps, min: 0, max: facilityMaxZ }}
                  name="sizeAndPosition.minZ"
                  label={getLabel({
                    baseLabel: 'Bottom (ft)',
                    formatter: imperialFormatter,
                    max: facilityMaxZ,
                  })}
                  fullWidth
                  onChange={(e) => {
                    setHeight(getHeight(e.target.value, values.sizeAndPosition.maxZ));
                    handleChange(e);
                  }}
                />

                <FormInputImperial
                  inputProps={{ ...inputProps, min: 0, max: facilityMaxZ }}
                  name="sizeAndPosition.maxZ"
                  label={getLabel({
                    baseLabel: 'Top (ft)',
                    formatter: imperialFormatter,
                    max: facilityMaxZ,
                  })}
                  fullWidth
                  onChange={(e) => {
                    setHeight(getHeight(values.sizeAndPosition.minZ, e.target.value));
                    handleChange(e);
                  }}
                />
              </div>
            ) : null}

            <FormInputImperial
              inputProps={{ ...inputProps, max: maxHeight }}
              name="sizeAndPosition.h"
              label={getLabel({
                baseLabel: 'Height (ft)',
                formatter: imperialFormatter,
                max: zoneTypeProps[imperialDroneZone.type].isHeightEnabled ? maxHeight : undefined,
              })}
              fullWidth
              disabled={!zoneTypeProps[imperialDroneZone.type].isHeightEnabled}
              value={
                zoneTypeProps[imperialDroneZone.type].isHeightEnabled
                  ? values.sizeAndPosition.h
                  : height
              }
            />

            <FormInputImperial
              name="sizeAndPosition.w"
              label="Width (ft)"
              inputProps={{
                ...inputProps,
                max: facilityMaxX - values.sizeAndPosition.minX,
              }}
              fullWidth
            />

            <FormInputImperial
              name="sizeAndPosition.l"
              label="Length (ft)"
              inputProps={{
                ...inputProps,
                min: 0,
                max: facilityMaxY - values.sizeAndPosition.minY,
              }}
              fullWidth
            />

            <div className={classes.formSection}>
              <FormInputImperial
                name="sizeAndPosition.minX"
                inputProps={{
                  step: inputProps.step,
                  min: facilityMinX,
                  max: facilityMaxX - values.sizeAndPosition.w,
                }}
                label="X-Position (ft)"
                fullWidth
              />

              <FormInputImperial
                name="sizeAndPosition.minY"
                inputProps={{
                  step: inputProps.step,
                  min: facilityMinY,
                  max: facilityMaxY - values.sizeAndPosition.l,
                }}
                fullWidth
                label="Y-Position (ft)"
              />
            </div>
          </div>

          <div className={classes.formFooter}>
            <Button
              variant="outlined"
              sx={{ flex: 1, mr: 1 }}
              onClick={() => onCancel(originalZone.current)}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              sx={{ flex: 1 }}
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
