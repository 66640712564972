import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { WarehouseOccupancy } from './warehouse-status-overview-widgets/WarehouseOccupancy';
import { WarehouseCoverage } from './warehouse-status-overview-widgets/WarehouseCoverage';
import { WarehouseIssues } from './warehouse-status-overview-widgets/WarehouseIssues';
import { WarehouseStatusOverviewLoader } from './WarehouseStatusOverviewLoader';
import { useLocationData } from '../../warehouse-status-beta/hooks/useLocationData';

export const WarehouseStatusOverview = ({
  systemId,
  onGridStateSelect,
}: {
  systemId: string;
  onGridStateSelect: (state: GridInitialStatePremium) => void;
}) => {
  const { slots, isLoadingLocations, locationData } = useLocationData(systemId);

  const showLoadingPlaceholder = isLoadingLocations;
  const showSummaryCards = locationData && !showLoadingPlaceholder;

  return (
    <>
      {showLoadingPlaceholder && (
        <WarehouseStatusOverviewLoader alreadyLoaded={locationData?.length} total={slots?.length} />
      )}

      {showSummaryCards && (
        <>
          <WarehouseIssues locationData={locationData} onGridStateSelect={onGridStateSelect} />

          <WarehouseOccupancy locationData={locationData} onGridStateSelect={onGridStateSelect} />

          <WarehouseCoverage locationData={locationData} onGridStateSelect={onGridStateSelect} />
        </>
      )}
    </>
  );
};
