import findIndex from 'lodash/findIndex';

// variables, functions, configurations
import { TAB_NAMES } from '../settings';

interface IWarehouseTabParams {
  WAREHOUSE_TABLE_TABS: ITabItemProp[];
  numberOfIssues: number;
  numberOfIssuesSolved: number;
  numberOfAmended: number;
  numberOfIssuesSnoozed: number;
  numberOfIssuesPotential: number;
  numberOfIssuesInconclusive: number;
  numberOfIssuesInvalid: number;
  numberOfFullReport: number;
}

export const warehouseTabs = ({
  WAREHOUSE_TABLE_TABS,
  numberOfIssues,
  numberOfIssuesSolved,
  numberOfAmended,
  numberOfIssuesSnoozed,
  numberOfIssuesPotential,
  numberOfIssuesInconclusive,
  numberOfIssuesInvalid,
  numberOfFullReport,
}: IWarehouseTabParams) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let tabs: any[] = [];

  const numberOfItemsInTabs = {
    ISSUES: numberOfIssues,
    SOLVED: numberOfIssuesSolved,
    AMENDED: numberOfAmended,
    SNOOZED: numberOfIssuesSnoozed,
    'POTENTIAL I.': numberOfIssuesPotential,
    INCONCLUSIVE: numberOfIssuesInconclusive,
    INVALID: numberOfIssuesInvalid,
    'FULL REPORT': numberOfFullReport,
  };

  WAREHOUSE_TABLE_TABS &&
    WAREHOUSE_TABLE_TABS.forEach((tab) => {
      tabs = [
        ...tabs,
        {
          icon: tab.icon,
          label: `${tab.label} (${
            numberOfItemsInTabs[
              tab.label as
                | 'ISSUES'
                | 'SOLVED'
                | 'AMENDED'
                | 'SNOOZED'
                | 'POTENTIAL I.'
                | 'INCONCLUSIVE'
                | 'INVALID'
                | 'FULL REPORT'
            ]
          })`,
          show: tab.show,
        },
      ];
    });

  return tabs;
};

export interface ITabItemProp {
  icon: JSX.Element;
  label:
    | 'ISSUES'
    | 'SOLVED'
    | 'AMENDED'
    | 'SNOOZED'
    | 'POTENTIAL I.'
    | 'INCONCLUSIVE'
    | 'INVALID'
    | 'FULL REPORT';
  show: boolean;
}
interface IReportTabsParams {
  REPORT_TABLE_TABS: ITabItemProp[];
  numberOfIssues: number;
  numberOfAmended: number;
  numberOfIssuesSnoozed: number;
  numberOfIssuesPotential: number;
  numberOfIssuesInconclusive: number;
  numberOfIssuesInvalid: number;
  numberOfFullReport: number;
}

export const reportTabs = ({
  REPORT_TABLE_TABS,
  numberOfIssues,
  numberOfAmended,
  numberOfIssuesSnoozed,
  numberOfIssuesPotential,
  numberOfIssuesInconclusive,
  numberOfIssuesInvalid,
  numberOfFullReport,
}: IReportTabsParams) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let tabs: any[] = [];

  const numberOfItemsInTabs = {
    ISSUES: numberOfIssues,
    AMENDED: numberOfAmended,
    SNOOZED: numberOfIssuesSnoozed,
    'POTENTIAL I.': numberOfIssuesPotential,
    INCONCLUSIVE: numberOfIssuesInconclusive,
    INVALID: numberOfIssuesInvalid,
    'FULL REPORT': numberOfFullReport,
  };

  REPORT_TABLE_TABS &&
    REPORT_TABLE_TABS.forEach((tab) => {
      tabs = [
        ...tabs,
        {
          icon: tab.icon,
          label: `${tab.label} (${
            numberOfItemsInTabs[
              tab.label as
                | 'ISSUES'
                | 'AMENDED'
                | 'SNOOZED'
                | 'POTENTIAL I.'
                | 'INCONCLUSIVE'
                | 'INVALID'
                | 'FULL REPORT'
            ]
          })`,
          show: tab.show,
        },
      ];
    });

  return tabs;
};

interface IReviewReportTabsParams {
  REPORTS_FOR_REVIEW_TABLE_TABS: ITabItemProp[];
  numberOfLocationsToReview: number;
  numberOfOtherLocations: number;
}

export const reviewReportTabs = ({
  REPORTS_FOR_REVIEW_TABLE_TABS,
  numberOfLocationsToReview,
  numberOfOtherLocations,
}: IReviewReportTabsParams) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let tabs: any[] = [];

  const numberOfItemsInTabs = {
    'LOCATIONS TO REVIEW': numberOfLocationsToReview,
    'OTHER LOCATIONS': numberOfOtherLocations,
  };

  REPORTS_FOR_REVIEW_TABLE_TABS.forEach((tab) => {
    tabs = [
      ...tabs,
      {
        icon: tab.icon,
        label: `${tab.label} (${
          numberOfItemsInTabs[tab.label as 'LOCATIONS TO REVIEW' | 'OTHER LOCATIONS']
        })`,
        show: tab.show,
      },
    ];
  });

  return tabs;
};

export const warehouseTabsMappings = (WAREHOUSE_TABLE_TABS: ITabItemProp[]) => {
  const tabs = {
    issuesTab: findIndex(WAREHOUSE_TABLE_TABS, (tab) => tab.label === TAB_NAMES.REPORT.ISSUES),
    issuesSolvedTab: findIndex(
      WAREHOUSE_TABLE_TABS,
      (tab) => tab.label === TAB_NAMES.REPORT.SOLVED,
    ),
    amendedTab: findIndex(WAREHOUSE_TABLE_TABS, (tab) => tab.label === TAB_NAMES.REPORT.AMENDED),
    issuesSnoozedTab: findIndex(
      WAREHOUSE_TABLE_TABS,
      (tab) => tab.label === TAB_NAMES.REPORT.SNOOZED,
    ),
    issuesPotentialTab: findIndex(
      WAREHOUSE_TABLE_TABS,
      (tab) => tab.label === TAB_NAMES.REPORT['POTENTIAL I.'],
    ),
    issuesInconclusiveTab: findIndex(
      WAREHOUSE_TABLE_TABS,
      (tab) => tab.label === TAB_NAMES.REPORT.INCONCLUSIVE,
    ),
    issuesInvalidTab: findIndex(
      WAREHOUSE_TABLE_TABS,
      (tab) => tab.label === TAB_NAMES.REPORT.INVALID,
    ),
    fullReportTab: findIndex(
      WAREHOUSE_TABLE_TABS,
      (tab) => tab.label === TAB_NAMES.REPORT['FULL REPORT'],
    ),
  };

  return tabs;
};

export const reportTabsMappings = (REPORT_TABLE_TABS: ITabItemProp[]) => {
  const tabs = {
    issuesTab: findIndex(REPORT_TABLE_TABS, (tab) => tab.label === TAB_NAMES.REPORT.ISSUES),
    amendedTab: findIndex(REPORT_TABLE_TABS, (tab) => tab.label === TAB_NAMES.REPORT.AMENDED),
    issuesSnoozedTab: findIndex(REPORT_TABLE_TABS, (tab) => tab.label === TAB_NAMES.REPORT.SNOOZED),
    issuesPotentialTab: findIndex(
      REPORT_TABLE_TABS,
      (tab) => tab.label === TAB_NAMES.REPORT['POTENTIAL I.'],
    ),
    issuesInconclusiveTab: findIndex(
      REPORT_TABLE_TABS,
      (tab) => tab.label === TAB_NAMES.REPORT.INCONCLUSIVE,
    ),
    issuesInvalidTab: findIndex(REPORT_TABLE_TABS, (tab) => tab.label === TAB_NAMES.REPORT.INVALID),
    fullReportTab: findIndex(
      REPORT_TABLE_TABS,
      (tab) => tab.label === TAB_NAMES.REPORT['FULL REPORT'],
    ),
  };

  return tabs;
};

export const reviewReportTabsMappings = (REPORTS_FOR_REVIEW_TABLE_TABS: ITabItemProp[]) => ({
  reviewLocationsTab: findIndex(
    REPORTS_FOR_REVIEW_TABLE_TABS,
    (tab) => tab.label === TAB_NAMES.REPORT['LOCATIONS TO REVIEW'],
  ),
  reviewOtherLocationsTab: findIndex(
    REPORTS_FOR_REVIEW_TABLE_TABS,
    (tab) => tab.label === TAB_NAMES.REPORT['OTHER LOCATIONS'],
  ),
});
