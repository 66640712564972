// libraries
import React, { useState, useEffect, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui core
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';

// functions and variables
import { getInvalidEmailErrorMessage } from 'udb/sign-in/features/SignInForm/utils/getInvalidEmailErrorMessage.utils';
import { AUTH_PAGES_URLS } from 'common/pages';

// helpers, services & store
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Box } from 'components/common/Box';
import { Spinner } from 'components/common/Spinner';
import { Copyright } from 'components/common/Copyright';
import { isValidEmailFormat } from 'utils/isValidEmailFormat';
import { UserLevelActionNames } from '../../store/UserLevelStore/userLevelActions';
import { authStore } from '../../store/AuthStore';

// styles
import { sharedStyles } from './styles';

// components
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';
import { useRequestController } from '../../hooks';

const logPrefix = getLogPrefixForType('COMPONENT', 'ForgotPassword');

export const ForgotPassword = () => {
  const { classes } = sharedStyles();
  const { dispatchUserLevelStore } = useUserLevelStore();
  const navigate = useNavigate();
  const { requestController } = useRequestController(logPrefix);

  // state variables
  const [spinner, setSpinner] = useState(0);
  const [values, setValues] = useState({ username: '' });

  const hasEmail = !!values.username;
  const hasValidEmailInput = hasEmail ? isValidEmailFormat(values.username) : true;
  const errorMessage = hasEmail ? getInvalidEmailErrorMessage(values.username) : '';

  const handleChange = (value: string, prop: string) => {
    setValues({ ...values, [prop]: value });
  };

  const handleSubmit = () => {
    let sanitizedUsername = values.username;
    sanitizedUsername = sanitizedUsername.replace(/\s+/g, '');

    const reservation = requestController.reserveSlotForRequest();
    requestController.doRequest({
      request: authStore.forgotPassword,
      requestParams: [sanitizedUsername, reservation.signal],
      callbackBeforeSend: () => setSpinner((repliesPending) => repliesPending + 1),
      callbackSuccess: (r) => {
        dispatchUserLevelStore({
          type: UserLevelActionNames.AUTH_DETAILS,
          payload: {
            username: sanitizedUsername,
            attrName: r.data.code_delivery_details.DeliveryMedium,
          },
        });
        dispatchUserLevelStore({
          type: UserLevelActionNames.AUTH_PROCESS,
          payload: true,
        });
        navigate(AUTH_PAGES_URLS.NEW_PASSWORD, { state: values });
      },
      callbackFinally: () => setSpinner((repliesPending) => repliesPending - 1),
    });
  };

  const handleKeyDownPress = (evt: KeyboardEvent<HTMLDivElement>) => {
    if (evt.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleCancel = () => navigate(AUTH_PAGES_URLS.SIGNOUT, { state: values });

  useEffect(() => {
    // #SG-20201104-TODO: Consider the following, future UX improvement (also move
    // scrollTo functionality to common file):
    // - we scroll to top just when navigating forward (not via browser button)
    // - we keep the scroll when:
    //   - navigating back (from the page header back arrow, and on the  browser button back)
    //   - navigating  forward via the browser button
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className={classes.container}>
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="h5" color="textPrimary">
          Forgot password?
        </Typography>

        <Typography style={{ margin: '16px 0px' }} variant="subtitle1" color="textSecondary">
          Enter your username to reset your password
        </Typography>

        <FormControl className={classes.formControl}>
          <TextField
            type="email"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email"
            autoFocus
            error={!hasValidEmailInput}
            helperText={errorMessage}
            value={values.username}
            onChange={(e) => {
              isValidEmailFormat(e.target.value);
              handleChange(e.target.value, 'username');
            }}
            onKeyDown={handleKeyDownPress}
          />
        </FormControl>

        <Box className={classes.actionButtons} display="flex">
          <Button
            data-testid="forgotPasswordCancel"
            fullWidth
            variant="outlined"
            color="primary"
            onClick={handleCancel}
          >
            Cancel
          </Button>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!hasEmail || !hasValidEmailInput}
            data-testid="forgotPasswordReset"
            onClick={handleSubmit}
            className={classes.submit}
          >
            Reset Password
          </Button>
        </Box>
      </Paper>

      <Box mt={8}>
        <Copyright />
      </Box>

      {spinner ? <Spinner /> : null}
    </Container>
  );
};
