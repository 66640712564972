import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import moment from 'moment-timezone';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { ILabelTypeST } from 'codegen/location_action';
import { TIME_FIRST_DATETIME_FORMAT } from 'common/datetimeFormats';
import { LabelsStatusAlertProps } from '../LabelsStatusAlert';
import { labelTypeEnumToText } from '../models/labelTypeEnumToText.model';

export const useLabelsStatusAlertProps = ({
  selectedLabelType,
  createdDateTime,
  onUnselect,
  closeModal,
}: {
  selectedLabelType: ILabelTypeST | undefined;
  createdDateTime: string | undefined;
  onUnselect: () => void;
  closeModal: () => void;
}): (LabelsStatusAlertProps & { id: string })[] => {
  const navigate = useNavigate();
  const { currentSystemId: systemId } = useFacilityLevelStore().stateFacilityLevel;

  const formattedCreatedDateTime = useMemo(
    () =>
      createdDateTime ? moment(createdDateTime).format(TIME_FIRST_DATETIME_FORMAT).trim() : '',
    [createdDateTime],
  );

  return [
    {
      id: 'no-label',
      message: 'Label tab will appear in the Warehouse status.',
      severity: 'info',
      actionButtonLabel: 'VIEW LABELS LIST',
      onClick: () => {
        closeModal();
        navigate(`/${systemId}${INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_X}?activeTab=8`);
      },
    },
    {
      id: 'label-selected',
      message: `Added to "${
        labelTypeEnumToText[selectedLabelType as ILabelTypeST]
      }" on ${formattedCreatedDateTime}`,
      severity: 'info',
      actionButtonLabel: 'VIEW LABELS LIST',
      onClick: () => {
        closeModal();
        navigate(`/${systemId}${INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_X}?activeTab=8`);
      },
    },
    {
      id: 'wms-updated',
      message: (
        <span>
          WMS Data has been updated since being bookmarked.
          <br /> Added to "{labelTypeEnumToText[selectedLabelType as ILabelTypeST]}" on&nbsp;
          {formattedCreatedDateTime}.
        </span>
      ),
      severity: 'warning',
      actionButtonLabel: 'Un-select',
      onClick: () => onUnselect(),
    },
    {
      id: 're-scanned',
      message: (
        <span>
          Location has been re-scanned since the label was added.
          <br /> Added to "{labelTypeEnumToText[selectedLabelType as ILabelTypeST]}" on&nbsp;
          {formattedCreatedDateTime}.
        </span>
      ),
      severity: 'warning',
      actionButtonLabel: 'Un-select',
      onClick: () => onUnselect(),
    },
  ];
};
