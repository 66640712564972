import { useEffect } from 'react';

import Grid from '@mui/material/Grid';
import { UploadCard, IUploadCardData, UploadInfoLabel } from 'components/UploadCard/UploadCard';
import { IUploadInfoStateParam } from 'components/common/UploadInfo';

/**
 * Interface for the element defining an upload card.
 */
interface IUploadCardDefinition {
  alertState: IUploadInfoStateParam;
  data: IUploadCardData;
  progress: number;
  fileDetails: { file: File };
  isLoading: boolean;
  upload: <T extends File>(acceptedFiles: T[]) => void;
}

export const Uploads = ({
  showAlert,
  wmsSnapshot,
  stockCheck,
  getFiles,
  movedLocations,
}: {
  showAlert: { close: (uploadInfoLabel: UploadInfoLabel) => void };
  /**
   * Definition for the wms snapshot card
   */
  wmsSnapshot: IUploadCardDefinition;
  /**
   * Definition for the Stock Check card.
   */
  stockCheck: IUploadCardDefinition;
  /**
   * Function retrieving the required files.
   */
  getFiles: () => void;
  /**
   * Definition for the upload moved location card.
   */
  movedLocations: IUploadCardDefinition;
}) => {
  useEffect(() => {
    getFiles();
  }, [getFiles]);

  return (
    <Grid spacing={3} container>
      <Grid lg={4} md={6} sm={12} item>
        <UploadCard
          testId="wms-snapshot"
          cardFor="WMS snapshot"
          uploadInfoLabel="WMSSnapshot"
          showAlert={showAlert}
          alertState={wmsSnapshot.alertState}
          data={wmsSnapshot.data}
          progress={wmsSnapshot.progress}
          fileDetails={wmsSnapshot.fileDetails}
          isLoading={wmsSnapshot.isLoading}
          onDrop={wmsSnapshot.upload}
        />
      </Grid>
      <Grid lg={4} md={6} sm={12} item>
        <UploadCard
          testId="stock-check"
          cardFor="Stock check"
          uploadInfoLabel="StockCheck"
          showAlert={showAlert}
          alertState={stockCheck.alertState}
          data={stockCheck.data}
          progress={stockCheck.progress}
          fileDetails={stockCheck.fileDetails}
          isLoading={stockCheck.isLoading}
          onDrop={stockCheck.upload}
        />
      </Grid>
      <Grid lg={4} md={6} sm={12} item>
        <UploadCard
          testId="moved-locations"
          cardFor="Moved locations"
          uploadInfoLabel="MovedLocations"
          showAlert={showAlert}
          alertState={movedLocations.alertState}
          data={movedLocations.data}
          progress={movedLocations.progress}
          fileDetails={movedLocations.fileDetails}
          isLoading={movedLocations.isLoading}
          onDrop={movedLocations.upload}
        />
      </Grid>
    </Grid>
  );
};
