export const isValidDecimalLength = (
  value: string | number,
  maxDecimalCount: number = 2,
): string | undefined => {
  const valueStr = typeof value === 'number' ? value.toString() : value;
  const decimalIndex = valueStr.indexOf('.');

  if (decimalIndex < 0 || !(valueStr.length - decimalIndex - 1 > maxDecimalCount)) {
    return undefined;
  }

  return `Input values must be no more than ${maxDecimalCount} decimal places`;
};
