import { useState } from 'react';
import { IconButton, InputAdornment, StandardTextFieldProps, TextField } from '@mui/material';
import { Field, FieldProps } from 'formik';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

/**
 * Form input interface
 */
interface IFormInput extends StandardTextFieldProps {
  togglePasswordVisibility?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  validate?: (value: string) => string | undefined;
}

/**
 * Form input
 * @param param0 TextFieldProps props
 * @returns component
 */
export const FormInput = ({
  name,
  placeholder = '',
  label = '',
  type = 'text',
  size = 'medium',
  togglePasswordVisibility = false,
  onChange,
  validate,
  ...rest
}: IFormInput) => {
  const [visibility, setVisibility] = useState<boolean>(false);

  return (
    <Field name={name} validate={validate}>
      {({ field, meta }: FieldProps) => (
        <TextField
          name={name}
          value={field.value}
          placeholder={placeholder}
          label={label}
          onChange={(e) => {
            field.onChange(e);
            onChange && onChange(e);
          }}
          type={visibility ? 'text' : type}
          size={size}
          error={!!(meta.touched && meta.error)}
          helperText={meta.error}
          InputProps={{
            endAdornment: togglePasswordVisibility && (
              <InputAdornment position="end">
                <IconButton onClick={() => setVisibility(!visibility)} edge="end" size="large">
                  {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
            ...rest.InputProps,
          }}
          {...rest}
        />
      )}
    </Field>
  );
};
