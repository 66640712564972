import { NEW_DRONE_ZONE_ID } from 'shared/map/defaults/new-drone-zone-id';
import { ControlledZone, ControlledZones } from 'shared/map/model/controlled-zone.model';
import { DroneZonesListProps } from '../../droneZonesList.model';
import { DroneZoneListItem } from '../../../drone-zone-list-item/droneZoneListItem';

export const ControlledZonesListItem = ({
  droneZone,
  onClone,
  onSelect,
  onEdit,
  onDelete,
}: Pick<
  DroneZonesListProps<ControlledZones, ControlledZone>,
  'onSelect' | 'onEdit' | 'onDelete' | 'onClone'
> & {
  droneZone: ControlledZone;
}) => {
  const menuOptions = [
    { id: 'controlled-zone-edit', label: 'Edit', clickHandler: () => onEdit(droneZone) },
    {
      id: 'controlled-zone-clone',
      label: 'Clone',
      clickHandler: () =>
        onClone({ ...droneZone, id: NEW_DRONE_ZONE_ID, name: `${droneZone.name} (Copy)` }),
    },
    { id: 'controlled-zone-delete', label: 'Delete', clickHandler: () => onDelete(droneZone) },
  ];

  const handleOnSelect = () => onSelect(droneZone);

  return (
    <DroneZoneListItem
      id={droneZone.id}
      name={droneZone.name}
      isSelected={droneZone.isSelected}
      options={menuOptions}
      onClick={handleOnSelect}
    />
  );
};
