import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { Grid } from '@mui/material';
import { WarehouseOccupancyCard } from './WarehouseOccupancyCard';
import { WarehouseStatusCard } from './WarehouseStatusCard';

export const WarehouseStatusOverviewLegacy = ({ systemId }: { systemId: string }) => (
  <Grid container sx={{ gridColumn: '1 / -1' }} spacing={2}>
    <Grid item md={6}>
      {userHasPermission(PERMISSION.VIEW_WAREHOUSE_STATUS) && (
        <WarehouseStatusCard systemId={systemId} />
      )}
    </Grid>
    <Grid item md={6}>
      <WarehouseOccupancyCard systemId={systemId} />
    </Grid>
  </Grid>
);
